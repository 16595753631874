import React from 'react';
import paymentActions from '../../../redux/futures/payment/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import * as types from '../../../redux/futures/payment/types';
import i18n from '../../../i18n';
class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            payment: {
                ladi_uid: '',
                order_id: '',
                order_reference_no: '',
                method: '',
                gate_way: '',
                amount: '',
                note: '',
                status: '',
                transaction_id: '',
                admin_id: ''
            },
            details: []
        }
    }

    // componentDidMount() {
    //     this.getDetail();
    // }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {


        if (this.props.currentPayment !== nextProps.currentPayment) {
            this.setState({
                payment: _.pick(nextProps.currentPayment, _.keys(this.state.payment))
            })
        }



        if (_.includes([types.DETAIL_PAYMENT], nextProps.action)) {
            if (nextProps.status) {

                this.setState({
                    details: nextProps.details,
                });
            } else {
            }
        }
    }

    render() {
        const { details, payment } = this.state;
        const title = 'Payment Detail Order No: ' + payment.order_reference_no;

        return (
            <div id="modal-detail" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-detail">
                <div className="ladiui-modal-content" style={{ maxWidth: '1000px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-detail"><i className="ladi-icon icon-e-remove" data-dismiss="modal-detail" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <div className="ladiui-tab-content"> */}
                        <div className="payment-box" style={{ width: '970px', margin: '15px' }}>
                            {/* <ul> */}
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Amount: </strong>
                                    {
                                        this.state.payment.amount && this.state.payment.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                    }
                                </div>
                                <div className="col-50">
                                    <strong>Method: </strong>{this.state.payment.method}
                                </div>



                            </div>
                            <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Status: </strong>{this.state.payment.status}
                                </div>
                                <div className="col-50">
                                    <strong>Gate Way: </strong>{this.state.payment.gate_way}
                                </div>

                            </div>
                            {/* <div className="ladiui-row" style={{ height: '50px' }}>
                                <div className="col-50">
                                    <strong>Transaction Id: </strong>{this.state.payment.transaction_id}
                                </div>
                                <div className="col-50">
                                    <strong>Note: </strong>{this.state.payment.note}
                                </div>

                            </div> */}

                            {/* <li><strong>admin_id: ''</strong>{this.state.admin_id}</li> */}

                            {/* </ul> */}
                            {this.props.loading && <Spinner />}
                        </div>
                        {/* </div> */}
                    </div>
                    <table className="ladiui table">
                        <thead>
                            <tr>
                                <th className="text-left">{i18n.t('PACKAGE.APP')}</th>
                                <th className="text-left">{i18n.t('PACKAGE.NAME')}</th>
                                <th className="text-left">{i18n.t('PACKAGE.MONTH')}</th>
                                <th className="text-left">{i18n.t('PACKAGE.ADDITION_MONTH')}</th>
                                {/* <th className="text-center">Commission Type</th> */}
                                {/* <th className="text-center">Commission Value</th> */}
                                <th className="text-left">{i18n.t('PACKAGE.TOTAL_PRICE')}</th>
                                <th className="text-left">{i18n.t('PACKAGE.CREATED_AT')}</th>
                                {/* <th className="text-center">Discount Type</th> */}
                                {/* <th className="text-center">Discount Value</th> */}
                                {/* <th></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(details, detail => {
                                    return <tr key={detail._id}>
                                        <td>{appConst.APP[detail.pkg_type]}</td>
                                        <td>{detail.pkg_name}</td>
                                        <td>{detail.pkg_month}</td>
                                        <td>{detail.addition_month}</td>
                                        {/* <td>{detail.pkg_commission_type}</td> */}
                                        {/* <td>{detail.pkg_commission_value}</td> */}
                                        <td>{baseHelper.formatNumber(detail.subtotal)} VND</td>
                                        <td>{baseHelper.formatStrToDate(detail.created_at)}</td>
                                        {/* <td>{detail.discount_type}</td> */}
                                        {/* <td>{detail.discount_value}</td> */}
                                    </tr>
                                }
                                )
                            }
                        </tbody>
                    </table>

                    <div className="ladiui-modal-footer">

                        {this.props.loading && <Spinner />}
                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDetail: (payment) => dispatch(paymentActions.getDetail(payment))
    }
}

const mapStateToProps = (state) => ({ ...state.payment });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);