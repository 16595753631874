import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import i18n from '../../../i18n';
import * as types from '../../../redux/futures/auth/types';

class ChangeAffilateCode extends React.Component {

    static propTypes = {
        userInfo: PropTypes.object
    }

    constructor(props) {
        super(props);
        const { userInfo } = this.props;
        this.state = {
            code: '',
            iconClass: 'icon-preview',
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.affilatecode && this.state.affilatecode !== nextProps.affilatecode) {
            this.setState({
                code: nextProps.affilatecode
            });
        }
        if (nextProps.action != this.props.action) {
            if (nextProps.action == types.CHANGE_AFFILATE_CODE) {

                if (nextProps.status) {
                    window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
                } else {
                    window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
                }
            }
            if (nextProps.action == types.GET_REF_AFFILATE) {
                if (nextProps.status) {
                    const affilate = nextProps.affilateInfo;
                    if (affilate.code) {
                        this.setState({ code: affilate.code });
                    }
                }
            }
        }
    }

    submit = () => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toast('danger', '', errorMessage);
            return;
        }
        const { code } = this.state;
        const jsonData = {
            affilate_code: code
        }
        this.props.changeAffilateCode(jsonData);

    }

    onChangeInput = (event) => {

        this.setState({
            code: event.target.value
        });
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss"))
            return;

        this.state = { code: '' };
    }

    render() {
        const { code } = this.state;

        const title = i18n.t('CHANGE_AFFILATE_CODE.TITLE');

        return (
            <div ref={this.ref} id="modal-change-affilatecode" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-change-affilatecode"
                onClick={this.resetForm}>
                <div className='ladiui modal-dialog modal-dialog-centered custom-ldp-width'>
                    <div className="ladiui modal-content">
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-change-affiliatecode"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body">
                            <form>
                                <div className="ladiui-tab-content">
                                    <div className="ladiui-row">
                                        <div>
                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('CHANGE_AFFILATE_CODE.LABEL')}</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="code"
                                                    validationName="Affilate code"
                                                    placeholder={i18n.t('CHANGE_AFFILATE_CODE.PLACE_HOLDER')}
                                                    value={code}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true, minLength: 2, maxLength: 45 }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button data-dismiss="modal" data-target="modal-profile" className="ladiui btn small txt body3 btn-secondary">
                                    {i18n.t("BASE.CANCEL")}
                                </button>
                                <div>
                                    <button className="ladiui btn small txt body3 btn-primary" onClick={() => this.submit()}>
                                        {i18n.t("BASE.UPDATE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeAffilateCode: (data) => dispatch(authActions.changeAffilateCode(data))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAffilateCode);