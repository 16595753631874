import React from 'react';

export default class Iframe extends React.Component{
    constructor(props) {
        super(props);
        
        this.state = { 
            src: props.src,
            height:'1px',
            width:'1px'
        }
 
    }

    render () {
        return (
            <div>
                <iframe src={this.state.src} height={this.state.height} width={this.state.width} />
            </div>
        )
    }
}
