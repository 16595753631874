import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import queryString from 'query-string';
import * as typesAuth from '../../redux/futures/auth/types';
import authActions from '../../redux/futures/auth/actions';
import i18n from '../../i18n';
class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    const userInfo = baseHelper.getCookie(appConst.USER_INFO);
    let dspText = i18n.t('PROFILE.PROCESS_VERIFY_EMAIL');
    if (userInfo && userInfo.is_verify_email) {
      dspText = i18n.t('PROFILE.DONE_VERIFY_EMAIL')
    }
    this.state = {
      userInfo,
      display_text: dspText,
      error_text:'',
      countdown_text:'',
      token: '',
      countDown: 8
    }
  }

  componentDidMount() {
    window.LadiUI.init();

    const hashObj = queryString.parse(window.location.search);

    if (hashObj.token && hashObj.token != '') {
      this.props.verifyEmail({ token: hashObj.token });
    }

  }
  componentWillReceiveProps(nextProps) {
    let { userInfo, countDown } = this.state;
    if (nextProps.action == typesAuth.SUBMIT_VERIFY_EMAIL) {
      if (nextProps.status) {
        userInfo = nextProps.userInfo;
        userInfo.is_sent_mail = new Date();
        baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(userInfo), appConst.COOKIE_EXPRIRE);

        this.setState({
          userInfo,
          display_text: i18n.t('PROFILE.DONE_VERIFY_EMAIL')
        }, () => {

          setInterval(() => {
            countDown -= 1;
            if (countDown == 0) {
              window.location.href = appConst.BUIDER_URL;
            }else {
              this.setState({
                countdown_text: "Trình duyệt tự động chuyển hướng sang LadiPage Builder sau: " + countDown + " giây."
              });
            }

          }, 1000);
        });

      } else {
        this.setState({
          display_text: 'Đường dẫn kích hoạt tài khoản không hợp lệ hoặc đã hết hạn!',
          error_text: 'Vui lòng đăng nhập tài khoản của bạn và bấm vào nút Gửi lại mail xác thực để LadiPage gửi mail xác thực mới cho bạn.'
        });
      }
      return;
    }

  }

  render() {
    const { userInfo, display_text, countdown_text, error_text } = this.state;
    const name = (userInfo && userInfo.fullname) ? userInfo.fullname : '';
    const email = (userInfo && userInfo.email) ? userInfo.email : '';
    const colorT = error_text != '' ? 'red':'blue';
    return (
      <div className="ladiui-content">
        <div className="page-ladiuid-account">
          <div className="welcome text-center">
            {userInfo&&
              <div>
                <div className={!userInfo.avatar ? 'profile-avatar no-avatar' : 'profile-avatar'} >
                  {
                    !userInfo.avatar
                      ?
                      baseHelper.getFirstLetter(userInfo.fullname)
                      :
                      <img src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} className="ladiui-profile-avatar" />
                  }

                </div>
                <h3>{i18n.t('PROFILE.HELLO_MSG')}, {(name == '') ? email : name}</h3>
              </div>
            }
            
            <div style={ {fontSize: '18px' }} >
              <p><b style={{ color: colorT }}>{display_text}</b></p>
              <p>{countdown_text}</p>
              <p>{error_text}</p>
            </div>
            <div style={{display:"inline-flex"}}>
              <div style={{ verticalAlign: "middle", textAlign: "center", margin: "20px" }}><a className="ladiui button sm outline-primary" href={appConst.BUIDER_URL} style={{ verticalAlign: "middle" }}>LadiPage Builder</a></div>
              <div style={{ verticalAlign: "middle", textAlign: "center", margin: "20px" }}><a className="ladiui button sm outline-primary" href={appConst.ACCOUNTs_URL} style={{ verticalAlign: "middle" }}>Quản lý tài khoản</a></div>
            </div>
          </div>
        </div>

      </div>

    );

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmail: (data) => dispatch(authActions.verifyEmail(data)),
  }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);