import React from 'react';
import * as types from '../../../redux/futures/partner/types';
import partnerActions from '../../../redux/futures/partner/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import configJson from '../../../config/const';
import appConst from '../../../config/app';
import i18n from '../../../i18n';

class UpgradeStore extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            store: {
                store_id: '',
                name: '',
                ladi_uid: '',
                pkg_name: '',
                pkg_exp: '',
                pkg_buy_exp: '',
                pkg_buy_price: '0',
                created_at: '',
            },
            userinfo: {
                point: '',
                email: '',
                fullname: '',
                lastname: '',
                phone: '',
                credit: '0'
            },
            appCode: 'LP',
            packages: [],
            selectedPackage: '',
            totalCredit: 0,
            loading: false,
            loaderPackages: true,
            classNormal: "ladiui btn btn-primary",
            classLoading: "ladiui loader btn btn-primary",
            classApply: "ladiui btn btn-primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.appCode !== nextProps.appCode) {
            this.setState({
                appCode: nextProps.appCode,
            });
        }
        if (this.props.currentStore !== nextProps.currentStore) {
            if (!nextProps.currentStore) {
                this.resetForm();
            } else {

                this.setState({
                    store: _.pick(nextProps.currentStore, _.keys(this.state.store)),
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    packages: []
                })
            }
        }

        if (nextProps.action === types.LIST_PARTNER_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                const { currentStore } = this.props;
                let priority = 0;
                let pkgExprire = new Date();
                let today = new Date();
                if (currentStore.pkg_exp && currentStore.pkg_exp !== '') {
                    pkgExprire = new Date(currentStore.pkg_exp);
                }
                const isExpire = today.getTime() >= pkgExprire.getTime();
                for (let i = 0; i < nextProps.packages.length; i++) {
                    if (currentStore.pkg_name === nextProps.packages[i].pkg_name && !isExpire) {
                        priority = nextProps.packages[i].priority;
                        break;
                    }
                }
                const availablePackages = [];
                for (let i = 0; i < nextProps.packages.length; i++) {
                    if (nextProps.packages[i].priority >= priority) {
                        availablePackages.push(nextProps.packages[i]);
                    }
                }
                this.setState({
                    packages: availablePackages,
                    selectedPackage: availablePackages[0]
                }, () => {
                    this.calculateMoney();
                });
            } else {
                window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
            }
        }
        if (nextProps.action === types.PARTNER_UPGRADE_STORE) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
                setTimeout(function () {
                    window.LadiUI.closeModal('modal-partner-upstore');
                }, 1000);

            } else {
                // window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }


    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            //window.LadiUI.toast('danger', '', errorMessage);
            window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), errorMessage, 'OK');
            return;
        }

        const { selectedPackage, store, totalCredit, appCode } = this.state;
        const userInfo = baseHelper.getCookie(configJson.USER_INFO);
        if (totalCredit > userInfo.credit) {
            window.LadiUI.toast('danger', '', 'Credit không đủ để thực hiện giao dịch. Vui lòng liên hệ support!');
            return;
        }

        const data = {
            store,
            package: selectedPackage,
            app_code: appCode
        };
        this.props.upgrade(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangePackage = (packageObj) => {
        this.setState({
            selectedPackage: packageObj
        }, () => {
            this.calculateMoney();

        });
    }
    calculateMoney = () => {

        const { selectedPackage } = this.state;
        let totalCredit = Math.floor(parseInt(selectedPackage.pkg_price) * parseInt(selectedPackage.discount) / 100 / 1000);
        if (parseInt(selectedPackage.discount) == 0) {
            totalCredit = selectedPackage.pkg_price / 1000;
        }
        this.setState({ totalCredit });
    }
    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }

    render() {
        this.inputsRef = new Set();
        let { packages, selectedPackage, loaderPackages, totalCredit, appCode } = this.state;
        const { currentStore } = this.props;
        const title = "Nâng cấp store: " + currentStore.email;
        const userInfo = baseHelper.getCookie(configJson.USER_INFO);

        return (
            <div ref={this.ref} id="modal-partner-upstore" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-partner-upstore" style={{ zIndex: 21 }}>
                <div className='ladiui modal-dialog modal-dialog-centered custom-ldp-width'>
                    <div className={`ladiui modal-content ${loaderPackages ? 'loader' : ''}`} style={{ maxWidth: "600px" }} >
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-partner-upstore"
                                className="ladiui modal-close"
                                onClick={this.props.onCancel}
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className={"ladiui modal-body"} style={{ height: "70vh", overflow: 'scroll' }}>
                            <form>
                                <div>
                                    <div className="ladiui form-group condition-form">

                                        <h3>Lựa chọn gói dịch vụ {appConst.APP_NAME[appCode]}</h3>
                                    </div>
                                    <div className="ladiui-row">
                                        {
                                            _.map(packages, packageObj => {
                                                let displayName = packageObj.pkg_display_name;
                                                if (packageObj.pkg_display_name === 'PREMIUM_5') {
                                                    displayName = 'BUSSINESS_3';
                                                }
                                                if (packageObj.pkg_display_name === 'PREMIUM_10') {
                                                    displayName = 'BUSSINESS_10';
                                                }
                                                if (packageObj.pkg_display_name === 'PREMIUM_20') {
                                                    displayName = 'BUSSINESS_20';
                                                }
                                                return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.id}>
                                                    <div className="ladiui radio">
                                                        <input type="radio"
                                                            id={"package-" + packageObj.id}
                                                            name="selectedPackage"
                                                            value={packageObj.id}
                                                            onChange={() => this.onChangePackage(packageObj)}
                                                            // defaultChecked
                                                            checked={selectedPackage.id == packageObj.id}
                                                            className="ladiui form-check-input"
                                                        />

                                                        <label htmlFor={"package-" + packageObj.id}>
                                                            {displayName + `: ` + packageObj.pkg_month + ` tháng (` + baseHelper.numberWithCommas(packageObj.pkg_price) + ` VND). KM: ` + packageObj.addition_month + ` tháng`}
                                                            {
                                                                selectedPackage.id == packageObj.id &&
                                                                ` - Credit :` + baseHelper.numberWithCommas(totalCredit)
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="ladiui form-group condition-form">

                                        <h3>{`Tổng credit sử dụng: `}<b style={{ fontWeight: 700 }}>{baseHelper.numberWithCommas(totalCredit)}</b></h3>
                                        <h3>{`Credit hiện có: `}<b style={{ fontWeight: 700 }}>{baseHelper.numberWithCommas(userInfo.credit)}</b></h3>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="ladiui modal-footer">
                            <button className="ladiui btn btn-secondary" type="button"
                                data-dismiss="modal"
                                data-target="modal-partner-upstore"
                                onClick={this.props.onCancel}>Close</button>
                            <button
                                ref={this.ref}
                                className={this.state.classApply}
                                onClick={this.submit}
                                disabled={this.state.loading}
                            >
                                <i className="ladi-icon" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>Nâng cấp </button>

                            {/* {this.props.loading && <Spinner />} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        upgrade: (store) => dispatch(partnerActions.upgrade(store)),
    }
}

const mapStateToProps = (state) => ({ ...state.partner });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UpgradeStore);