import React from 'react';


class LoadingScene extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="builder-loading"   style={{backgroundImage: `url('https://static.ladipage.net/57b167caca57d39c18a1c57e/ladiloader-1558673436.svg')`}}>
            </div>
        );
    };
}



export default LoadingScene;