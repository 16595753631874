import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const myAffilateList = (data) => ({
    type: types.MY_AFFILATE_LIST,
    meta: {
        endpoint: endpoint.MY_AFFILATE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const myAffilateInfo = () => ({
    type: types.MY_AFFILATE_INFO,
    meta: {
        endpoint: endpoint.MY_AFFILATE_INFO,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const updatePaymentInfo = (data) => ({
    type: types.UPDATE_PAYMENT_INFO,
    meta: {
        endpoint: endpoint.UPDATE_PAYMENT_INFO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const createAffilate = (data) => ({
    type: types.ACTIVE_AFFILATE,
    meta: {
        endpoint: endpoint.ACTIVE_AFFILATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const updateAffilate = (data) => ({
    type: types.UPDATE_AFFILATE,
    meta: {
        endpoint: endpoint.UPDATE_AFFILATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


export default {
    myAffilateList,
    updatePaymentInfo,
    myAffilateInfo,
    createAffilate,
    updateAffilate
}