import React from 'react';

import { connect } from 'react-redux';
import paymentActions from '../../redux/futures/payment/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import Pagination from "react-js-pagination";
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/payment/types';

import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";

import Detail from '../payments/components/Detail';
import Print from '../payments/components/Print';
import Cancel from '../payments/components/Cancel';

import LoadingTable from '../../components/LoadingTable';
import i18n from '../../i18n';

class Payment extends React.Component {

    constructor(props) {
        super(props);

        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.state = {
            activePage: 1,

            searchStatus: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
            },
            searchMethod: {
                name: appConst.PAYMENT_METHOD_OPTIONS.ALL.name,
                value: appConst.PAYMENT_METHOD_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_METHOD_OPTIONS.ALL.value,
            },
            fromDate: firstDay,
            toDate: new Date(),
            searchOrderNo: '',
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            currentPayment: {},
            mode: appConst.FORM_MODE.CREATE,
            payments: [],
            sortby: appConst.SORT_BY.DESCENDING
        }

        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) &&
            nextProps.action === types.MY_PAYMENT) {
            if (nextProps.status) {
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
            this.setState({ payments: nextProps.payments });
            // this.getList();
        }
    }

    changeFromdate = (date) => {

        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }


    /**
     * list store
     */
    getList = (resetActivePageFlag = true) => {
        document.getElementById("checkAllItem").checked = false;
        const { searchOrderNo, selectedLimit } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        // Reset selectedOrderIds
        this.selectedOrderIds = [];

        const statusSelected = this.state.searchStatus.selected;
        const methodSelected = this.state.searchMethod.selected;

        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "status": statusSelected,
            "method": methodSelected,
            "reference_no": searchOrderNo,
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.myPayments(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    onChangeSearchMethod = (option) => {
        this.setState({
            searchMethod: { ...this.state.searchMethod, selected: option.value },
        }, () => {
            this.getList();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
        if (checked) {
            this.selectedOrderIds = _.map(this.state.payments, store => store.store_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length == this.state.payments.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }


    sort = (event, sortKey) => {
        const { classList } = event.target;
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            this.setState({
                sortby: appConst.SORT_BY.DESCENDING
            });
            classList.remove('up');
        } else {
            this.setState({
                sortby: appConst.SORT_BY.ASCENDING
            });
            classList.add('up');
        }

        const data = this.state.payments;
        data.sort((a, b) => {

            if (this.state.sortby == 1) {
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }
            return b[sortKey].toString().localeCompare(a[sortKey].toString());
        });
        this.setState({ payments: data });
    }

    print = (currentPayment) => {
        const data = {
            "id": currentPayment.order_id
        };
        this.setState({
            currentPayment
        });
        this.props.getDetail(data);
    }

    view = (currentPayment) => {
        const data = {
            "id": currentPayment.order_id
        };
        this.setState({
            currentPayment
        });
        this.props.getDetail(data);
    }

    cancel = (currentPayment) => {
        const data = {
            "id": currentPayment.order_id
        };
        this.setState({
            currentPayment
        });
        this.props.getDetail(data);
    }

    /****************************************************************/


    render() {
        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, searchStatus, searchMethod, searchOrderNo, resetForm, fromDate, toDate } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.state.payments || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.state.payments.length - 1;
        }


        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.MY_PAYMENT);
        return (
            <div>
                <h1 className="page-title">{i18n.t("PAYMENT.TITLE")}</h1>

                <div>
                    <div className="ladiui-table-actions">
                        <div className="action-item">

                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder={i18n.t('SEARCH.SEARCH_REFERENCE_NO')} name="searchOrderNo" value={searchOrderNo} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.getList} />
                            </div>
                        </div>

                        <div className="action-item">

                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={fromDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeFromdate} //only when value has changed
                            />
                        </div>

                        <div className="action-item">
                            <CustomDatePicker
                                customClass="ladiui input"
                                selected={toDate}
                                onSelect={this.handleSelect} //when day is clicked
                                onChange={this.changeTodate} //only when value has changed
                            />
                        </div>
                        {/* <div className="action-item">
                            <span className="title-date">Status</span>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{i18n.t(searchStatus.name)}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PAYMENT_STATUS_OPTIONS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchStatus(option)}><a data-value={option.value}>{i18n.t(option.name)}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="action-item">
                            <span className="title-date">Method</span>
                        </div> */}
                        <div className="action-item">
                            <div className="ladiui data-status">
                                <div className="ladiui-select-options">
                                    <span className="ladiui-select-init">{i18n.t(searchMethod.name)}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        {
                                            _.map(appConst.PAYMENT_METHOD_OPTIONS, option =>
                                                <li key={option.value} className="select-item" onClick={() => this.onChangeSearchMethod(option)}><a data-value={option.value}>{i18n.t(option.name)}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <table className="ladiui table">
                                <thead>
                                    <tr>
                                        <th className="ladiui checkall text-center">
                                            <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                        </th>
                                        {/* <th name="store_id" onClick={this.sort} className="text-left">ID</th> */}
                                        <th name="reference_no" onClick={e => this.sort(e, 'order_reference_no')} className="text-left">{i18n.t('ORDER.REFERENCE_NO')}</th>
                                        <th name="method" onClick={e => this.sort(e, 'method')} className="text-left">{i18n.t('ORDER.METHOD')}</th>
                                        <th name="amount" onClick={e => this.sort(e, 'amount')} className="text-left">{i18n.t('ORDER.AMOUNT')}</th>
                                        {/* <th className="text-left">{i18n.t('ORDER.NOTE')}</th>s */}
                                        <th name="created_at" onClick={e => this.sort(e, 'created_at')} className="text-left">{i18n.t('ORDER.CREATED_AT')}</th>
                                        <th name="status" onClick={e => this.sort(e, 'status')} className="text-left">{i18n.t('COMMON.STATUS')}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(this.state.payments, payment => {

                                            return <tr key={payment._id}>
                                                <td className="text-center">
                                                    <input type="checkbox" onClick={(event) => this.checkItem(event, payment._id)} className="ladiui checkbox" />
                                                </td>
                                                {/* <td>{store.store_id}</td> */}
                                                <td>{payment.order_reference_no}</td>
                                                <td>{payment.method}</td>
                                                <td>{payment.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                {/* <td>{payment.note}</td> */}
                                                <td>{baseHelper.formatStrToDate(payment.created_at)}</td>
                                                <td>
                                                    {i18n.t(appConst.PAYMENT_STATUS_OPTIONS[payment.status].name)}
                                                </td>
                                                <td>

                                                    <ul className="ladiui-navbar -horizontal row-actions">
                                                        <li className="ladiui-dropdown">
                                                            <a><i className="ladi-icon icon-dots"></i></a>
                                                            <ul>
                                                                {/* <li><a onClick={() => this.print(payment)} className="ladiui open-modal" modal="modal-print" >Print</a></li> */}
                                                                <li><a onClick={() => this.view(payment)} className="ladiui open-modal" modal="modal-detail" >{i18n.t('COMMON.DETAIL')}</a></li>
                                                                {
                                                                    (payment.status === appConst.PAYMENT_STATUS_OPTIONS.PENDING.value) &&
                                                                    <li><a onClick={() => this.cancel(payment)}
                                                                        className="ladiui open-modal" modal="modal-cancel" >{i18n.t('COMMON.CANCEL')}</a></li>
                                                                }
                                                                {
                                                                    (payment.status === appConst.PAYMENT_STATUS_OPTIONS.CANCEL.value || payment.status === appConst.PAYMENT_STATUS_OPTIONS.INVALID.value) &&
                                                                    <li><a onClick={() => this.deleteItem(payment._id)} className="ladiui open-modal" modal="modal-delete">{i18n.t('COMMON.HIDE')}</a></li>
                                                                }

                                                            </ul>
                                                        </li>
                                                    </ul>

                                                </td>
                                            </tr>
                                        }
                                        )
                                    }
                                    {
                                        totalRecord <= 0 &&
                                        <tr className="text-center">
                                            <td colSpan="100%">{i18n.t('COMMON.NO_DATA')}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui pagination-block">
                            <div className="ladiui search-actions">
                                <div className="ladiui form-filter">
                                    <div className="ladiui data-limit">
                                        <span>Show</span>
                                        <div className="ladiui per-page">
                                            <div className="ladiui-select-options">
                                                <span className="ladiui-select-init">{selectedLimit}</span>
                                                <input type="hidden" name="select" defaultValue />
                                                <ul>
                                                    {
                                                        _.map(appConst.PAGINATION.LIMIT_OPTIONS, option =>
                                                            <li key={option.value} className="select-item" onClick={() => this.onChangeLimit(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ladiui showing">{msgShowItem}</div>
                                </div>
                            </div>
                            {
                                totalRecord > 0 && <div className="ladiui pagination">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={selectedLimit}
                                        totalItemsCount={totalRecord}
                                        pageRangeDisplayed={5}
                                        onChange={this.onPageChange}
                                        prevPageText={<i className="ladi-icon icon-left-arrow" />}
                                        nextPageText={<i className="ladi-icon icon-right-arrow" />}
                                        firstPageText={<i className="ladi-icon icon-double-arrow-left" />}
                                        lastPageText={<i className="ladi-icon icon-double-arrow-right" />}
                                    />
                                </div>
                            }

                        </div>
                    }
                </div>

                <Detail
                    currentPayment={this.state.currentPayment}
                    mode={this.state.mode}
                />
                <Print
                    currentPayment={this.state.currentPayment}
                    mode={this.state.mode}
                />
                <Cancel
                    currentPayment={this.state.currentPayment}
                    mode={this.state.mode}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        myPayments: (data) => dispatch(paymentActions.myPayments(data)),

        getDetail: (data) => dispatch(paymentActions.getDetail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.payment });

export default connect(mapStateToProps, mapDispatchToProps)(Payment);