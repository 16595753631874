import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

 
const myPointLogs = (data) => ({
    type: types.MY_POINT_LOGS,
    meta: {
        endpoint: endpoint.MY_POINT_LOGS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 

export default {
    myPointLogs 
}