import * as types from './types';

export default (state = {}, action) => {
    switch (action.type) {
        case types.ASYNC_START:
            return { ...state };
        case types.ASYNC_END:
            return { ...state };
        case types.REDIRECT:
            return {
                ...state,
                redirectTo: action.redirectTo,
            };
        default:
            return state;
    }
}

