import React from 'react';
import orderActions from '../../../redux/futures/order/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import * as types from '../../../redux/futures/order/types';

class Print extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {
                ladi_uid: '',
                id: '',
                reference_no: '',
                discount: '',
                total: '', 
                note: '',
                status: '' 
            },
            details: []
        }
    }

    // componentDidMount() {
    //     this.getDetail();
    // }

    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {
        

        if (this.props.currentPayment !== nextProps.currentPayment) {
            this.setState({
                order: _.pick(nextProps.currentPayment, _.keys(this.state.order))
            })
        }



        if (_.includes([types.DETAIL_ORDER], nextProps.action)) {
            if (nextProps.status) {

                this.setState({
                    details: nextProps.details,
                });
            } else {
            }
        }
    }

    render() {
        const { details } = this.state;
        const title = 'Print Payment';

        return (
            <div id="modal-print" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-print">
                <div className="ladiui-modal-content" style={{ maxWidth: '1000px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-print"><i className="ladi-icon icon-e-remove" data-dismiss="modal-print" /></button>
                        <h3>Order No: {this.state.order.reference_no}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <div className="ladiui-tab-content"> */}
                            <div className="order-box"  style={{width:'970px',margin:'15px'}}>
                                {/* <ul> */}
                                <div className="ladiui-row" style={{height:'50px'}}>
                                        
                                        <div className="col-50">
                                            <strong>Amount: </strong>
                                            {
                                                this.state.order.total && this.state.order.total.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                            }
                                        </div>
                                  
                                        
                                        <div className="col-50">
                                            <strong>Discount: </strong>
                                            {
                                                this.state.order.discount
                                            }
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{height:'50px'}}>
                                        <div className="col-50">
                                            <strong>Note: </strong>{this.state.order.note}
                                        </div>
                                        <div className="col-50">
                                            <strong>Status: </strong>{this.state.order.status}
                                        </div>
                                    </div>
                                     
                                    {/* <li><strong>admin_id: ''</strong>{this.state.admin_id}</li> */}

                                {/* </ul> */}
                                {this.props.loading && <Spinner />}
                            </div>
                        {/* </div> */}
                    </div>
                    <table className="ladiui table">
                        <thead>
                            <tr>
                                <th className="text-left">Package Type</th>
                                <th className="text-left">Package Name</th>
                                <th className="text-right">Month</th>
                                <th className="text-center">Commission Type</th>
                                <th className="text-center">Commission Value</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Create Date</th>
                                <th className="text-center">Discount Type</th>
                                <th className="text-center">Discount Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(details, detail => {
                                    return <tr key={detail._id}>
                                        <td>{appConst.APP[detail.pkg_type]}</td>
                                        <td>{detail.pkg_name}</td>
                                        <td>{detail.pkg_month}</td>
                                        <td>{detail.pkg_commission_type}</td>
                                        <td>{detail.pkg_commission_value}</td>
                                        <td>{baseHelper.formatNumber(detail.subtotal)} VND</td>
                                        <td>{baseHelper.formatStrToDate(detail.created_at)}</td>
                                        <td>{detail.discount_type}</td>
                                        <td>{detail.discount_value}</td>
                                    </tr>
                                }
                                )
                            }
                        </tbody>
                    </table>

                    <div className="ladiui-modal-footer">

                        {this.props.loading && <Spinner />}
                    </div>
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDetail: (order) => dispatch(orderActions.getDetail(order))
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Print);