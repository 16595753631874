import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Input from '../../components/Input';
import queryString from 'query-string';


import * as types from '../../redux/futures/auth/types';




class GoogleCallback extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        const callbackObj = queryString.parse(this.props.location.search);
        let buildSearch = '';
        let stateObj = {}; 
        if (callbackObj.state) { 
            stateObj = JSON.parse(baseHelper.base64Decode(callbackObj.state));
            buildSearch = queryString.stringify(stateObj);
            callbackObj.search = buildSearch;
            callbackObj.path = props.location.pathname;
        }

        this.state = {
            stateObj
        }; 
        this.signin(callbackObj);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.OAUTH2_GOOGLE) {
            if (nextProps.status) {
                
                const { stateObj } = this.state;
                if (!stateObj.callback_url) {
                    this.props.history.push('/account/profile');
                }
            } else {
                this.props.history.push('/signin');
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
    }

    componentWillMount() {
        this.redirectAuth(this.props);
      }

    redirectAuth(props) {
        const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
        if (token) {
            props.history.push('/account/profile');
        }
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    signin = (callbackObj) => {
        
        this.props.signin(callbackObj);

    }

    render() {
         
        return (
            <div className="ladiui auth">
                 
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signin: (callbackObj) => dispatch(authActions.oauth2Goole(callbackObj))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(GoogleCallback);