import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';


const myCreditLogs = (data) => ({
    type: types.MY_CREDIT_LOGS,
    meta: {
        endpoint: endpoint.MY_CREDIT_LOGS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listDetail = (data) => ({
    type: types.LIST_ORDER_DETAIL,
    meta: {
        endpoint: endpoint.LIST_ORDER_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listPackages = (data) => ({
    type: types.LIST_PARTNER_PACKAGE,
    meta: {
        endpoint: endpoint.LIST_PARTNER_PACKAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listStore = (data) => ({
    type: types.LIST_STORE,
    meta: {
        endpoint: endpoint.LIST_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const upgrade = (data) => ({
    type: types.PARTNER_UPGRADE_STORE,
    meta: {
        endpoint: endpoint.PARTNER_UPGRADE_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const myCredit = (data) => ({
    type: types.MY_CREDIT,
    meta: {
        endpoint: endpoint.MY_CREDIT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const activeStore = (data) => ({
    type: types.ACTIVE_STORE,
    meta: {
        endpoint: endpoint.ACTIVE_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    myCreditLogs,
    listDetail,
    listStore,
    listPackages,
    upgrade,
    myCredit,
    activeStore
}