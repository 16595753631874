import React from 'react';

export default class LadiAppLogo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="ladiui-logo">
            <img src={this.props.logoURL ? this.props.logoURL : "https://w.ladicdn.com/ladiui/ladiapp/logo-ladiapp.svg"} alt='' />
        </div>
        )
    }
}