import React from 'react';
import { DateRangePicker } from 'react-dates';
import { omit } from 'lodash';
import i18n from '../i18n';
import 'react-dates/lib/css/_datepicker.css';
import '../assets/css/ladi_daterangepicker.css';
import baseHelper from '../helpers/BaseHelper';

class LadiDateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.moment = baseHelper.getMoment();
        this.last7Day = this.moment().subtract(7, 'day');
        this.last30Day = this.moment().subtract(30, 'day');
        this.firstDayOfMonth = this.moment().startOf('month');
        this.firstDayOfLastMonth = this.moment().subtract(1, 'month').startOf('month');
        this.lastDayOfLastMonth = this.moment().subtract(1, 'month').endOf('month');

        this.presets = [
            {
                text: 'DATE.YESTERDAY',
                start: this.moment().subtract(1, 'day'),
                end: this.moment().subtract(1, 'day'),
                all: false,
            },
            {
                text: 'DATE.TODAY',
                start: this.moment(),
                end: this.moment(),
                all: false,
            },
            {
                text: 'DATE.7DAY',
                start: this.last7Day,
                end: this.moment(),
                all: false,
            },
            {
                text: 'DATE.30DAY',
                start: this.last30Day,
                end: this.moment(),
                all: false,
            },
            {
                text: 'DATE.THIS_MONTH',
                start: this.firstDayOfMonth,
                end: this.moment(),
                all: false,
            },
            {
                text: 'DATE.LAST_MONTH',
                start: this.firstDayOfLastMonth,
                end: this.lastDayOfLastMonth,
                all: false,
            }
        ];
    }

    isSameDay = (a, b) => {
        if (!this.moment.isMoment(a) || !this.moment.isMoment(b)) return false;
        // Compare least significant, most likely to change units first
        // Moment's isSame clones moment inputs and is a tad slow
        return a.date() === b.date()
            && a.month() === b.month()
            && a.year() === b.year();
    }

    renderDatePresets = () => {
        const { startDate, endDate } = this.props;

        return (
            <div className="custom-btn-date-panel">
                {this.presets.map(({
                    text, start, end, all,
                }) => {
                    const isSelected = (this.isSameDay(start, startDate) && this.isSameDay(end, endDate)) || (!startDate && !endDate && all);
                    return (
                        <button
                            key={text}
                            type="button"
                            className={`custom-btn-date ${isSelected ? 'selected' : ''}`}
                            onClick={() => this.props.onDatesChange({ startDate: start, endDate: end })}>
                            {i18n.t(text)}
                        </button>
                    );
                })}
            </div>
        );
    }

    render() {
        // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
        // example wrapper but are not props on the SingleDatePicker itself and
        // thus, have to be omitted.
        const props = omit(this.props, [
            'autoFocus',
            'autoFocusEndDate',
            'initialStartDate',
            'initialEndDate',
            'presets',
        ]);

        const isAllTime = !this.props.startDate && !this.props.endDate;

        const styleAllTime = {
            position: 'absolute',
            zIndex: '1',
            background: '#fff',
            width: '202px',
            color: 'var(--main-secondary)',
            top: '1px',
            left: '1px',
            lineHeight: '34px',
            paddingLeft: '15px',
        };

        return (<div style={{ position: 'relative' }}>
            {isAllTime ? <div style={styleAllTime}>
                {i18n.t('BASE.CHOOSE_DATE')}
                <i className="ladiui icon icon-calendar-date"></i>
            </div > : ''}
            <DateRangePicker
                {...props}
                customInputIcon={
                    <i className="ladiui icon icon-date-time-picker" />
                }
                renderCalendarInfo={this.renderDatePresets}
            />
        </div>

        );
    }
}

export default LadiDateRangePicker;
