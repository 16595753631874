import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import appConst from '../config/const';
import baseHelper from '../helpers/BaseHelper';

import { withTranslation } from 'react-i18next';

class AffilateSubItem extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);
        this.state = {
            userInfo
        }
    }
    render() {
        const { t } = this.props;
        return (
            <ul key="affilate_submenu" className="ladiui submenu" onClick={(event) => {
                if (document.body.classList.contains('mini-navbar')) {
                    event.currentTarget.classList.remove('show');
                }
            }}>
                <li><NavLink key='affilateList' to="/affilate">{t('SIDE_BAR.AFFILATE_LIST')}</NavLink></li>
                <li><NavLink key='commission' to="/commission" >{t('SIDE_BAR.AFFILATE_COMMISSION')}</NavLink></li>
                <li><NavLink key='invoice' to="/invoice" >{t('SIDE_BAR.AFFILATE_INVOICE')}</NavLink></li>
            </ul>
        )
    }
}

class OrderSubItem extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        return (
            <ul key="order_submenu" className="ladiui submenu" onClick={(event) => {
                if (document.body.classList.contains('mini-navbar')) {
                    event.currentTarget.classList.remove('show');
                }
            }}>
                <li><NavLink key='order' to="/order" >{t('SIDE_BAR.ORDER')}</NavLink></li>
                <li><NavLink key='payment' to="/payment" >{t('SIDE_BAR.PAYMENT')}</NavLink></li>

            </ul>
        )
    }
}

export const SubItemAff = withTranslation()(AffilateSubItem);
export const SubItemOrder = withTranslation()(OrderSubItem);
const SubMenuItem = {
    affilate: <SubItemAff />,
    order: <SubItemOrder />

};

class SidebarItem extends React.Component {
    static propTypes = {
        keyStr: PropTypes.array,
        classLi: PropTypes.string,
        classNav: PropTypes.string,
        classIcon: PropTypes.string,
        to: PropTypes.string,
        title: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = {
            classOpen: this.props.classLi + ' ladi-sidebar-submenu'
        }
    }

    componentDidMount() {
        const checkPath = window.location.pathname.replace('/', '');
        const newClass = (this.props.keyStr.indexOf(checkPath) >= 0) ? this.props.classLi + " active" : this.props.classLi;
        this.setState({
            classOpen: newClass
        })
    }

    changeActiveMenu = (event) => {
        const checkPath = window.location.pathname.replace('/', '');
        const index = this.props.keyStr.indexOf(checkPath);
        const elements = document.getElementsByClassName('menu-item');
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove('active');
            }
        }

        const newClass = (index >= 0) ? this.state.classOpen += " active" : this.props.classLi;
        this.sendTracking(null);
        this.setState({
            classOpen: newClass
        })
    }
    sendTracking = (event) => {
        const { event_name } = this.props;
        // alert('event_name  ' + event_name);

    }
    render() {
        const { classNav, classIcon, to, title, subMenu, t } = this.props;

        if (!subMenu) {
            return (
                <li className={this.state.classOpen} onClick={this.changeActiveMenu}>
                    <NavLink to={to || '#link'} className={classNav}> <i className={classIcon} />
                        <div className="ladiui menu-text"><span>{t(title)} </span></div>
                    </NavLink>
                </li>
            );
        }

        return (
            <li className={this.state.classOpen} onClick={this.sendTracking}>
                <a className={classNav}> <i className={classIcon} />  <div className="ladiui menu-text"><span>{t(title)} </span></div></a>
                {
                    SubMenuItem[subMenu]
                }
            </li>

        );
    }
}

export default withTranslation()(SidebarItem);