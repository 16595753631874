import React from 'react';

import authActions from '../redux/futures/auth/actions';
import * as types from '../redux/futures/auth/types';
import { connect } from 'react-redux';
import AppLayout from './AppLayout';
import CheckoutLayout from './CheckoutLayout';
import CookieLayout from './CookieLayout';
import LoadingScene from '../components/LoadingScene';

import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import baseHelper from '../helpers/BaseHelper';
import appConst from '../config/const';
import VerifyEmailLayout from './VerifyEmailLayout';
import 'react-dates/initialize';
class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: props.location.search,
            path: props.location.pathname,
            isLoadingData: types.GET_USER_INFO

        };
    }

    componentDidMount() {
        const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
        const data = {
            token,
            search: this.state.search,
            path: this.state.path
        }

        this.props.getUserInfo(data);
    }

    render() {
        const isLoadingData = this.state.isLoadingData && (this.props.waitting == types.GET_USER_INFO);
        return (

            <div>
                {
                    isLoadingData
                        ?
                        <LoadingScene />
                        :
                        <Switch>
                            <Route path='/(checkout)' render={({ location }) => (
                                <CheckoutLayout />
                            )} />
                            <Route path='/(verify-email)' render={({ location }) => (
                                <VerifyEmailLayout />
                            )} />
                            <Route path='/(cookie)' render={({ location }) => (
                                <CookieLayout />
                            )} />
                            <Route component={AppLayout} />
                        </Switch>
                }
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (data) => dispatch(authActions.getUserInfo(data)),
    }
}

const mapStateToProps = (state) => ({
    // ...state.auth 
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));