import React from 'react';
import PropTypes from 'prop-types';
import partnerActions from '../../../redux/futures/partner/actions';
import * as types from '../../../redux/futures/partner/types';

import appConst from '../../../config/const';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import UpgradeStore from './UpgradeStore';
import ActiveForm from './ActiveForm';
import i18n from '../../../i18n';

class UserAffInfo extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                code: ""

            },
            currentStore: {
                store_id: '',
                name: '',
                ladi_uid: '',
                pkg_name: '',
                pkg_exp: '',
                pkg_buy_exp: '',
                pkg_buy_price: '0',
                created_at: '',
                email: ''
            },
            appCode: appConst.STORE_CONFIG.APP_CODE.LP.value,
            selectedApp: {
                name: appConst.STORE_CONFIG.APP_CODE.LP.name,
                value: appConst.STORE_CONFIG.APP_CODE.LP.value,
                selected: appConst.STORE_CONFIG.APP_CODE.LP,
            },
            currentDetail: {},
            stores: [],
            currentTab: 'Store'
        };

    }


    componentDidUpdate() {
        window.LadiUI.init();
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.currentUser && this.state.currentUser !== nextProps.currentUser) {
            this.setState({
                currentUser: nextProps.currentUser,
                stores: [],
                detail: []
            }, () => {
                const { currentUser, appCode } = this.state;
                const data = {
                    store_name: '',
                    app_code: appCode,
                    limit: 20,
                    offset: 0,
                    ladi_uid: currentUser._id

                };

                if (currentUser._id !== '' && currentUser._id !== undefined) {
                    this.props.listStore(data);
                }
                const date = new Date();
                const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

                const dataOrder = {
                    "ladi_uid": currentUser._id,
                    "from_date": firstDay,
                    "to_date": new Date(),

                };

                this.props.listDetail(dataOrder);

            });
        }
        if (nextProps.action && !_.includes(this.muteActions, nextProps.action)
            && nextProps.action === types.LIST_STORE) {
            if (nextProps.status) {
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');

            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }

        if (nextProps.action === types.PARTNER_UPGRADE_STORE) {
            if (nextProps.status) {
                const seft = this;
                setTimeout(function () {
                    const { currentUser, appCode } = seft.state;
                    const data = {
                        store_name: '',
                        app_code: appCode,
                        limit: 20,
                        offset: 0,
                        ladi_uid: currentUser._id
                    };
                    seft.props.listStore(data);

                    const req = {}
                    seft.props.myCredit(req);
                }, 1500);
            } else {
                window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
            }
        }
        if (nextProps.action === types.MY_CREDIT) {
            const userInfo = baseHelper.getCookie(appConst.USER_INFO);
            if (nextProps.status) {
                userInfo.credit = nextProps.credit;
                baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(userInfo), appConst.COOKIE_EXPRIRE);
            } else {
            }
        }

        if (nextProps.action === types.LIST_STORE) {

            if (nextProps.status) {
                this.setState({
                    stores: nextProps.stores,
                });
            } else {
            }
        }

        if (nextProps.action === types.LIST_ORDER_DETAIL) {

            if (nextProps.status) {

                this.setState({
                    details: nextProps.details,
                });
            } else {
            }
        }
    }

    upgradeStore = (store) => {
        // this.formRef.current.resetForm();
        let { currentStore, currentUser, appCode } = this.state;
        currentStore = store;
        currentStore.email = currentUser.email;

        this.setState({
            currentStore
        }, () => {
            this.props.listPackages({
                app_code: appCode
            });
        });

    }

    listStoreUser = () => {
        const { currentUser, appCode } = this.state;
        this.setState({ currentTab: 'Store' });
        const data = {
            store_name: '',
            app_code: appCode,
            limit: 20,
            offset: 0,
            ladi_uid: currentUser._id
        };
        this.props.listStore(data);
    }

    listDetailUser = () => {
        const { currentUser } = this.state;
        this.setState({ currentTab: 'Order' });
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const dataOrder = {
            "ladi_uid": currentUser._id,
            "from_date": firstDay,
            "to_date": new Date(),

        };

        this.props.listDetail(dataOrder);
    }

    cancelOrder = (currentDetail) => {
        this.setState({
            currentDetail,
            appCode: 'LP'
        }, () => {
            this.props.listPackages();
        });
    }


    onCancel = () => {
        this.setState({
            formVisible: false
        });
    }

    onChangeApp = (app) => {
        this.setState({
            selectedApp: {
                name: app.name,
                value: app.value,
                selected: app
            },
            appCode: app.value,
        }, () => {
            const { selectedApp, currentUser } = this.state;
            const data = {
                store_name: '',
                app_code: selectedApp.value,
                limit: 20,
                offset: 0,
                email: currentUser.email,
                ladi_uid: currentUser._id

            };
            if (currentUser.email != '' && currentUser.email != undefined) {
                this.props.listStore(data);

            }
        })
    }

    openCreateForm = () => {
        const { currentUser, selectedApp } = this.state;
        // this.getListPackages(selectedApp.value);
        // console.log('openCreateForm', this.state.currentUser);
        this.setState({
            appCode: 'LS',
            currentUser
        })
    }

    render() {
        const { totalRecord } = this.props;
        const { currentStore, details, stores, currentTab, selectedApp, appCode, currentUser } = this.state;
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);
        // console.log('currentTab:', currentTab)
        return (
            <div id="modal-useraff-detail" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-useraff-detail">
                <div className='ladiui modal-dialog modal-dialog-centered' style={{ width: '1150px' }}>
                    <div className="ladiui modal-content">
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{i18n.t('AFFILATE.CUSTOMER_INFO')}: {currentUser.email}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-useraff-detail"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>

                        <div className="ladiui modal-body">

                            <ul className="ladiui nav-tabs" id="myTab" role="tablist">
                                <li className="ladiui tab-nav-item" role="presentation" onClick={this.listStoreUser}>
                                    <span className="ladiui tab-link active" id="home-tab" data-toggle="tab"
                                        data-parent="myTab" data-target="home">{i18n.t('AFFILATE.STORE_LIST')}</span>
                                </li>
                                <li className="ladiui tab-nav-item" role="presentation" onClick={this.listDetailUser}>
                                    <span className="ladiui tab-link" id="profile-tab" data-toggle="tab" data-parent="myTab"
                                        data-target="profile">{i18n.t('AFFILATE.ORDER')}</span>
                                </li>
                            </ul>
                            <div className="ladiui tab-content" id="myTabContent">
                                {
                                    currentTab == 'Store' &&
                                    <div className="action-item">
                                        <div className="ladiui dropdown">
                                            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                                <span className="ladiui dropdown-text">{i18n.t(selectedApp.name)}</span>
                                            </button >
                                            <ul className="ladiui dropdown-menu">
                                                {
                                                    _.map(appConst.STORE_CONFIG.APP_CODE, option =>
                                                        <li key={option.value} onClick={() => this.onChangeApp(option)}><a className="ladiui dropdown-item">{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                <div className="action-item">
                                    {appCode == 'LS' && stores.length == 0 &&
                                        <button style={{ marginRight: "5px" }} className="ladiui btn btn-primary open-modal"
                                            data-toggle="modal" data-target="modal-active-store" onClick={this.openCreateForm}>Kích hoạt LadiSales</button>
                                    }
                                    {/* {appCode == 'LC' && stores.length == 0 &&
                                        <button className="ladiui btn btn-primary open-modal" data-toggle="modal" data-target="modal-active-store" onClick={this.upgradeStore}>Kích hoạt LadiChat</button>
                                    } */}
                                </div>

                                {/* <div className="ladiui actions">
                                    <div className="action-item">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedApp.name}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.STORE_CONFIG.APP_CODE, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeApp(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="ladiui tab-pane fade show open" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <table className="ladiui table-vertical">
                                        <thead>
                                            <tr>
                                                <th className="text-left">{i18n.t('AFFILATE.PKG_NAME')}</th>
                                                <th className="text-left">{i18n.t('AFFILATE.ACTIVED_AT')}</th>
                                                <th className="text-left">{i18n.t('AFFILATE.EXP_AT')}</th>
                                                <th name="created_at" onClick={this.sort} className="text-left">{i18n.t('AFFILATE.CREATED_AT')}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {


                                                _.map(stores, store => {
                                                    let displayName = store.pkg_name;
                                                    if (store.pkg_name === 'PREMIUM_5') {
                                                        displayName = 'BUSSINESS_3';
                                                    }
                                                    if (store.pkg_name === 'PREMIUM_10') {
                                                        displayName = 'BUSSINESS_10';
                                                    }
                                                    if (store.pkg_name === 'PREMIUM_20') {
                                                        displayName = 'BUSSINESS_20';
                                                    }
                                                    displayName = displayName.replace('PREMIUM', 'BUSSINESS');
                                                    return <tr

                                                        key={store.store_id || store._id}
                                                    >
                                                        <td>{displayName}</td>
                                                        <td>{baseHelper.formatStrToDate(store.pkg_actived_at)}</td>
                                                        <td>{baseHelper.formatStrToDate(store.pkg_exp)}</td>
                                                        <td>{baseHelper.formatStrToDate(store.created_at)}</td>

                                                        <td>
                                                            {
                                                                userInfo.is_partner &&
                                                                <button className="ladiui btn btn-primary open-modal" onClick={() => this.upgradeStore(store)} data-toggle="modal" data-target="modal-partner-upstore" >Nâng cấp</button>
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                )
                                            }
                                            {
                                                totalRecord === 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="100%">Không có bản ghi nào</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ladiui tab-pane fade" id="profile" role="tabpanel"
                                    aria-labelledby="profile-tab">
                                    <table className="ladiui table-vertical">
                                        <thead>
                                            <tr>
                                                <th className="text-left">{i18n.t('AFFILATE.CREATED_AT')}</th>
                                                <th className="text-left">Store</th>
                                                <th className="text-left">App</th>
                                                <th className="text-left">{i18n.t('AFFILATE.PKG_NAME')}</th>
                                                <th className="text-left">{i18n.t('AFFILATE.MONTH')}</th>

                                                <th className="text-left">{i18n.t('AFFILATE.MONTH_BONUS')}</th>

                                                <th className="text-left">{i18n.t('BASE.TOTAL')}</th>
                                                <th className="text-left">Credit</th>

                                                {/* <th className="text-center">Discount Type</th>
                                        <th className="text-center">Discount Value</th> */}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(details, detail => {
                                                    let displayName = detail.pkg_name;
                                                    if (detail.pkg_name === 'PREMIUM_5') {
                                                        displayName = 'BUSSINESS_3';
                                                    }
                                                    if (detail.pkg_name === 'PREMIUM_10') {
                                                        displayName = 'BUSSINESS_10';
                                                    }
                                                    if (detail.pkg_name === 'PREMIUM_20') {
                                                        displayName = 'BUSSINESS_20';
                                                    }
                                                    displayName = displayName.replace('PREMIUM', 'BUSSINESS');
                                                    return <tr key={detail._id}>
                                                        <td>{baseHelper.formatStrToDate(detail.created_at)}</td>
                                                        <td>{detail.store_name}</td>
                                                        <td>{appConst.APP[detail.pkg_type]}</td>
                                                        <td>{displayName}</td>
                                                        <td>{detail.pkg_month}</td>
                                                        <td>{detail.addition_month}</td>
                                                        <td>{detail.subtotal.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                        <td>{baseHelper.numberWithCommas(detail.credit_used)}</td>
                                                    </tr>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ActiveForm
                    ref={this.formRef}
                    onCancel={this.onCancel}
                    currentUser={currentUser}
                    appCode={appCode}
                />
                <UpgradeStore
                    ref={this.formRef}
                    onCancel={this.onCancel}
                    currentStore={currentStore}
                    appCode={this.state.appCode}
                />
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        myCredit: (data) => dispatch(partnerActions.myCredit(data)),
        listStore: (data) => dispatch(partnerActions.listStore(data)),
        listDetail: (data) => dispatch(partnerActions.listDetail(data)),
        listPackages: (data) => dispatch(partnerActions.listPackages(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.partner });

export default connect(mapStateToProps, mapDispatchToProps)(UserAffInfo);