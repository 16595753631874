import React from 'react';
import PropTypes from 'prop-types';
import orderActions from '../../redux/futures/order/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import { authEndpoint } from '../../config/config';
import queryString from 'query-string';
import '../../assets/css/checkout.css';

import i18n from '../../i18n';
import * as types from '../../redux/futures/order/types';

class Checkout extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);

        const parsed = queryString.parse(this.props.location.search);
 
        this.state = {
            order: false,
            method:'CASH',
            usePoint: 'true',
            transaction_id: parsed.transaction_id,
            pointUsed: 0
        };


        this.getOrderByTransaction(parsed);
    }

    componentWillReceiveProps(nextProps) {

        const userinfo = baseHelper.getCookie(appConst.USER_INFO);
        if (nextProps.action == types.GET_TRANSACTION_DETAIL) {
            if (nextProps.status) {
                this.setState({
                    order: {
                        ...nextProps.order
                    }
                }, () => {
                    this.handleChangePoint()
                });
                

            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
        if (nextProps.action == types.CHECKOUT_ORDER) {
            
            if (nextProps.status) {
                if  (nextProps.url) {
                    window.location.href= nextProps.url;
                }else {
                    window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
                    userinfo.point = nextProps.new_point;
                    baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(userinfo),appConst.COOKIE_EXPRIRE);
                }
            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    onChangeMethod = (event) => {
         
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onChangePoint = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.handleChangePoint()
        });
    }

    handleChangePoint = () => {
          
        const userinfo = baseHelper.getCookie(appConst.USER_INFO);
        const  { order,usePoint } = this.state;
        let point = 0;
       
        if (usePoint == 'true') {
            if (userinfo.point >= order.total) {
                point = order.total;
            }else {
                point = userinfo.point;
            }
        }
        this.setState({
            pointUsed: point
        });
    }

    getOrderByTransaction = (parsed) => {
        
        if (parsed.transaction_id) {
            this.props.getOrderByTransaction({ transaction_id: parsed.transaction_id });
        }
    }

    submit = () => {
        const data = {
            order_id: this.state.order._id,
            method: this.state.method,
            use_point: this.state.usePoint
        };
        // console.log(data);
        this.props.doCheckout(data);
    }

    render() {
        const userinfo = baseHelper.getCookie(appConst.USER_INFO);
        const discount = 0;
        const { order } = this.state;
        let details = [];
        if (order) {
            details = order.details;
        }
        const parsed = queryString.parse(this.props.location.search);
        let pointUsed = 0;
        if (order.point_used){
            pointUsed = order.point_used;
        }else if(this.state.pointUsed >0) {
            pointUsed = this.state.pointUsed;
        }
         
        const appCode = (parsed.app_code) ? parsed.app_code.toUpperCase() : 'LUID';
        const cashInfo = i18n.t('CHECKOUT.CASH_INFO');
        return (
                
                !order 
                ?
                <div className="box-profile ladiui card text-center col" style={{ top: "80px"}}>
                    <h2>{i18n.t('CHECKOUT.ORDER_NOTEXISTS')}</h2>
                    <a href={authEndpoint.REDIRECT_APP_URL[appCode]}>
                    <button type="button" className="ladiui button primary">
                    {i18n.t('CHECKOUT.BACK')}
                        </button>
                    </a>
                </div>
                :
            
            <div className="ladiui-checkout">
             
                <div className="ladiui-checkout-body">
                    <div className="ladiui-checkout-body-left">
                    
                        <div className="ladiui-checkout-left-content">
                            <div className="ladiui-checkout-logo">
                                <img src="https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg" />
                            </div>
                            
                            <div className="ladiui-tab-content">
                            <a href={authEndpoint.REDIRECT_APP_URL[appCode]}>{i18n.t('CHECKOUT.BACK')}</a>
                                <div className="ladiui-tab-item active" id="tab-customer-info">
                                    <div className="account-details">
                                        <h3 className="checkout-heading">{i18n.t('CHECKOUT.PAYMENT_INFO')}</h3>
                                        <div className="form-group float-label">
                                            <label >Email</label>
                                            <input type="email" className="ladiui input" disabled='disable'
                                            value={userinfo.email}/>
                                        </div>
                                        <div className="ladiui-row flex">
                                            <div className="col-50 form-group float-label">
                                                <label >{i18n.t('CHECKOUT.NAME')}</label>
                                                <input type="text" className="ladiui input" disabled='disable'
                                            value={userinfo.fullname}/>
                                            </div>
                                            <div className="col-50 form-group float-label">
                                                <label >{i18n.t('CHECKOUT.PHONE')}</label>
                                                <input type="text" className="ladiui input" 
                                                disabled='disable'
                                                value={userinfo.phone}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui-tab-item active">
                                    <h3 className="checkout-heading">{i18n.t('CHECKOUT.PAYMENT_METHOD')}</h3>
                                    <div className="ladiui-checkout-payment-group">
                                    <div  className="ladiui-checkout-payment">
                                            <div  className="ladiui-checkout-payment-header">
                                                <span>
                                                    <input type="radio" id="payment-cod" //name="ladiui-payment-radio-group"
                                                    name="method"
                                                    value={appConst.PAYMENT_METHOD_GATEWAY.CASH}
                                                    onChange={this.onChangeMethod}
                                                    defaultChecked
                                                    />
                                                    <label htmlFor="payment-cod">{i18n.t('CHECKOUT.CASH')}</label>
                                                </span>
                                            </div>
                                            <div className={ 
                                             (this.state.method == appConst.PAYMENT_METHOD_GATEWAY.CASH)
                                             ?
                                             'ladiui-checkout-payment-body active'
                                             :
                                             'ladiui-checkout-payment-body'
                                            }>
                                                <div dangerouslySetInnerHTML={{ __html: cashInfo }}>
                                                </div>
                                                
                                                
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="ladiui-checkout-payment" >
                                            <div className="ladiui-checkout-payment-header">
                                                <span>
                                                    <input type="radio" id="payment-zalopay" name="method"
                                                    value={appConst.PAYMENT_METHOD_GATEWAY.ZALOPAY}
                                                    onChange={this.onChangeMethod}
                                                    
                                                    />
                                                    <label htmlFor="payment-zalopay"><img height="18px" src="https://file.hstatic.net/1000003969/file/logozlp1.png"/>
                                                    </label>
                                                </span>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <div className="ladui-row">
                                    <div className="form-group text-right">
                                        <button type="button" className="checkout-button ladiui button primary" onClick={this.submit}>{i18n.t('CHECKOUT.CONFIRM')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ladiui-checkout-body-right">
                            <div className="ladiui-checkout-right-content">
                                <div className="ladiui-checkout-items">
                                     
                                    {
                                        details && 
                                        _.map(details, item =>
                                            <div className="ladiui-checkout-item" key={item._id} >
                                                <div className="ladiui-checkout-item-info">
                                                    <h3>{appConst.APP[item.pkg_type] + ' ' + item.pkg_name}</h3>

                                                    <p>Store: {item.store_id} / {item.pkg_month} month</p>
                                                     
                                                    
                                                </div>
                                                <div className="ladiui-checkout-item-total">
                                                    <strong>{baseHelper.numberWithCommas(item.subtotal)}</strong><sup>đ</sup>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                
                                {
                                    (userinfo.point > 0 && !order.point_used) &&
                                        <div className="ladiui-checkout-total-row">
                                             <div className="ladiui-checkout-total-row">
                                            <label>{i18n.t('CHECKOUT.POINT_LABEL')} {userinfo.point} point</label>
                                            </div>
                                            <div className="ladiui-checkout-total-row">
                                               <div> 
                                                    <input type="radio" id="use-point" 
                                                    name="usePoint"
                                                    value='true'
                                                    onChange={this.onChangePoint}
                                                    defaultChecked
                                                />
                                                <label htmlFor="use-point">{i18n.t('CHECKOUT.POINT_USE_LABEL')}</label>
                                                </div>
                                                <div>
                                                    <input type="radio" id="notuse-point" 
                                                    name="usePoint"
                                                    value='false'
                                                    onChange={this.onChangePoint}
                                                />
                                                <label htmlFor="notuse-point">{i18n.t('CHECKOUT.POINT_NOT_USE_LABEL')}</label>
                                                </div>
                                            </div></div>
                                    }
                                <div className="ladiui-checkout-form-total">
                                    <div className="ladiui-checkout-total-row">
                                        <span>{i18n.t('CHECKOUT.TOTAL')}</span><span>{baseHelper.numberWithCommas(order.total)}<sup>đ</sup></span>
                                    </div>
                                    <div className="ladiui-checkout-total-row">
                                        <span>{i18n.t('CHECKOUT.DISCOUNT')}</span><span>{baseHelper.numberWithCommas(discount)}<sup>đ</sup></span>
                                    </div>
                                    {
                                        pointUsed &&
                                        <div className="ladiui-checkout-total-row">
                                            <span>{i18n.t('POINT_USE_LABEL')}</span><span>{baseHelper.numberWithCommas(pointUsed)}<sup> Point</sup></span>
                                        </div>
                                    }
                                    <div className="ladiui-checkout-total-row last">
                                        <span>Total</span><span>{baseHelper.numberWithCommas(order.total - discount - pointUsed * 1000)}<sup>đ</sup></span>
                                    </div>


                                </div>
                            


                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            
        )
}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOrderByTransaction: (data) => dispatch(orderActions.getOrderByTransaction(data)),
        doCheckout: (data) => dispatch(orderActions.doCheckout(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);