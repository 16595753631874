import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.CANCEL_ORDER: {
            return {
                ...state,
                action: types.CANCEL_ORDER,
                status: action.status,
                message: action.message,
            }
        } 
        case types.CREATE_ORDER: {
            return {
                ...state,
                action: types.CREATE_ORDER,
                status: action.status,
                message: action.message,
            }
        }
        case types.DETAIL_ORDER: {
            return {
                ...state,
                action: types.DETAIL_ORDER,
                details: action.status ? action.payload.details : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.MY_ORDER: {
            return {
                ...state,
                action: types.MY_ORDER,
                orders: action.status ? action.payload.orders : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }

        case types.GET_TRANSACTION_DETAIL: {
            return {
                ...state,
                action: types.GET_TRANSACTION_DETAIL,
                order: action.status ? action.payload.order : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }

        case types.CHECKOUT_ORDER: {
            return {
                ...state,
                action: types.CHECKOUT_ORDER,
                url: action.status ? action.payload.url : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        
        
        default:
            return state;
    }
}

