export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_VERIFY = 'SIGNUP_VERIFY';
export const RESEND_OTP = 'RESEND_OTP';
export const SUBMIT_OTP = 'SUBMIT_OTP';
export const SIGNOUT = 'SIGNOUT';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_REF_AFFILATE = 'GET_REF_AFFILATE';
export const GET_REFFERRAL_INFO = 'GET_REFFERRAL_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const SUBMIT_VERIFY_EMAIL = 'SUBMIT_VERIFY_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_URL_FACEBOOK = 'GET_URL_FACEBOOK';
export const GET_URL_GOOGLE = 'GET_URL_GOOGLE';
export const OAUTH2_FACEBOOK = 'OAUTH2_FACEBOOK';
export const OAUTH2_GOOGLE = 'OAUTH2_GOOGLE';
export const LOGOUT_ALL = 'LOGOUT_ALL';
export const COMPLETE_REGISTER = 'COMPLETE_REGISTER';
export const CHANGE_AFFILATE_CODE = 'CHANGE_AFFILATE_CODE';
export const CHANGE_FLOW_AFFILATE_CODE = 'CHANGE_FLOW_AFFILATE_CODE';
