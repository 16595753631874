import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: action.currentType,
                action: commonTypes.ASYNC_START,
            }
        }
        case types.USER_LIST: {
            return {
                ...state,
                action: types.USER_LIST,
                users: action.status ? action.payload.users : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.ADMIN_UPDATE_USER: {
            return {
                ...state,
                action: types.ADMIN_UPDATE_USER, 
                status: action.status,
                message: action.message,
            }
        }
        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                loading: false,
            }
        }

        default:
            return state;
    }
}

