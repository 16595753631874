import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import Input from '../../../components/Input';
import i18n from '../../../i18n';
import * as types from '../../../redux/futures/auth/types';
const FormData = require('form-data');

class EditProfile extends React.Component {

    static propTypes = {
        userInfo: PropTypes.object
    }

    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);

        this.state = {
            file: null,
            userInfo: userInfo,
            selectedLanguage: appConst.LANGUAGE[userInfo.language] || appConst.LANGUAGE.vi,
            loading: false,
            classNormal: "ladiui btn btn-primary",
            classLoading: "ladiui loader btn btn-primary",
            classApply: "ladiui btn btn-primary"

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();

    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.userInfo && this.state.userInfo !== nextProps.userInfo) {
            if (nextProps.userInfo.ladi_uid) {
                this.setState({
                    userInfo: nextProps.userInfo
                });
            }
        }
        if (nextProps.action == types.UPDATE_USER_INFO) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });

            if (nextProps.status) {
                this.setState({
                    userInfo: nextProps.userInfo
                });

                window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }
    }

    submit = () => {
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), errorMessage, 'OK');
            return;
        }

        const { userInfo, selectedLanguage } = this.state;

        const jsonData = {
            "fullname": userInfo.fullname,
            "phone": userInfo.phone,
            "time_zone": userInfo.tiomezone,
            "language": selectedLanguage.value
        }
        const form = new FormData();
        form.append('jsonData', JSON.stringify(jsonData));
        if (this.state.file) {
            form.append('avatar', this.state.file, this.state.file.name);
        }
        this.props.update(form);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeInput = (event) => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [event.target.name]: event.target.value
            }
        });
    }

    fileChangedHandler = (event) => {
        const that = this;
        if (event.target.files && event.target.files[0]) {

            const reader = new FileReader();
            reader.onload = function () {

                that.setState({
                    userInfo: {
                        ...that.state.userInfo,
                        avatar: reader.result
                    }
                });

            };
            reader.readAsDataURL(event.target.files[0]);
        }
        this.setState({ file: event.target.files[0] })
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        const userInfo = baseHelper.getCookie(appConst.USER_INFO);

        this.state = {
            userInfo: userInfo ? userInfo : {}
        }
    }

    onError = () => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                avatar: appConst.DEFAULT_AVATAR
            }
        });
    }

    onChangeLanguage = (option) => {
        this.setState({
            selectedLanguage: option,
        })
    }

    render() {
        const { userInfo, selectedLanguage } = this.state;
        const title = i18n.t('PROFILE.TITLE');
        return (
            <div ref={this.ref} id="modal-profile" className="ladiui modal" tabIndex={-1} data-dismiss="modal-profile" onClick={this.resetForm}>
                <div className='ladiui modal-dialog modal-dialog-centered custom-ldp-width'>
                    <div className="ladiui modal-content">
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-profile"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body custom-ldp">
                            <form>
                                <div className="ladiui-tab-content">
                                    <div className="ladiui-row">
                                        <div className="col-50 text-center">
                                            <div className={!userInfo.avatar ? 'profile-avatar no-avatar' : 'profile-avatar'} >
                                                {
                                                    !userInfo.avatar
                                                        ?
                                                        baseHelper.getFirstLetter(userInfo.fullname)
                                                        :
                                                        <img className="avatar-pic" src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} />
                                                }
                                                <div className="ladiui-button-upload text-center" onClick={() => {
                                                    document.getElementById('inputChangeAvatar').click();
                                                }}>
                                                    <i className="ladi-icon icon-file-upload" /> <img className='icon-change-avatar' src='https://w.ladicdn.com/ladiui/icons/icon-change-avatar.svg' />
                                                    <input id='inputChangeAvatar' type="file" name="file" onChange={this.fileChangedHandler} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-50">
                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('PROFILE.NAME')}</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="fullname"
                                                    validationName="FullName"
                                                    placeholder={i18n.t('PROFILE.NAME')}
                                                    value={userInfo.fullname}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>

                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('PROFILE.PHONE')}</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="phone"
                                                    validationName="Phone"
                                                    placeholder={i18n.t('PROFILE.PHONE')}
                                                    value={userInfo.phone}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: false }}
                                                />
                                            </div>
                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">Email</label>

                                                <input name="email" className="ladiui form-control" value={userInfo.email} disabled="disable" />
                                            </div>
                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('PROFILE.LANGUAGE')}</label>
                                                <div className="ladiui dropdown">
                                                    <button type='button' className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                                                        data-toggle="dropdown">
                                                        <span className="ladiui dropdown-text">{selectedLanguage.name || 'Ngôn nguwx'}</span>
                                                    </button>
                                                    <ul className="ladiui dropdown-menu">
                                                        {
                                                            _.map(appConst.LANGUAGE, option =>
                                                                <li key={option.value} className="ladiui dropdown-item" onClick={() => this.onChangeLanguage(option)}>{option.name}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button data-dismiss="modal" data-target="modal-profile" className="ladiui btn small txt body3 btn-secondary">
                                    {i18n.t("BASE.CANCEL")}
                                </button>
                                <div>
                                    <button className="ladiui btn small txt body3 btn-primary" onClick={() => this.submit()}>
                                        {i18n.t("BASE.UPDATE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (userInfo) => dispatch(authActions.updateUserInfo(userInfo))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);