import validator from 'validator';

class ValidatorHelper {
    isLength(str, max = undefined, min = 0) {
        return validator.isLength(str, min, max);
    }

    isLengthMoreThan(str = '', min) {
        return str.length < min;
    }

    isLengthLessThan(str = '', max) {
        return str.length > max;
    }

    isEmpty(str = '') {
        return validator.isEmpty(str);
    }

    isEmail(text) {
        try {
            // const re = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$', 'gi')
            const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')
            return re.test(text);
        } catch (err) {
            throw err;
        }
    }

    isPhone(text) {
        try {
            return validator.isMobilePhone(text);
        } catch (err) {
            throw err;
        }
    }

    isDate(text) {
        try {
            return validator.toDate(text);
        } catch (err) {
            throw err;
        }
    }

    isJson(text) {
        try {
            return validator.isJSON(text);
        } catch (err) {
            throw err;
        }
    }

    isAlphanumeric(text) { //kiểm tra xem chuỗi có phải chỉ chữa số và chữ cái hay không
        try {
            return validator.isAlphanumeric(text);
        } catch (err) {
            throw err;
        }
    }
}

export default new ValidatorHelper();