import React from 'react';
import { connect } from 'react-redux';
import authActions from '../redux/futures/auth/actions';
import { withRouter } from 'react-router-dom';
import appConst from '../config/const';
import routes from '../routes';
import baseHelper from '../helpers/BaseHelper';
import i18n from '../i18n'
import LadiLanguage from '../components/LadiLanguage'
class Topbar extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);

        this.state = {
            userInfo: userInfo,
            lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi'
        };
        this.signOut = this.signOut.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const availableRoutes = routes.getRoutesByScopes();
        let _name = '';
        if (availableRoutes && availableRoutes.length > 0) {
            for (let i = 0; i < availableRoutes.length; i++) {
                if (window.location.pathname.indexOf(availableRoutes[i].key) > 0) {
                    _name = availableRoutes[i].name;
                    break;
                }
            }
        }
        this.setState({
            pageName: _name
        })
    }

    signOut() {
        this.props.signOut();
        this.props.history.push('/signin?logout=1');
    }
    onError = () => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                avatar: appConst.DEFAULT_AVATAR
            }
        });
    }

    onChangeLanguage = (item) => {
        baseHelper.setCookie(appConst.COOKIE.LANGUAGE, item.code);
        i18n.changeLanguage(item.code);
        window.location.reload();
    }

    getSelectedLanguage = () => {
        for (let i = 0; i < appConst.LANGUAGES.length; i++) {
            if (this.state.lang === appConst.LANGUAGES[i].code)
                return appConst.LANGUAGES[i].icon;
        }
    }

    render() {
        const { userInfo } = this.state;

        return (
            <div className="ladiui topbar flex-row">
                <div className="ladiui topbar-menu">{i18n.t(this.state.pageName)}</div>
                <div className="ladiui topbar-menu-right flex-row">
                    <div className="ladiui app-tools flex-row">
                        <div className="ladiui tool-language">
                            <LadiLanguage/>
                        </div>
                    </div>
                    <div className="ladiui account">
                        <div className="ladiui my-account dropdown-toggle" data-toggle="dropdown">
                            <div className="ladiui my-account-avata">
                                {
                                    userInfo.avatar ?

                                        <img src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} width="40" height="40" />
                                        :
                                        <div className="ladiui boder-avatar">
                                            <div className="ladiui avarta no-avatar">
                                                <div id='setBackgroudColor' className="ladiui show-avata-name custom">
                                                    <div className="ladiui show-text-avata custom">
                                                        {baseHelper.getFirstLetter(userInfo.fullname)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="ladiui account dropdown-menu custom_r-10__3K4Bt">
                            <div className="ladiui account-content">
                                <div className="ladiui account-header">
                                    <div className="ladiui account-avatar">
                                        {
                                            userInfo.avatar ?

                                                <img className="ladiui avt" src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} width="40" height="40" />
                                                :
                                                <span
                                                    className="ladiui-topbar-noavatar"
                                                >
                                                    {baseHelper.getFirstLetter(userInfo.fullname)}
                                                </span>
                                        }

                                    </div>
                                    <div className="ladiui account-header-text">
                                        <span className="ladiui account-name body-1"> {(userInfo && userInfo.fullname) ? userInfo.fullname : 'UID'}</span>
                                        <div className="ladiui account-email subtitle-2 secondary-text">{userInfo.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ladiui account-content-footer">
                                <div className="ladiui account-footer"></div>
                            </div>
                            <a className="ladiui dropdown-item" href="/account/profile"><i className="ladiui icon icon-ldp-setting-builder"></i>{i18n.t('BASE.MANAGE_ACCOUNT')}</a><a className="ladiui dropdown-item" onClick={this.signOut}><i className="ladiui icon icon-ldp-log-out"></i>{i18n.t('BASE.LOG_OUT')}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(authActions.signOut()),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Topbar));