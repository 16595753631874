import React from 'react';
import { authEndpoint } from "../config/config";
import i18n from '../i18n';
import LadiLanguage from "../components/LadiLanguage";
import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class PoweredBy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isIframe: false,
            layout: 1
        }
    }

    componentDidMount() {

        const parsed = queryString.parse(this.props.location.search);

        if (parsed.iframe) {
            this.setState({
                isIframe: true
            });
        }
    }
    render() {
        const {
            isIframe,
        } = this.state;
        return (<>
            <div style={{ textAlign: "center" }}>
                {
                    isMobile ?
                        <React.Fragment>
                            <div className="ladipage-terms">
                                <ul>
                                    <li>
                                        <a target="_blank" href={authEndpoint.SUPPORT_URL.SECURE}>
                                            {i18n.t('AUTH.SERCURE_POLICY')}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            href={authEndpoint.SUPPORT_URL.SUPPORT}
                                            className="terms-item"
                                        >
                                            {i18n.t('AUTH.HELP')}
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={authEndpoint.SUPPORT_URL.POLICY}>
                                            {i18n.t('AUTH.TERM_OF_USE')}
                                        </a>
                                    </li>
                                </ul>

                            </div>
                            {!isIframe &&
                                <div className="language">
                                    <LadiLanguage />
                                </div>
                            }
                        </React.Fragment>
                        :
                        <div className="ladipage-terms">
                            <ul>
                                <li>
                                    <a target="_blank" href={authEndpoint.SUPPORT_URL.SECURE}>
                                        {i18n.t('AUTH.SERCURE_POLICY')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href={authEndpoint.SUPPORT_URL.SUPPORT}
                                        className="terms-item"
                                    >
                                        {i18n.t('AUTH.HELP')}
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href={authEndpoint.SUPPORT_URL.POLICY}>
                                        {i18n.t('AUTH.TERM_OF_USE')}
                                    </a>
                                </li>
                            </ul>
                            {!isIframe &&
                                <div className="language">
                                    <LadiLanguage />
                                </div>
                            }
                        </div>
                }
            </div>
        </>
        )
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const mapStateToProps = (state) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PoweredBy));