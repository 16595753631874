import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import authActions from "../../redux/futures/auth/actions";
import * as types from "../../redux/futures/auth/types";
import _ from "lodash";
import i18n from "../../i18n";
import Input from "../../components/Input";
import queryString from "query-string";
import appConst from "../../config/const";
import baseHelper from "../../helpers/BaseHelper";
import LadiIframe from "./LadiIframe";
import PoweredBy from "../../components/PoweredBy";
import LadiAppLogo from "../../components/LadiAppLogo";
import ReCAPTCHA from "react-google-recaptcha";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';



import RegisterWarning from "./RegisterWarning";
import app from "../../config/app";

class SignupV3 extends React.Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    let affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    let ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    if (parsed.ref && parsed.ref != '') {
      baseHelper.setCookie(appConst.AFFILATE_CODE, parsed.ref);
      affilateCode = parsed.ref;
    }
    if (parsed.affilate_code && parsed.affilate_code != '') {
      baseHelper.setCookie(appConst.AFFILATE_CODE, parsed.affilate_code);
      affilateCode = parsed.affilate_code;
    }
    if (parsed.ladiflow_ref && parsed.ladiflow_ref != '') {
      baseHelper.setCookie(appConst.LADIFLOW_REF, parsed.ladiflow_ref);
      ladiflowRef = parsed.ladiflow_ref;
    }


    let ladiClientID = parsed.LADI_CLIENT_ID;
    let referralID = baseHelper.getCookie(appConst.REFERRAL_ID);

    let accessTradeUtm = baseHelper.getCookie(appConst.ACCESS_TRADE.UTM_SOURCE);
    let accessTradeSid = baseHelper.getCookie(appConst.ACCESS_TRADE.AFF_SID);
    // console.log(parsed);

    if (referralID != undefined && referralID != "") {
      parsed.referral_id = referralID;
    }
    if (affilateCode != undefined && affilateCode != "") {
      parsed.affilate_code = affilateCode;
    }
    if (ladiflowRef != undefined && ladiflowRef != "") {
      parsed.ladiflow_ref = ladiflowRef;
    }
    if (accessTradeUtm != undefined && accessTradeUtm != "") {
      parsed.utm_source = accessTradeUtm;
    }

    if (accessTradeSid != undefined && accessTradeSid != "") {
      parsed.aff_sid = accessTradeSid;
    }

    if (ladiClientID != undefined && ladiClientID != "") {
      baseHelper.setCookie(appConst.LADI_CLIENT_ID, ladiClientID);
    }

    this.state = {
      email: "",
      password: "",
      errorPassword: "",
      errorObject: {
        policy: false,
        email: false,
        password: false,
        name: false,
        phone: false,
      },
      is_phone_valid: false,
      // confirmpass: '',
      country_code: 'vn',
      app_code: "",
      phone: "",
      name: "",
      recaptchaToken: "",
      recaptchaLoaded: false,
      refObject: parsed,
      loading: false,
      typeInput: "password",
      iconClass: "icon-password-hide",
      classNormal: "ladiui btn btn-primary full-width", //"ladiui button primary",
      classLoading: "ladiui loader btn btn-primary full-width",
      classDisable: "ladiui btn btn-disable full-width",
      classApply: "ladiui btn btn-primary full-width",
      search: this.props.location.search,
      token: "",
      lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi',
      iPayLogo: null,
      agree_policy: false,
      layout: 1,

    };
    this.data_key = false;
    this.isIframe = false;
    this.isConfirm = false;
    this.inputsRef = new Set();
    this.recaptchaRef = React.createRef();
    this.ref = React.createRef();
  }
  componentDidUpdate() {


  }
  async componentDidMount() {

    window.LadiUI.init();
    // document.addEventListener("mouseleave", this.handlerBack);

    // var that = this;
    // window.addEventListener("beforeunload", function () {
    //   alert('abd');
    //   that.handlerBack();
    //   if (!that.isConfirm) {
    //     that.isConfirm = true;
    //     return;
    //   }
    // });
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.callback_url && parsed.callback_url.indexOf('app.ipay.com.vn') >= 0) {
      this.setState({
        iPayLogo: 'https://w.ladicdn.com/ladisales/ipay.svg'
      });
    }
    if (parsed.iframe) {
      this.isIframe = true;
    }
    if (parsed.layout) {
      this.setState({ layout: parsed.layout });
    }
    document.body.classList.add('iframe-popupx');
    document.title = "Đăng ký LadiApp I All-in-One application for businesses";

  }


  componentWillUnmount() {
    document.removeEventListener("mouseleave", this.handlerBack);
  }
  componentWillMount() {
    this.redirectAuth(this.props);

  }

  handlerBack = () => {
    if (this.isConfirm || this.isIframe) {
      return;

    }
    window.LadiUI.showModal('modal-register-warning');
    this.isConfirm = true;
    return false;
  }
  redirectAuth(props) {
    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);

    if (token) {
      this.props.getUserinfo({
        token,
        search: this.props.location.search,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.action == types.SIGNUP_VERIFY) {
      const that = this;
      this.setState({
        loading: false,
        classApply: that.state.classNormal,
      });
      if (nextProps.status) {
        localStorage.setItem("email", this.state.email);
        let parsed = {};
        if (this.state.search) {
          parsed = queryString.parse(this.props.location.search);
        }

        const { verify_type, user, exp_time, is_require_verify } = nextProps.data;
        // console.log(' nextProps.data = ', nextProps.data);

        if (is_require_verify) {
          let strSearch = `?verify_type=${verify_type}&email=${user.email}&phone=${user.phone}&exp_time=${exp_time}&token=${user.token}`;
          if (parsed.callback_url && parsed.app_code) {
            strSearch += `&callback_url=${parsed.callback_url}&app_code=${parsed.app_code}`;
          }
          this.props.history.push(`/verify-account${strSearch}`);
        } else {
          if (!parsed.callback_url) {
            this.props.history.push("/account/profile");
          }
        }
        // }
        if (nextProps.event_track && window.gtag) {
          window.gtag('event', nextProps.event_track, {
            'event_category': 'LadiUID',
            'event_label': 'Section'
          });
        }
        if (is_require_verify) {
          this.setState({ token: nextProps.data.user.token });
        } else {
          this.setState({ token: nextProps.data.token });
        }
      } else {
        // window.LadiUI.showErrorMessage('', nextProps.message, 'OK');
        this.setState({ errorPassword: nextProps.message })
        this.refreshCaptcha();
      }
    }
    if (
      nextProps.action === types.GET_URL_FACEBOOK ||
      nextProps.action === types.GET_URL_GOOGLE
    ) {
      if (nextProps.status) {
        if (this.isIframe) {
          // window.parent.postMessage(JSON.stringify({
          //   ladi_uid: {
          //     redirect_url: nextProps.url
          //   }
          // }), '*');
          window.parent.location.href = nextProps.url;
          // console.log('nextProps.url = ', nextProps.url)
        } else {
          window.location.href = nextProps.url;
        }
      } else {
        window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
      }
    }
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onblurInput = (event) => {
    // console.log('onblurInput - ', event.target.name)
    let errors = [];
    const inputsRef = _.compact(Array.from(this.inputsRef));
    _.map(inputsRef, (ref) => {
      console.log(" ")
      if (ref.props.name == event.target.name) {
        if (ref.validate) {
          this.sendCaptureForm(true);
        }
      }

    });
  };

  signUp = async (event) => {
    event.preventDefault();
    const { errorObject } = this.state;
    if (this.isIframe && this.state.layout == 3 && window.gtag) {
      window.gtag('event', 'home_btn_signup_click', {
        'event_category': 'LadiUID',
        'event_label': 'Section'
      });
    }
    // Validate
    let errors = [];
    const inputsRef = _.compact(Array.from(this.inputsRef));
    _.map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
        if (ref.validate().length > 0) {
          errorObject[ref.props.name] = ref.validate()[0];
        } else {
          errorObject[ref.props.name] = false;
        }
      }
    });

    _.compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      // window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), errorMessage, 'OK');
      errorObject.policy = false;
      this.setState({ errorObject, errorPassword: "" })
      return;
    }


    // this.setState({
    //     recaptchaToken: token
    // })

    const {
      email,
      password,
      confirmpass,
      name,
      phone,
      refObject,
      recaptchaToken,
      is_phone_valid,
      agree_policy,
      country_code
    } = this.state;
    if (!baseHelper.passwordValidate(password.trim())) {
      errorObject.password = i18n.t('AUTH.PASSWORD_INVALID');
      this.setState({ errorObject, errorPassword: "" });
      return;
    }
    if (!agree_policy) {
      errorObject.policy = i18n.t('AUTH.ACCEPT_POLICY_ERROR');
      this.setState({ errorObject, errorPassword: "" })
      return;
    }
    if (!is_phone_valid) {
      errorObject.phone = i18n.t('VALIDATION.INVALID_PHONE');
      this.setState({ errorObject, errorPassword: "" })
      return;
    }
    const parsed = queryString.parse(this.props.location.search);
    let referralID = baseHelper.getCookie(appConst.REFERRAL_ID);
    let affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    let ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    let accessTradeUtm = baseHelper.getCookie(appConst.ACCESS_TRADE.UTM_SOURCE);
    let accessTradeSid = baseHelper.getCookie(appConst.ACCESS_TRADE.AFF_SID);
    const data = {
      country_code,
      email: email,
      password: password,
      phone: phone,
      app_code: "luid",
      referral_id: referralID,
      affilate_code: affilateCode,
      ladiflow_ref: ladiflowRef,
      fullname: name,
    };
    if (parsed.app_code) {
      data.app_code = parsed.app_code;
    }
    if (parsed.callback_url) {
      const parseCallback = queryString.parseUrl(
        decodeURIComponent(parsed.callback_url)
      );
      _.map(parseCallback.query, (value, key) => {
        data[key] = value;
      });
    }
    if (parsed.utm_source) {
      data.utm_source = parsed.utm_source;
    }

    if (accessTradeUtm && accessTradeUtm != "") {
      data.utm_source = accessTradeUtm;
    }
    if (accessTradeSid && accessTradeSid != "") {
      data.aff_sid = accessTradeSid;
    }

    if (
      accessTradeUtm &&
      accessTradeSid &&
      accessTradeUtm == "accesstrade" &&
      accessTradeSid != ""
    ) {
      delete data.affilate_code;
    }

    data.search = this.state.search;
    const that = this;
    if (!baseHelper.passwordValidate(password.trim())) {
      this.setState({ errorPassword: i18n.t('AUTH.PASSWORD_INVALID') })
      return;
    } else {
      this.setState(
        {
          errorPassword: '',
          loading: true,
          classApply: that.state.classLoading,
        },
        async () => {
          if (!recaptchaToken || recaptchaToken == "") {
            that.setState({
              loading: false,
              classApply: that.state.classNormal,
            });
            return;
          }
          data.token = recaptchaToken;
          if (!this.isIframe && this.state.layout == 3) {
            data.event_track = 'home_btn_signup_success';
          }
          this.sendCaptureForm(false);
          this.data_key = baseHelper.generateText(10);
          that.props.signUpVerify(data);
        }
      );
    }
  };

  callClose = (modal) => {
    modal.stopPropagation();
    if (modal.target.id && (modal.target.id == 'layout-2' || modal.target.id == 'close-login-frame')) {
      if (window.parent) {
        window.parent.postMessage(JSON.stringify({
          ladi_uid: {
            is_close_iframe: true
          }
        }), '*');
      }
    }
  }

  geturlFaceBook = () => {
    const { refObject } = this.state;
    delete refObject.logout;
    if (window.gtag) {
      if (this.isIframe && this.state.layout == 3) {
        window.gtag('event', 'home_FB_btn_signup_click', {
          'event_category': 'LadiUID',
          'event_label': 'Section'
        });
      } else {
        window.gtag('event', 'Facebook_btn_login_click', {
          'event_category': 'LadiUID',
          'event_label': 'Test'
        });
      }
    }
    if (this.isIframe && !refObject.callback_url) {
      refObject.app_code = 'lp';
      refObject.callback_url = appConst.DEFAULT_CALLBACK_URL;
    }
    if (this.isIframe && this.state.layout == 3) {
      refObject.event_track = 'home_FB_btn_signup_success';
    }
    // console.log('this.state.refObject = ', refObject);

    delete refObject.iframe;
    delete refObject.layout;
    // return;
    this.props.getUrlFacebook(refObject);
  };

  trackRedirectSignin = () => {
    if (!this.isIframe && this.state.layout == 3) {
      window.gtag('event', 'home_btn_relogin_click', {
        'event_category': 'LadiUID',
        'event_label': 'Section'
      });
    }
  }

  geturlGoogle = () => {
    const { refObject } = this.state;
    delete refObject.logout;
    if (window.gtag) {
      if (this.isIframe && this.state.layout == 3) {
        window.gtag('event', 'home_GG_btn_signup_click', {
          'event_category': 'LadiUID',
          'event_label': 'Section'
        });
      } else {
        window.gtag('event', 'Google_btn_login_click', {
          'event_category': 'LadiUID',
          'event_label': 'Test'
        });
      }
    }
    if (this.isIframe && !refObject.callback_url) {
      refObject.app_code = 'lp';
      refObject.callback_url = appConst.DEFAULT_CALLBACK_URL;
    }
    if (this.isIframe && this.state.layout == 3) {
      this.state.refObject.event_track = 'home_GG_btn_signup_success';
    }
    delete refObject.iframe;
    delete refObject.layout;
    // return;
    // console.log('this.state.refObject = ', this.state.refObject)
    // return;
    this.props.getUrlGoogle(this.state.refObject);
  };

  showPassword = () => {
    const { typeInput } = this.state;
    if (typeInput == "password") {
      this.setState({
        typeInput: "text",
        iconClass: "icon-password-view",
      });
    }
    if (typeInput == "text") {
      this.setState({
        typeInput: "password",
        iconClass: "icon-password-hide",
      });
    }
  };

  captchaSuccess = (token) => {
    const that = this;
    return new Promise(function (resolve, reject) {
      that.setState(
        {
          recaptchaToken: token,
        },
        () => {
          resolve();
        }
      );
      //Your code logic goes here

      //Instead of using 'return false', use reject()
      //Instead of using 'return' / 'return true', use resolve()
    }); //end promise

    // return;
    // this.recaptchaDone(token);
  };

  refreshCaptcha = () => {
    this.recaptchaRef.reset();
  };

  recaptchaLoaded(token) {
    // console.log("recaptchaLoaded:", token);
  }

  onChangeCaptcha = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  };

  checkAllItem = (event) => {
    const { checked } = event.target;
    this.setState({ agree_policy: checked });

  }

  sendCaptureForm = async (isCapture) => {
    const {
      email,
      name,
      phone,
    } = this.state;
    const parsed = queryString.parse(this.props.location.search);
    if (!this.data_key) {
      this.data_key = baseHelper.generateText(10);
    }
    const dataCapture = {
      "form_config_id": "6747e4b935ee1c0012eb439d",
      "ladi_form_id": "FORM2",
      "ladipage_id": "6747e4f935ee1c0012eb4eba",
      "tracking_form": [
        {
          "name": "url_page",
          "value": window.location.href
        },
        {
          "name": "utm_source",
          "value": parsed.utm_source || ''
        },
        {
          "name": "utm_medium",
          "value": parsed.utm_medium || ''
        },
        {
          "name": "utm_campaign",
          "value": parsed.utm_campaign || ''
        },
        {
          "name": "utm_term",
          "value": parsed.utm_term || ''
        },
        {
          "name": "utm_content",
          "value": parsed.utm_content || ''
        }
      ],
      "form_data": [
        {
          "name": "name",
          "value": name
        },
        {
          "name": "email",
          "value": email
        },
        {
          "name": "phone",
          "value": phone
        }
      ],
      "status_send": isCapture ? 1 : 2,
      "time_zone": 7,
      data_key: this.data_key
    }
    const options = {
      method: 'POST',
      url: app.API_FORM,
      headers: { 'Content-Type': 'application/json' },
      data: dataCapture
    };
    await baseHelper.sendRequest(options);
  }

  handlePhoneChange = (isValid, value, countryData) => {
    // setPhone(value);
    // setValid(isValid);

    if (countryData.iso2 == 'vn') {
      isValid = baseHelper.isPhoneValid(value, countryData.iso2);
    }
    // console.log("Selected Country Data:", isValid, value, countryData);
    this.setState({
      phone: value,
      is_phone_valid: isValid,
      country_code: countryData.iso2
    }, () => {
      if (isValid) {
        this.sendCaptureForm(true);
      }
    });

  }

  render() {
    const {
      email,
      password,
      errorPassword,
      token,
      name,
      phone,
      typeInput,
      iconClass,
      recaptchaToken,
      classNormal,
      classDisable,
      isIframe,
      agree_policy,
      errorObject,
      refObject,
      layout,
      is_phone_valid
    } = this.state;
    const search = this.props.location.search;

    let affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    let ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    if (refObject.ref && refObject.ref != '') {
      affilateCode = refObject.ref;
    }
    if (refObject.affilate_code && refObject.affilate_code != '') {
      affilateCode = refObject.affilate_code;
    }
    if (refObject.ladiflow_ref && refObject.ladiflow_ref != '') {
      ladiflowRef = refObject.ladiflow_ref;
    }
    const checkAff =
      affilateCode != undefined &&
      affilateCode != "" &&
      affilateCode != "null" &&
      affilateCode != "false";
    const checkLFAff =
      ladiflowRef != undefined &&
      ladiflowRef != "" &&
      ladiflowRef != "null" &&
      ladiflowRef != "false";

    return (
      appConst.ALLOW_RESISTER && (
        <div id={'layout-' + layout} className={"ladiui auth " + (token != '' ? "redirect-delay" : '') + (' layout-' + layout)} onClick={this.callClose} >
          {token == '' &&
            <div className="ladiui-login-wrap no-frame" style={this.state.iPayLogo ? { width: '100%' } : {}}>
              {!this.isIframe && <LadiAppLogo logoURL={this.state.iPayLogo} />}
              {!this.isIframe
                && <div style={{ textAlign: "center" }}>

                  <h1 className="form-title">{i18n.t('AUTH.SIGN_UP_ACCCOUNT')}</h1>
                  {/* <p className="form-sub">{i18n.t('AUTH.FILL_YOUR_INFO_TO_SIGNUP')}</p> */}
                  {/* <p className="form-sub">{i18n.t('AUTH.FILL_YOUR_INFO_TO_SIGNUP2')}</p> */}
                </div>
              }
              <div className="ladiui-login">
                {
                  layout == 2 &&
                  <button
                    id="close-login-frame"
                    type="button"
                    // data-dismiss="modal"
                    // data-target="modal-register-warning"
                    className="ladiui modal-close"
                    onClick={this.callClose}
                  >
                    <i className="ladiui icon icon-ldp-close"></i>
                  </button>
                }


                {this.isIframe &&
                  <div style={{ textAlign: "center" }}>

                    <h1 className="form-title">{i18n.t('AUTH.SIGN_UP_ACCCOUNT_L3')}</h1>
                  </div>
                }


                <div className="ladiui-login-form">
                  <div className={"ladiui-form create-user-form " + (layout != 3 ? "signup" : '')}>
                    <div className={`error-password ${errorPassword != '' ? 'is_error' : ''}`}>{errorPassword}</div>
                    <form onSubmit={this.signUp}>
                      <div className="ladiui-form-group ">
                        <div className={layout != 3 ? "" : ''}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.FULLNAME')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="name"
                            validationName={i18n.t('AUTH.FULLNAME')}
                            placeholder={i18n.t('AUTH.FILL_YOUR_FULLNAME')}
                            value={name}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: true }}
                          />
                          <div className={`error-input ${errorObject.name ? 'is_error' : ''}`}>{errorObject.name}</div>
                        </div>
                      </div>
                      <div className="ladiui-form-group ">
                        <div className={layout != 3 ? "" : 'mt-20'}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="email"
                            type='email'
                            validationName={i18n.t('AUTH.EMAIL')}
                            placeholder={i18n.t('AUTH.FILL_YOUR_EMAIL')}
                            value={email}
                            onBlur={this.onblurInput}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: true, email: true }}
                          />
                          <div className={`error-input ${errorObject.email ? 'is_error' : ''}`}>{errorObject.email}</div>
                        </div>

                      </div>
                      <div className="ladiui-form-group ">
                        <div className={layout != 3 ? "" : 'mt-20'}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PHONE')}</label>
                          {/* <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="phone"
                            validationName={i18n.t('AUTH.PHONE')}
                            placeholder={i18n.t('AUTH.FILL_YOUR_PHONE')}
                            value={phone}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: false, phone: true }}
                          /> */}
                          {/* <IntlTelInput
                            onChangeNumber={this.setNumber}
                            onChangeValidity={this.setIsValid}
                            initOptions={{
                              initialCountry: "vn",
                            }}
                          /> */}
                          <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName={`ladiui form-control` + (is_phone_valid ? '' : ' error')}
                            onPhoneNumberChange={(isValid, value, countryData) =>
                              this.handlePhoneChange(isValid, value, countryData)
                            }
                            defaultCountry="vn"
                            preferredCountries={['vn']}
                          // autoHideDialCode={false}
                          // separateDialCode={true}
                          // autoFormat={true} // Formats the input dynamically

                          />
                          <div className={`error-input ${errorObject.phone ? 'is_error' : ''}`}>{errorObject.phone}</div>
                        </div>

                      </div>
                      <div className="ladiui-form-group ">
                        <div className={layout != 3 ? "" : ''}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PASSWORD')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="password"
                            validationName={i18n.t('AUTH.PASSWORD')}
                            placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                            value={password}
                            onChange={this.onChangeInput}
                            validations={{
                              isRequired: true,
                              minLength: 8,
                              maxLength: 20,
                            }}
                            type={typeInput}
                          />
                          <i
                            className={
                              `ladiui icon ` + iconClass
                            }
                            onClick={this.showPassword}
                          ></i>
                          <div className={`error-input ${errorObject.password ? 'is_error' : ''}`}>{errorObject.password}</div>
                        </div>
                      </div>


                      <div className="ladiui-form-group recapcha">
                        <div>
                          <ReCAPTCHA
                            ref={(ref) => (this.recaptchaRef = ref)}
                            sitekey={appConst.KEY_CAPCHA}
                            onChange={this.onChangeCaptcha}
                            onExpired={this.refreshCaptcha}
                            type={"image"}
                          />
                        </div>
                      </div>
                      <div className="ladiui-form-group allow-policy">
                        <input id="accept-policy" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                        <div id="policy-text" style={{ textAlign: "left", fontSize: "12px" }}><label htmlFor="accept-policy">{i18n.t('AUTH.SIGNUP_CONFIRM_RULE')}<a href="https://ladipage.vn/dieukhoandichvu" target="_blank">{i18n.t('AUTH.TERM_OF_USE')}</a> & <a href="https://ladipage.vn/chinhsachbaomat" target="_blank"> {i18n.t('AUTH.SERCURE_POLICY')}</a> {i18n.t('AUTH.POLICY_FROM')}.</label></div>

                      </div>
                      <div className={`error-input ${errorObject.policy ? 'is_error' : ''}`}>{errorObject.policy}</div>
                      <div className="ladiui-form-group">
                        <button
                          ref={this.ref}
                          className={this.state.classApply}
                          onClick={this.signUp}
                          disabled={this.state.loading}
                        >
                          {i18n.t('AUTH.COMPLETE')}
                        </button>
                      </div>
                    </form>

                  </div>
                  <span className="login-with">
                    <h1>{i18n.t('AUTH.OR_3')} </h1>
                  </span>
                  <div className="social-login">

                    <div className="social-button">
                      <a
                        href="#"
                        className="ladiui btn login-google "
                        onClick={this.geturlGoogle}
                      >
                        <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg"></img>
                        <span>Google</span>
                      </a>
                      <a
                        href="#"
                        className="ladiui btn login-facebook "
                        onClick={this.geturlFaceBook}
                      >
                        <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg"></img>
                        <span>Facebook</span>
                      </a>

                    </div>
                  </div>
                  <div className="question-form">
                    {i18n.t('AUTH.HAVE_ACCOUNT')}
                    {layout == 1 && <a href={`/signin${search}`}>&nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}</a>}

                    {layout != 1 && <NavLink onClick={this.trackRedirectSignin}
                      to={{
                        pathname: "/signin",
                        search: search,
                      }}
                    >
                      &nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}
                    </NavLink>
                    }

                    {/* {layout == 2 && <NavLink  to={`/signin${search}`}>&nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}</NavLink>} */}
                    {/* // <a href={`/signin${search}`}>&nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}</a>} */}
                  </div>
                  {!checkAff ? (
                    <div></div>
                  ) : (
                    <div className="question-form">
                      {i18n.t('AUTH.AFF_BY')}: {affilateCode || ladiflowRef}
                    </div>
                  )}
                  {!checkLFAff ? (
                    <div></div>
                  ) : (
                    <div className="question-form">
                      {i18n.t('AUTH.LADIFLOW_AFF_BY')}: {ladiflowRef}
                    </div>
                  )}
                </div>

              </div>
              {layout == 1 &&
                <PoweredBy />
              }
            </div>
          }
          {/* {(!this.state.iPayLogo && layout == 1) ? <LadiIframe /> : <></>} */}
          {/* <RegisterWarning /> */}
        </div>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUpVerify: (data) => dispatch(authActions.signUpVerify(data)),
    getUrlFacebook: (refObject) =>
      dispatch(authActions.getUrlFacebook(refObject)),
    getUrlGoogle: (refObject) => dispatch(authActions.getUrlGoole(refObject)),
    getUserinfo: (token) => dispatch(authActions.getUserInfo(token)),
  };
};

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupV3));
