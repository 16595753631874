import React from 'react';
import { Route, Switch } from 'react-router-dom';
import '../assets/css/style.css';
import '../assets/css/custom.css';
import _ from 'lodash';
import routes from '../routes';
import Topbar from './Topbar';
import SideBar from './Sidebar';
import baseHelper from "../helpers/BaseHelper";
import appConst from "../config/const";
import i18n from '../i18n';
export default class AppLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi'
        };
    }

    componentDidMount() {
        const lang = baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi';
        i18n.changeLanguage(lang);
    }

    render() {
        const availableRoutes = routes.getRoutesByScopes({
            admin: true
        });
        const _class = 'ladiui wrapper ' + this.state.lang;
        return (
            <section className={_class}>
                <SideBar />
                <div className="ladiui main">
                    <Topbar />
                    <div className="ladiui content">
                        <div className='ladiui main-content'>
                            <Switch>
                                {
                                    _.map(availableRoutes, route => {
                                        return <Route key={route.name} path={route.path} exact={route.exact} component={route.component} />
                                    })
                                }
                            </Switch>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}