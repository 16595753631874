import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Input from '../../components/Input';
import queryString from 'query-string';
import LadiIframe from "./LadiIframe";
import PoweredBy from "../../components/PoweredBy";
import LadiAppLogo from "../../components/LadiAppLogo";
import { NavLink } from 'react-router-dom';
import * as types from '../../redux/futures/auth/types';
import i18n from '../../i18n';
import LadiLanguage from '../../components/LadiLanguage';
class ResetPasswordV3 extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);

        this.state = {
            passwordObj: {
                newpass: '',
                confirmpass: ''
            },
            errorObject: {
                newpass: false,
                confirmpass: false,
            },
            token: parsed.token ? parsed.token : '',
            loading: false,
            typeInput: 'password',
            iconClass: 'icon-password-hide',
            classNormal: "ladiui btn btn-primary full-width",
            classLoading: "ladiui loader btn btn-primary full-width",
            classApply: "ladiui btn btn-primary full-width",
        };

        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillMount() {
    }

    redirectAuth(props) {
        const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
        if (token) {
            props.history.push('/account/profile');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.RESET_PASSWORD) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    submit = (event) => {
        event.preventDefault();
        let { errorObject } = this.state;
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
                console.log(ref.props.name, '-', ref.validate());
                if (ref.validate().length > 0) {
                    errorObject[ref.props.name] = ref.validate()[0];
                } else {
                    errorObject[ref.props.name] = false;
                }
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            // const errorMessage = errors.join("<br/>");
            // window.LadiUI.toast('danger', '', errorMessage);
            // console.log('errorObject ', errorObject);
            this.setState({ errorObject })
            return;
        }

        const { passwordObj, token } = this.state;

        if (!baseHelper.passwordValidate(passwordObj.newpass.trim())) {
            errorObject.newpass = i18n.t('AUTH.PASSWORD_INVALID');
            this.setState({ errorObject, errorPassword: "" });
            return;
        }

        if (passwordObj.newpass != passwordObj.confirmpass) {
            // window.LadiUI.toast('danger', '', i18n.t('RESET_PASSWORD.ERROR_PASSWORD_MISSMATCH'));
            errorObject.confirmpass = i18n.t('RESET_PASSWORD.ERROR_PASSWORD_MISSMATCH');
            this.setState({ errorObject });
            return;
        }
        const jsonData = {
            "token": token,
            "password": passwordObj.newpass,
            "store_id": "",
            "app_code": "luid"
        }
        this.props.resetpass(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }

    onChangeInput = (event) => {
        this.setState({
            passwordObj: {
                ...this.state.passwordObj,
                [event.target.name]: event.target.value
            }
        });
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.state = {
            passwordObj: {
                newpass: '',
                confirmpass: ''
            }
        }
    }

    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput === 'password') {
            this.setState({
                typeInput: 'text',
                iconClass: 'icon-password-view'
            });
        }
        if (typeInput === 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-password-hide'
            });

        }
    }

    render() {
        const { passwordObj, typeInput, iconClass, errorObject } = this.state;
        const search = this.props.location.search;

        // console.log('errorObject ', errorObject)
        return (
            <div className="ladiui auth">
                <div className="ladiui-login-wrap no-frame">
                    <LadiAppLogo />
                    <h1 className="form-title" style={{ textAlign: "center" }}>{i18n.t('RESET_PASSWORD.TITLE')}</h1>
                    <div className="ladiui-login">


                        <div className="ladiui-login-form">
                            <div className="ladiui-form">
                                <form onSubmit={this.submit}>
                                    <div className="ladiui form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('RESET_PASSWORD.NEW_PASSWORD')}</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="newpass"
                                            validationName="NewPassword"
                                            placeholder={i18n.t('RESET_PASSWORD.NEW_PASSWORD')}
                                            value={passwordObj.newpass}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true, minLength: 8, maxLength: 20 }}
                                            type={typeInput}
                                        />
                                        <i className={`ladiui icon ` + iconClass} onClick={this.showPassword}></i>
                                        <div className={`error-input ${errorObject.newpass ? 'is_error' : ''}`}>{errorObject.newpass}</div>
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('RESET_PASSWORD.CONFIRM_PASSWORD')}</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="confirmpass"
                                            validationName="ConfirmPassword"
                                            placeholder={i18n.t('RESET_PASSWORD.CONFIRM_PASSWORD')}
                                            value={passwordObj.confirmpass}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true, minLength: 8, maxLength: 20 }}
                                            type={typeInput}
                                        />
                                        <div className={`error-input ${errorObject.confirmpass ? 'is_error' : ''}`}>{errorObject.confirmpass}</div>
                                    </div>
                                    <input type="hidden" name="token" value={this.state.token} />
                                    <div className="ladiui form-group">
                                        <button
                                            ref={this.ref}
                                            className={this.state.classApply}
                                            onClick={this.submit}
                                            disabled={this.state.loading}
                                        >
                                            <i style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                                            {i18n.t('RESET_PASSWORD.RESET_BUTTON')}</button>
                                    </div>
                                </form>
                            </div>
                            <div className="question-form">
                                <NavLink to={{
                                    pathname: "/signin",
                                    search: search
                                }}>{i18n.t('SIGNIN.BACK_LOGIN')}</NavLink>
                            </div>

                        </div>
                    </div>
                    <PoweredBy />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetpass: (passwordObj) => dispatch(authActions.resetPassword(passwordObj))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordV3);