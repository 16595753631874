import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.MY_CREDIT_LOGS: {
            return {
                ...state,
                action: types.MY_CREDIT_LOGS,
                creditlogs: action.status ? action.payload.creditlogs : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.MY_CREDIT: {
            return {
                ...state,
                action: types.MY_CREDIT,
                credit: action.status ? action.payload.credit : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.LIST_ORDER_DETAIL: {
            return {
                ...state,
                action: types.LIST_ORDER_DETAIL,
                details: action.status ? action.payload.details : [],
                totalRecord: action.status ? action.payload.total : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.LIST_STORE: {
            return {
                ...state,
                action: types.LIST_STORE,
                stores: action.status ? action.payload.stores : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.LIST_PARTNER_PACKAGE: {
            return {
                ...state,
                action: types.LIST_PARTNER_PACKAGE,
                packages: action.status ? action.payload.packages : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.PARTNER_UPGRADE_STORE: {
            return {
                ...state,
                action: types.PARTNER_UPGRADE_STORE,
                status: action.status,
                message: action.message,
            }
        }

        case types.ACTIVE_STORE: {
            return {
                ...state,
                action: types.ACTIVE_STORE,
                status: action.status,
                message: action.message,

            }
        }
        default:
            return state;
    }
}

