
import React from 'react';

import PropTypes from 'prop-types';

class CustomInputDate extends React.Component {

  static propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.any,
    value: PropTypes.string
  };

  render() {
    const { value, onClick, onChange, placeholder, id, customClass } = this.props;
    return (
      <div onClick={onClick}>
        <input
          className={`ladiui input ${customClass ? customClass : ''}`}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          id={id}
        />
        <i className="ladi-icon icon-calendar ladi-date-picker-icon"></i>
      </div>
    )
  }
}
export default CustomInputDate;