import React from "react";
import PropTypes from "prop-types";
import authActions from "../../redux/futures/auth/actions";
import _ from "lodash";
import { connect } from "react-redux";
import baseHelper from "../../helpers/BaseHelper";
import appConst from "../../config/const";
import queryString from "query-string";
import Input from "../../components/Input";
import { NavLink } from "react-router-dom";

import * as types from "../../redux/futures/auth/types";
import i18n from "../../i18n";
import LadiIframe from "./LadiIframe";
import PoweredBy from "../../components/PoweredBy";
import LadiLanguage from "../../components/LadiLanguage";
import LadiAppLogo from "../../components/LadiAppLogo";
class VerifyAccount extends React.Component {
    static propTypes = {
        code: PropTypes.string,
    };

    constructor(props) {
        super(props);

        const parsed = queryString.parse(this.props.location.search);
        let verify_type = appConst.VERIFY_ACCOUNT_TYPE.EMAIL;
        let email = '';
        let phone = '';
        let countDownTime = 60;
        let exp_time = 1;
        let token = false;
        if (parsed.verify_type && parsed.verify_type != '') {
            verify_type = parsed.verify_type;
        }
        if (parsed.email && parsed.email != '') {
            email = parsed.email;
        }
        if (parsed.phone && parsed.phone != '') {
            phone = parsed.phone;
        }
        if (parsed.exp_time && parsed.exp_time != '') {
            exp_time = parsed.exp_time;
        }

        if (parsed.token && parsed.token != '') {
            token = parsed.token;
        }

        this.state = {
            verify_type,
            email,
            phone,
            user: false,
            code: "",
            countDownTime,
            exp_time,
            token,
            search: this.props.location.search,
            loading: false,
            classNormal: "ladiui btn btn-primary full-width",
            classLoading: "ladiui loader btn btn-primary full-width",
            classApply: "ladiui btn btn-primary full-width",
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }
    componentDidMount() {
        this.timerId = setInterval(() => {
            this.setState((prevState) => ({
                countDownTime: prevState.countDownTime - 1,
            }));
        }, 1000);
    }
    componentWillMount() {
        this.redirectAuth(this.props);
    }
    redirectAuth(props) {
        const tokenCookie = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
        const { token } = this.state;
        if (!tokenCookie && !token) {
            props.history.push("/signin");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.SUBMIT_OTP) {
            if (nextProps.status) {
                // const user = nextProps.data;
                // console.log(' nextProps.data = ',nextProps.data)
                // this.setState({ verify_type, user });
                // window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
            } else {
                window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
            }
            this.setState({
                loading: false,
                classApply: this.state.classNormal,
            });
        }
        if (nextProps.action == types.RESEND_OTP) {
            if (nextProps.status) {
                const { exp_time } = nextProps.data;
                // const user = nextProps.data;
                // console.log(' nextProps.data = ',nextProps.data)
                this.setState({ exp_time: Math.ceil(exp_time), countDownTime: 60 }, () => {

                    clearInterval(this.timerId);
                    this.timerId = setInterval(() => {
                        this.setState((prevState) => ({
                            countDownTime: prevState.countDownTime - 1,
                        }));
                    }, 1000);
                });
                // window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
            } else {
                window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
            }
            this.setState({
                loading: false,
                classApply: this.state.classNormal,
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    componentDidUpdate() {
        window.LadiUI.init();
        if (this.state.countDownTime <= 0 && this.timerId) {
            clearInterval(this.timerId);
        }

    }

    submit = (event) => {
        event.preventDefault();

        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toast("error", "", errorMessage);
            return;
        }

        const { code, token } = this.state;
        const jsonData = {
            code,
            token,
            search: this.state.search
        };
        this.props.submitOtp(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    };

    onChangeInput = (event) => {
        this.setState({
            code: event.target.value,
        });
    };

    resendCode = () => {
        const { token, countDownTime } = this.state;
        if (countDownTime > 0) {
            return;
        }
        const jsonData = {

            token
        };
        this.props.resendOtp(jsonData);
    }
    render() {
        const { code, countDownTime, verify_type, phone, email } = this.state;
        // console.log(email);
        const search = this.props.location.search;
        return (
            <div className="ladiui auth">

                <div className="ladiui-login-wrap no-frame">
                    <LadiAppLogo />
                    <h1 className="form-title" >{i18n.t('VERIFY_ACCOUNT.TITLE')}</h1>
                    <div className="ladiui-login">

                        <div style={{ textAlign: "center" }}>
                            {
                                verify_type == appConst.VERIFY_ACCOUNT_TYPE.EMAIL.value ?
                                    '' :
                                    <img src='https://w.ladicdn.com/ladiui/icons/Message.svg' style={{ marginTop: '28px' }} />
                            }
                            <h4 style={{ marginTop: "32px" }}>{i18n.t("VERIFY_ACCOUNT.SUCCESS_SEND_MSG")}
                                {appConst.VERIFY_ACCOUNT_TYPE[verify_type].name}: &nbsp;
                                <div className="verify-to">
                                    {
                                        verify_type == appConst.VERIFY_ACCOUNT_TYPE.EMAIL.value ?
                                            email : phone
                                    }
                                </div>
                            </h4>
                            {/* <h4>{i18n.t("VERIFY_ACCOUNT.EXP_TIME")} {Math.floor(this.state.exp_time / 60)} Phút </h4> */}
                        </div>

                        <div className="ladiui-login-form create-user-form">
                            <div className="ladiui-form">
                                <form onSubmit={this.submit}>
                                    <div className="ladiui form-group" style={{ marginBottom: "16px", position: "relative" }}>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="code"
                                            validationName="Code"
                                            placeholder={i18n.t('VERIFY_ACCOUNT.CODE_HOLDER_TEXT')}
                                            value={code}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true }}
                                        />
                                        <span className="resend-opt" onClick={this.resendCode}>

                                            <span className={countDownTime > 0 ? 'disable-resend' : ''}>{i18n.t('VERIFY_ACCOUNT.RESEND_BTN')}&nbsp;</span>
                                            {
                                                countDownTime > 0 ? `(${countDownTime}s)`
                                                    : ''
                                            }

                                        </span>
                                    </div>
                                    <div className="ladiui-form-group">
                                        <button
                                            ref={this.ref}
                                            className={this.state.classApply}
                                            onClick={this.submit}
                                            disabled={this.state.loading}
                                        >
                                            <i
                                                style={{
                                                    display: this.state.loading ? "none" : "",
                                                    paddingRight: "5px",
                                                }}
                                            ></i>
                                            {i18n.t("VERIFY_ACCOUNT.CONFIRM_CODE")}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                    <PoweredBy />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resendOtp: (data) =>
            dispatch(authActions.resendOtp(data)),
        submitOtp: (data) =>
            dispatch(authActions.submitOtp(data)),
    };
};

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
