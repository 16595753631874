import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import { authEndpoint } from '../../config/config';
import * as typesAuth from '../../redux/futures/auth/types';
import * as typesAffilate from '../../redux/futures/affilate/types';
import affilateActions from '../../redux/futures/affilate/actions';
import authActions from '../../redux/futures/auth/actions';
import i18n from '../../i18n';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);
        if (userInfo.is_refferral === undefined) {
            userInfo.is_refferral = false;
        }
        const isSentMail = userInfo.is_sent_mail ? new Date(userInfo.is_sent_mail) : false;
        this.state = {
            userInfo: userInfo,
            affilateInfo: false,
            passwordObj: {
                newpass: '',
                confirmpass: ''
            },
            isSentMail
        }
        // this.ref =  React.createRef();
    }

    componentDidMount() {
        window.LadiUI.init();
        this.getAffilateInfo();
    }

    componentWillReceiveProps(nextProps) {

        const affilateInfo = this.state.affilateInfo;
        const userInfo = this.state.userInfo;
        if (nextProps.action == typesAuth.GET_USER_INFO) {
            if (nextProps.status) {
                this.setState({
                    userInfo: nextProps.userInfo,
                });
            }
        }
        if (nextProps.action == typesAuth.UPDATE_USER_INFO) {
            if (nextProps.status) {
                this.setState({
                    userInfo: nextProps.userInfo,
                    affilateInfo
                });
            }
            return;
        }

        if (nextProps.action == typesAuth.VERIFY_EMAIL) {
            if (nextProps.status) {
                userInfo.is_sent_mail = new Date();
                window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
                this.setState({
                    userInfo,
                    affilateInfo
                });
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
            return;
        }

        if (nextProps.action == typesAuth.GET_REFFERRAL_INFO) {
            if (nextProps.status) {
                const result = _.cloneDeep(nextProps.refferralInfo);
                userInfo.is_refferral = result.is_referral;
                this.setState({
                    userInfo,
                });
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
            return;
        }

        if (nextProps.affilate.action == typesAffilate.MY_AFFILATE_INFO) {

            if (nextProps.affilate.status) {
                this.setState({
                    affilateInfo: nextProps.affilate.affilateInfo,
                    userInfo
                }, () => {
                    this.props.getRefferralInfo();
                });
            } else {
                this.setState({
                    affilateInfo: false,
                    userInfo
                }, () => {
                    this.props.getRefferralInfo();
                });
            }
            return;
        }
    }
    getAffilateInfo = () => {
        this.props.getAffilateInfo();
    }

    resetForm() {

    }

    openCreateForm = () => {
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);
        this.resetForm();

        this.setState({
            userInfo: userInfo,
            passwordObj: {
                newpass: '',
                confirmpass: ''
            }
        })
    }

    openPaymentInfo = () => {
        const affilateInfo = this.state.affilateInfo;
        const userInfo = this.state.userInfo;

        this.setState({
            userInfo: userInfo,
            affilateInfo: affilateInfo
        })
    }

    onError = () => {

        this.setState({
            userInfo: {
                ...this.state.userInfo,
                avatar: appConst.DEFAULT_AVATAR
            }
        });
    }

    logoutAll = () => {

        this.props.logoutAll();
        this.props.signOut();
    }

    sendVerifyEmail = () => {
        const { isSentMail } = this.state;
        if (isSentMail) {
            const diff = baseHelper.diffDateMinute(new Date(), isSentMail);
            if (diff > 5) {
                this.props.sendVerifyEmail({});
            }
        } else {
            this.props.sendVerifyEmail({});
        }

    }

    getRefferralInfo = () => {
        this.props.getRefferralInfo();
    }

    getRefAffilate = () => {

        this.props.getRefAffilate();
    }

    copyToClipboardRef = (e) => {
        this.textAreaRef.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        window.LadiUI.toast('success', '', i18n.t('PROFILE.COPIED'));
    };

    copyToClipboardAff = (e) => {
        this.textAreaAff.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        window.LadiUI.toast('success', '', i18n.t('PROFILE.COPIED'));
    };

    onclickSelectedRef = (e) => {
        this.textAreaRef.select();
        e.target.focus();
    };

    onclickSelectedAff = (e) => {
        this.textAreaAff.select();
        e.target.focus();
    };
    render() {
        const { userInfo, affilateInfo } = this.state;
        const name = (userInfo && userInfo.fullname) ? userInfo.fullname : '';
        const email = (userInfo && userInfo.email) ? userInfo.email : '';
        const packages = (userInfo && userInfo.packages) ? userInfo.packages : {};

        const timezone = (userInfo && userInfo.timezone) ? userInfo.timezone : '7';

        var date = "September 21, 2011 00:00:00";
        var targetTime = new Date(date);
        var timeZoneFromDB = -7.00; //time zone value from database
        //get the timezone offset from local time in minutes
        var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
        //convert the offset to milliseconds, add to targetTime, and make a new Date
        var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);

        const referralUrl = authEndpoint.REGISTER_LP_URL + "gt=" +
            userInfo.ladi_uid;
        let affilateUrl = '';
        if (affilateInfo) {
            affilateUrl = authEndpoint.REGISTER_LP_URL + "ref=" +
                affilateInfo.code
        }
        const isLoadingLogout = this.props.loading && (this.props.waitting == typesAuth.LOGOUT_ALL);
        return (
            <>
                <div className="page-ladiuid-account pd24">
                    <div className="welcome text-center">
                        <div className={!userInfo.avatar ? 'profile-avatar no-avatar' : 'profile-avatar'} >
                            {
                                !userInfo.avatar
                                    ?
                                    baseHelper.getFirstLetter(userInfo.fullname)
                                    :
                                    <img src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} className="ladiui-profile-avatar" />
                            }

                        </div>
                        <h3>{i18n.t('PROFILE.HELLO_MSG')}, {(name == '') ? email : name}</h3>
                        <p className='text-center'>{email} | {(userInfo.phone) ? userInfo.phone : 'No Phone'}</p>
                        <div className='credit-info'>
                            <ul>
                                {userInfo.money && userInfo.money > 0 ?
                                    <li>
                                        <NavLink to="/credit">
                                            <span className="points">Tiền còn lại : {
                                                userInfo.money ? baseHelper.numberWithCommas(userInfo.money.toFixed(2)) : null
                                            }</span>
                                        </NavLink>
                                    </li> : <></>
                                }
                                {userInfo.credit && userInfo.credit > 0 ?
                                    <li>
                                        <NavLink to="/credit">
                                            <span className="points">Partner Credit : {
                                                userInfo.credit ? baseHelper.numberWithCommas(userInfo.credit.toFixed(2)) : null
                                            }</span>
                                        </NavLink>
                                    </li> : <></>
                                }
                                {userInfo.credit_app && userInfo.credit_app > 0 &&
                                    <li>
                                        <NavLink to="/credit" className={'ladiui link'}>
                                            <span className="points">API Credit : {
                                                userInfo.credit_app ? baseHelper.numberWithCommas(userInfo.credit_app.toFixed(2)) : 0
                                            }</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    !userInfo.is_verify_email &&
                                    <li>
                                        <a className="ladiui button sm outline-primary" onClick={this.sendVerifyEmail} >{i18n.t('PROFILE.VERIFY_EMAIL')}</a>
                                    </li>
                                }
                                {!userInfo.credit &&
                                    <li className="points"><span className='ladiui link'>{i18n.t('PROFILE.POINT')} : {
                                        userInfo.point ? baseHelper.numberWithCommas(userInfo.point) : 0
                                    }</span></li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="ladiui-row flex">
                        <div className="ladiui-group-box">
                            <div className="ladiui-group-items">
                                <img src="https://w.ladicdn.com/ladiui/ladiapp/ladipage-logo.svg" />
                                <h3>{i18n.t('LADIPAGE_APP.HEADING')}</h3>
                                <p className="ladiui-text-sub">
                                    {i18n.t('LADIPAGE_APP.DESC')}
                                </p>
                                <div className="ladiui-box-button">
                                    <a target="_blank" href="https://help.ladipage.vn?utm_source=ladiapp" className="ladiui-button-text">
                                        {i18n.t('DASHBOARD.GUIDE')}
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://ladipage.vn?utm_source=ladiapp"
                                        className="ladiui-button-text-url ladi-icon icon-right-arrow"
                                    >
                                        <span>{i18n.t('DASHBOARD.ACCESS')}</span>
                                    </a>
                                </div>
                            </div>
                            {i18n.language === 'vi' ?
                                <>
                                    <div className="ladiui-group-items">
                                        <img src="https://w.ladicdn.com/ladiui/ladiapp/ladiflow-logo.svg" />
                                        <h3>Nền tảng tương tác với khách hàng dựa trên hệ thống phân luồng tự động</h3>
                                        <p className="ladiui-text-sub">
                                            Tương tác với khách hàng đa kênh hoàn toàn tự động qua Messenger, Instagram, What'sapp, Email, SMS, Zalo Web, AI Call, Notification,...
                                        </p>
                                        <div className="ladiui-box-button">
                                            <a target="_blank" href="https://help.ladiflow.vn?utm_source=ladiapp" className="ladiui-button-text" style={{ display: 'none' }}>
                                                {i18n.t('DASHBOARD.GUIDE')}
                                            </a>
                                            <a
                                                target="_blank"
                                                href="https://www.ladiflow.vn?utm_source=ladiapp"
                                                className="ladiui-button-text-url ladi-icon icon-right-arrow"
                                            >
                                                <span>{i18n.t('DASHBOARD.ACCESS')}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ladiui-group-items">
                                        <img src="https://w.ladicdn.com/ladiui/ladiapp/ladishare-logo.svg" />
                                        <h3>Nền tảng Affilate Marketing. Thúc đẩy doanh số doanh nghiệp của bạn</h3>
                                        <p className="ladiui-text-sub">
                                            Quản lý các chiến dịch quảng cáo, affilate và phát triển chương trình affilate đơn giản hơn với LadiShare.
                                        </p>
                                        <div className="ladiui-box-button">
                                            <a target="_blank" href="https://help.ladishare.vn?utm_source=ladiapp" className="ladiui-button-text" style={{ display: 'none' }}>
                                                {i18n.t('DASHBOARD.GUIDE')}
                                            </a>
                                            <a
                                                target="_blank"
                                                href="https://www.ladishare.vn?utm_source=ladiapp"
                                                className="ladiui-button-text-url ladi-icon icon-right-arrow"
                                            >
                                                <span>{i18n.t('DASHBOARD.ACCESS')}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ladiui-group-items">
                                        <img src="https://w.ladicdn.com/ladiui/ladiapp/ladichat-logo.svg" />
                                        <h3>Nền tảng giao tiếp tối ưu trải nghiệp khách hàng đa kênh</h3>
                                        <p className="ladiui-text-sub">
                                            Livechat với khách hàng đa kênh chỉ trong một màn hình. Website Livechat, Messenger, Facebook Comments, Zalo,..
                                        </p>
                                        <div className="ladiui-box-button">
                                            <a target="_blank" href="https://help.ladichat.vn?utm_source=ladiapp" className="ladiui-button-text" style={{ display: 'none' }}>
                                                {i18n.t('DASHBOARD.GUIDE')}
                                            </a>
                                            <a
                                                target="_blank"
                                                href="https://www.ladichat.vn?utm_source=ladiapp"
                                                className="ladiui-button-text-url ladi-icon icon-right-arrow"
                                            >
                                                <span>{i18n.t('DASHBOARD.ACCESS')}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ladiui-group-items">
                                        <img src="https://w.ladicdn.com/ladiui/ladiapp/ladisales-logo.svg" />
                                        <h3>Nền tảng tạo trang thanh toán trực tuyến chỉ với 1 Click</h3>
                                        <p className="ladiui-text-sub">
                                            Đơn giản với 1-click bạn có thể tạo ngay một trang thanh toán (checkout) cho sản phẩm, dịch vụ và sự kiện của bạn.
                                        </p>
                                        <div className="ladiui-box-button">
                                            <a target="_blank" href="https://help.ladisales.vn?utm_source=ladiapp" className="ladiui-button-text">
                                                {i18n.t('DASHBOARD.GUIDE')}
                                            </a>
                                            <a
                                                target="_blank"
                                                href="https://www.ladisales.vn?utm_source=ladiapp"
                                                className="ladiui-button-text-url ladi-icon icon-right-arrow"
                                            >
                                                <span>{i18n.t('DASHBOARD.ACCESS')}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ladiui-group-items">
                                        <img src="https://w.ladicdn.com/ladiui/ladiapp/ladilink-logo.svg" />
                                        <h3>Công cụ rút gọn link và đo lường nguồn truy cập cho chuyển đổi</h3>
                                        <p className="ladiui-text-sub">
                                            Giúp việc tối ưu nội dung và đo lường, thay đổi đường dẫn Landing Page mà không ảnh hưởng đến chiến dịch quảng cáo của bạn.
                                        </p>
                                        <div className="ladiui-box-button">
                                            <a target="_blank" href="https://help.ladilink.vn?utm_source=ladiapp" className="ladiui-button-text">
                                                {i18n.t('DASHBOARD.GUIDE')}
                                            </a>
                                            <a
                                                target="_blank"
                                                href="https://www.ladilink.vn?utm_source=ladiapp"
                                                className="ladiui-button-text-url ladi-icon icon-right-arrow"
                                            >
                                                <span>{i18n.t('DASHBOARD.ACCESS')}</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                                : <></>}
                        </div>
                    </div>
                </div>
            </>
        );

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAffilateInfo: () => dispatch(affilateActions.myAffilateInfo()),
        getRefAffilate: () => dispatch(authActions.getRefAffilate()),
        getRefferralInfo: () => dispatch(authActions.getRefferralInfo()),
        logoutAll: () => dispatch(authActions.logoutAll()),
        signOut: () => dispatch(authActions.signOut()),
        sendVerifyEmail: () => dispatch(authActions.sendVerifyEmail()),
    }
}

const mapStateToProps = (state) => ({ ...state.auth, affilate: { ...state.affilate } });

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);