import React from 'react';
import Order from './pages/orders/Order';
// import Checkout from './pages/orders/Checkout'; 
import Point from './pages/points/Point';
import Credit from './pages/partner/Credit';
import Invoice from './pages/invoices/Invoice';
import Commission from './pages/commission/Commission';
import Referral from './pages/referrals/Referral';
import Payment from './pages/payments/Payment';
import Affilate from './pages/affilates/Affilate';
import SidebarItem from './components/SidebarItem';
import VerifyEmail from './pages/profile/VerifyEmail'

import Dashboard from './pages/dashboard/Dashboard';
import _ from 'lodash';
import Profile from './pages/profile/Profile';
import Package from './pages/packages/Package';

const routeMap = {
    '/': {
        key: '', path: '/', exact: true, name: 'MENU.DASHBOARD', component: Dashboard
    },
    '/dashboard': {
        key: 'dashboard', path: '/dashboard', exact: true, name: 'MENU.DASHBOARD', component: Dashboard
    },
    '/order': {
        key: "order", path: '/order', exact: true, name: 'MENU.ORDER', component: Order
    },
    '/verify-email': {
        key: "verify-email", path: '/verify-email', exact: true, name: 'Verify Email', component: VerifyEmail
    },
    '/affiliate': {
        key: "affiliate", path: '/affiliate', exact: true, name: 'MENU.AFFILATE', component: Affilate
    },
    '/payment': {
        key: "payment", path: '/payment', exact: true, name: 'MENU.PAYMENT', component: Payment
    },
    '/invoice': {
        key: "invoice", path: '/invoice', exact: true, name: 'MENU.INVOICE', component: Invoice
    },
    '/commission': {
        key: "commission", path: '/commission', exact: true, name: 'MENU.COMMISSION', component: Commission
    },
    '/account/profile': {
        key: "account/profile", path: '/account/profile', exact: true, name: 'MENU.PROFILE', component: Profile
    },
    '/profile': {
        key: "account/profile", path: '/profile', exact: true, name: 'MENU.PROFILE', component: Profile
    },
    '/package': {
        key: "package", path: '/package', exact: true, name: 'MENU.PACKAGE', component: Package
    },
    '/referral': {
        key: "referral", path: '/referral', exact: true, name: 'MENU.REFERRAL', component: Referral
    },
    '/point': {
        key: "point", path: '/point', exact: true, name: 'MENU.POINT', component: Point
    },
    '/credit': {
        key: "credit", path: '/credit', exact: true, name: 'MENU.CREDIT', component: Credit
    },

}

const menuItems = {
    '/dashboard':
        <SidebarItem
            keyStr={['dashboard']}
            key="dashboard"
            classLi="ladiui menu-item"
            to="/dashboard"
            classNav="ladiui flex-row-menu-item"
            classIcon="ladiui icon-menu-dashboard"
            title='MENU.DASHBOARD'

        />,
    '/order':
        <SidebarItem
            keyStr={['order', 'payment']}
            key='order'
            classLi="ladiui menu-item"
            to="/order"
            classNav="ladiui flex-row-menu-item"
            classIcon="ladiui icon-menu-item1"
            title='MENU.ORDER'
        />,
    '/affiliate':
        <SidebarItem
            keyStr={['affiliate', 'commission', 'invoice']}
            key='affiliate'
            classLi="ladiui menu-item"
            to="/affiliate"
            classNav="ladiui flex-row-menu-item"
            classIcon="ladiui icon-menu-partner"
            title='MENU.PARTNER'
            subMenu={false}
            event_name="ladi_uid_affiliate_menu_click"
        />
    ,
    '/credit':
        <SidebarItem
            keyStr={['credit']}
            key='credit'
            classLi="ladiui menu-item"
            to="/credit"
            classNav="ladiui flex-row-menu-item"
            classIcon="ladiui icon icon-menu-payment"
            title='MENU.CREDIT'

        />,
    '/account/profile':
        <SidebarItem
            keyStr={['account/profile']}
            key='account/profile'
            classLi="ladiui menu-item"
            to="/account/profile"
            classNav="ladiui flex-row-menu-item"
            classIcon="ladiui icon-menu-user"
            title='MENU.PROFILE'
        />
};

class Routes {
    getRoutesByScopes(scopes = {}) {
        const _routes = [];
        _.map(routeMap, (values) => {
            _routes.push(values);
        });
        return _routes;
    }

    getItemByScopes(scopes = {}) {
        if (scopes.admin) {
            return menuItems;
        }

        const items = [];


        _.map(scopes, (actions, scope) => {
            if (actions.length == 1 && actions[0] == '*') {
                items.push(menuItems[`/${scope}`]);
            }
        });

        return items;
    }
}

export default new Routes();