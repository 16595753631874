import { combineReducers } from 'redux';
import commonReducer from '../futures/common/reducer';
import authReducer from '../futures/auth/reducer';
import storeReducer from '../futures/store/reducer';
import orderReducer from '../futures/order/reducer';
import paymentReducer from '../futures/payment/reducer';
import packageReducer from '../futures/package/reducer';
import affilateReducer from '../futures/affilate/reducer';
import invoiceReducer from '../futures/invoice/reducer';
import commissionReducer from '../futures/commission/reducer';
import referralReducer from '../futures/referral/reducer';
import userReducer from '../futures/user/reducer';
import pointReducer from '../futures/point/reducer';
import partnerReducer from '../futures/partner/reducer';


import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    common: commonReducer,
    auth: authReducer,
    store: storeReducer,
    router: connectRouter(history),
    order: orderReducer,
    payment: paymentReducer,
    affilate: affilateReducer,
    partner: partnerReducer,
    package: packageReducer,
    invoice: invoiceReducer,
    commission: commissionReducer,
    referral: referralReducer,
    user: userReducer,
    point: pointReducer
});


