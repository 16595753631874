import React from 'react';
import PropTypes from 'prop-types';
import validatorHelper from '../helpers/ValidatorHelper';
import baseHelper from '../helpers/BaseHelper';
import i18n from '../i18n';

const TypeProps = {
    text: 'text',
    number: 'number',
    password: 'password'
}

export default class Input extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        validationName: PropTypes.string,
        value: PropTypes.string,
        prefix: PropTypes.string,
        placeholder: PropTypes.string,
        hasBlurValidate: PropTypes.bool,

        onChange: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyPress: PropTypes.func,

        validations: PropTypes.shape({
            isRequired: PropTypes.bool,
            min: PropTypes.number,
            max: PropTypes.number,
            phone: PropTypes.bool,
            email: PropTypes.bool
        })
    }

    static defaultProps = {
        type: TypeProps.text,
        hasBlurValidate: false,
        toggleValidate: false,
        validations: {
            isRequired: false,
            min: 0,
            max: 300,
            phone: false,
            email: false
        }
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    onBlur = (e) => {
        if (this.props.onBlur) {
            // console.log('this.props.onBlur ', e);
            this.props.onBlur(e);
        }
        if (!this.props.hasBlurValidate || !this.props.validations) {
            return;
        }

        e.preventDefault();

        this.validate();
        return false;
    }

    validate = () => {

        const { classList } = this.ref.current;
        classList.remove("error");

        let validateStatus = true;
        const errors = [];

        let { value } = this.props;
        value = value.trim();
        let { validationName, name } = this.props;
        if (!validationName) {
            validationName = name;
        }

        const { isRequired, minLength, maxLength, email, phone, min, max } = this.props.validations;
        if (isRequired) {
            if (validatorHelper.isEmpty(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.ENTER', validationName));
            }
        }

        if (minLength) {
            if (validatorHelper.isLengthMoreThan(value, minLength)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.LENGTH_MORE_THAN', validationName, minLength));
            }
        }

        if (maxLength) {
            if (validatorHelper.isLengthLessThan(value, maxLength)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.LENGTH_LESS_THAN', validationName, maxLength));
            }
        }

        if (min) {
            if (baseHelper.parseFloat(value) < baseHelper.parseFloat(min)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.GREATER_THAN', validationName, min));
            }
        }

        if (max) {
            if (baseHelper.parseFloat(value) > baseHelper.parseFloat(max)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.GREATER_THAN', validationName, max));
            }
        }


        if (email) {
            if (!validatorHelper.isEmail(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.INVALID_EMAIL'));
            }
        }

        if (phone) {
            if (!validatorHelper.isPhone(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.INVALID_PHONE'));
            }
        }

        if (!validateStatus) {
            classList.add("error");
        } else {
            classList.remove("error");
        }

        return errors;
    }

    render() {
        const { value, name, placeholder, onChange, hasBlurValidate, type, onKeyDown, onKeyPress } = this.props;
        const { min, max } = this.props.validations;

        return (
            <input ref={this.ref} name={name} className="ladiui form-control" placeholder={placeholder} value={value} onChange={onChange} onBlur={this.onBlur} type={type} onKeyDown={onKeyDown} min={min} max={max}
                onKeyPress={onKeyPress} />
        );
    }
}
