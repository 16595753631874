const config = {
        API_V2_0: "https://api.dev.ladiuid.com/2.0",
    }
    
    
    const authEndpoint = {
        LOG_OUT_URL: {
            LP: 'https://builder.dev.ladipage.com/signout.html',
            LS: 'https://app.dev.ladisales.com/signout.html',
            LL: 'https://app.dev.ladilink.com/signout.html',
            LF: 'https://app.dev.ladiflow.com/signout.html',
            LADISHARE: 'https://app.dev.ladishare.com/signout.html',
            LC: 'https://app.dev.ladichat.com/signout.html'
        },
        REDIRECT_APP_URL: {
            LP: 'https://builder.dev.ladipage.com',
            LS: 'https://app.dev.ladisales.com',
            LL: 'https://app.dev.ladilink.com',
            LF: 'https://app.dev.ladiflow.com',
            LADISHARE: 'https://app.dev.ladishare.com',
            LC: 'https://app.dev.ladichat.com'
        },
        REGISTER_LP_URL: 'https://ladipage.vn?',
        ALLOW_CALLBACK_URL: {
            LP: [
                'https://builder.dev.ladipage.com',
            ],
            LS: [
                'https://app.dev.ladisales.com'
            ],
            LL: [
                'https://app.dev.ladilink.com'
            ],
            LF: [
                'https://app.dev.ladiflow.com'
            ],
            LC: [
                'https://app.dev.ladichat.com'
            ],
            LADISHARE: [
                'https://app.dev.ladishare.com'
            ]
        },
        SUPPORT_URL: {
            SUPPORT: 'https://help.ladipage.vn/',
            DOCUMENT: 'https://docs.ladipage.com',
            NEWS: 'https://www.facebook.com/groups/LadiPageVietnam',
            SHORT_CUT: 'https://builder.ladipage.com',
            FANPAGE_FACEBOOK: 'https://www.facebook.com/groups/LadiPageVietnam',
            AFFILATTE_URL: 'https://ladipage.vn/affiliate',
            POLICY: 'https://ladipage.vn/dieukhoandichvu',
            SECURE: 'https://ladipage.vn/chinhsachbaomat',
            REFERRAL_DETAIL: 'https://ladipage.vn/gioithieu'
        }
    
    }
    const fireBaseConfig = {
        apiKey: "AIzaSyD86O99qJyIz5-3w3Sd7yKMPAkR3RgvMcI",
        authDomain: "ladi-239504.firebaseapp.com",
        databaseURL: "https://ladi-239504.firebaseio.com",
        projectId: "ladi-239504",
        storageBucket: "ladi-239504.appspot.com",
        messagingSenderId: "861065591814",
        appId: "1:861065591814:web:cca80ca3611bf59d"
    }
    
    export default config;
    export {
        authEndpoint
    };
    export {
        fireBaseConfig
    };
