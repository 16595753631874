import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                action: '',
            }
        }
        case types.LIST_STORE: {
            return {
                ...state,
                action: types.LIST_STORE,
                stores: action.status ? action.payload.stores : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
                loading: false
            }
        }
        case types.DELETE_STORES: {
            return {
                ...state,
                action: types.DELETE_STORES,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_STORE: {
            return {
                ...state,
                action: types.DELETE_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_STORE: {
            return {
                ...state,
                action: types.CREATE_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_STORE: {
            return {
                ...state,
                action: types.UPDATE_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case commonTypes.ASYNC_END: {
            return {
                ...state,
                loading: false,
                action: '',
            }
        }
        default:
            return state;
    }
}

