import React, { useEffect, useState } from 'react'
import i18n from '../../../i18n';
import { Link } from 'react-router-dom'

function SidebarMenu({ }) {
    const [menuName, setMenuName] = useState('affiliate');
    const _className = 'ladiui subtitle-2 content-tag-text';
    const paths = window.location.pathname.split('/');
    let currentPath = '';
    if (paths[1]) currentPath += paths[1];
    if (paths[2]) currentPath += '-' + paths[2];

    useEffect(() => {
       setMenuName(currentPath);
    }, [currentPath])

    return (
        <div className="ladiui setting-content-left">

            <div className="ladiui setting-content">
                <Link to={'/affiliate'}><span className={menuName === 'affiliate' ? _className + ' active' : _className}>{i18n.t('BASE.CUSTOMER_LIST')}</span></Link>
            </div>
            <div className="ladiui setting-content">
                <Link to={'/commission'}><span className={menuName === 'commission' ? _className + ' active' : _className}>{i18n.t('BASE.COMMISSION')}</span></Link>
            </div>
            <div className="ladiui setting-content">
                <Link to={'/invoice'}><span className={menuName === 'invoice' ? _className + ' active' : _className}>{i18n.t('BASE.INVOICE')}</span></Link>
            </div>
        </div>
    )
}

export default SidebarMenu