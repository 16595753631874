import React from 'react';
import PropTypes from 'prop-types';
import affilateActions from '../../../redux/futures/affilate/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import i18n from '../../../i18n';
import * as types from '../../../redux/futures/affilate/types';
class PaymentInfo extends React.Component {

    static propTypes = {
        affilateInfo: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            affilateInfo: {
                code: "",
                payment_info: ""
            },
            loading: false,
            classNormal: "ladiui btn btn-primary",
            classLoading: "ladiui loader btn btn-primary",
            classApply: "ladiui btn btn-primary"
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.affilateInfo && this.state.affilateInfo !== nextProps.affilateInfo) {
            this.setState({
                affilateInfo: nextProps.affilateInfo
            });
        }
        if (nextProps.action == types.UPDATE_PAYMENT_INFO) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });

            if (nextProps.status) {

                this.setState({
                    affilateInfo: nextProps.affilateInfo
                });
                const _seft = this;
                window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
                setTimeout(function () {
                    window.LadiUI.closeModal('modal-paymentaffilate');
                }, 1000);

            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }
    }

    submit = () => {
        let errors = [];
        _.map(this.inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toast('danger', '', errorMessage);
            return;
        }

        const { affilateInfo } = this.state;
        if (affilateInfo.payment_info == '') {
            window.LadiUI.toast('danger', '', i18n.t('AFFILATE.ERROR_PAYMENT_INFO'));
            return;
        }

        const jsonData = {
            "payment_info": affilateInfo.payment_info
        }
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
        this.props.update(jsonData);

    }

    onChangeInput = (event) => {

        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeTextArea = (event) => {
        this.setState({
            affilateInfo: {
                ...this.state.affilateInfo,
                [event.target.name]: event.target.value
            }
        });
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss"))
            return;
        const affilateInfo = baseHelper.getCookie(appConst.USER_INFO);
        this.state = {
            affilateInfo: affilateInfo ? affilateInfo : {}
        }
    }

    copyClipboard = (id) => {
        const copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        window.LadiUI.toast('success', '', 'Copied!', 5000, 'bottom-left');
    };

    render() {
        const { affilateInfo } = this.state;
        const title = i18n.t('PROFILE.AFF_PAYMENT_INFO');
        return (
            <div ref={this.ref} id="modal-paymentaffilate" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-paymentaffilate"
                onClick={this.resetForm}>
                <div className='ladiui modal-dialog modal-dialog-centered custom-ldp-width'>
                    <div className="ladiui modal-content" style={{ width: '500px' }}>
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-paymentaffilate"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body">
                            <form>
                                <div className="ladiui-tab-content">
                                    <div className="ladiui form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('PROFILE.AFFILATE_LABEL_URL')}</label>
                                        <div style={{ position: 'relative' }}><input type="text" className="ladiui form-control" id="ladipage-aff-ref-link" name="api-key" disabled readOnly={true} value={'https://ladipage.vn?ref=' + affilateInfo.code} /><div onClick={() => this.copyClipboard('ladipage-aff-ref-link')} className="api-key-action"><i className="ladiui icon icon-ldp-replication"></i></div></div>
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('AFFILATE.PAYMENT_INFO')}</label>
                                        <textarea rows={5} name="payment_info" className="ladiui form-control ladi-payment-info"
                                            value={affilateInfo.payment_info} onChange={this.onChangeTextArea} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="ladiui modal-footer">
                            <button className="ladiui btn btn-secondary" data-dismiss="modal-paymentaffilate">{i18n.t('COMMON.CLOSE')}</button>
                            <button
                                ref={this.ref}
                                className={this.state.classApply}
                                onClick={this.submit}
                                disabled={this.state.loading}>
                                <i className="" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                                {i18n.t('BASE.UPDATE')}
                            </button>
                            {(this.props.loading == types.UPDATE_PAYMENT_INFO) && <Spinner />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (data) => dispatch(affilateActions.updatePaymentInfo(data))
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);