import React from 'react';

import { connect } from 'react-redux';
import affilateActions from '../../redux/futures/affilate/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/affilate/types';

import UserAffInfo from './components/UserAffInfo';
import LoadingTable from '../../components/LoadingTable';
import SidebarMenu from './components/SidebarMenu';
import LadiPagination from '../../components/LadiPagination';

import "react-datepicker/dist/react-datepicker.css";
import i18n from '../../i18n';

class Affilate extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);

        this.state = {
            activePage: 1,
            searchLevel: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL,
            },
            fromDate: firstDay,
            toDate: new Date(),
            code: "",
            emailOrPhone: "",
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            affilateList: [],
            mode: appConst.FORM_MODE.CREATE,
            affilateInfo: false,
            userInfo,
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                code: ""
            }
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
        this.getAffilateInfo();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.MY_AFFILATE_LIST) {
            if (nextProps.status) {
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
                this.getAffilateInfo();
            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
            // if (nextProps.action === types.LIST_ORDER ){
            //     this.state.();
            // }
        }
        if (nextProps.action === types.MY_AFFILATE_INFO) {

            if (nextProps.status) {
                this.setState({
                    affilateInfo: nextProps.affilateInfo
                });
            } else {
                this.setState({
                    affilateInfo: false
                });
            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true) => {
        const { selectedLimit, code, emailOrPhone } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        const levelSelected = this.state.searchLevel.selected;
        let { fromDate, toDate } = this.state;
        fromDate.setHours(0, 0, 0, 0);

        toDate.setHours(23, 59, 59, 999);
        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            search: emailOrPhone
        };

        this.props.list(data);
    }

    getAffilateInfo = () => {
        this.props.getAffilateInfo();
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        console.log(event.target.name)
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.list();
        })
    }
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }


    onSelectedLimit = (limit) => {
        this.setState({ selectedLimit: limit }, () => {
            this.list();
        })
    }

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }


    /****************************************************************/

    openCreateForm = () => {
        this.setState({
            currentAffilate: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentAffilate) => {
        this.setState({
            currentAffilate,
            mode: appConst.FORM_MODE.EDIT
        })
    }
    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
        if (checked) {
            this.selectedOrderIds = _.map(this.props.affilates, store => store.store_id);

        } else {

        }
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length == this.props.affilates.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedOrderIds.length <= 0) {
            window.LadiUI.toast('danger', '', 'Please select at least one record');
            return;
        }

        this.props.deleteOrders(this.selectedOrderIds);
    }

    deleteItem = (orderId) => {
        this.props.deleteOrder(orderId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.list());
    }

    view = (user) => {
        this.setState({
            currentUser: user
        })
    }

    /****************************************************************/


    render() {

        const { totalRecord, totalPage } = this.props;
        const { activePage, selectedLimit, affilateInfo, userInfo, emailOrPhone } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.affilates || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.affilates.length - 1;
        }
        const isLoadingData = this.props.loading && (this.props.waitting == types.MY_AFFILATE_LIST);
        return (
            <div className='page-payment-history'>
                <SidebarMenu />
                <div className='ladiui content-main'>
                    <div className="ladiui content-main-title flex-row">
                        <div className="ladiui main-title">{i18n.t('BASE.CUSTOMER_LIST')}</div>
                    </div>

                    <div className="ladiui-table-actions">
                        <div className="action-item">
                            <div className="ladiui search-group dropdown">
                                <input autoComplete='off' className="ladiui search-field form-control" style={{ minWidth: 270 }} name='emailOrPhone' placeholder={i18n.t("BASE.SEARCH")} value={emailOrPhone} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} />
                                <i onClick={this.list} className="ladiui icon icon-search"></i>
                            </div >
                        </div>
                        {
                            affilateInfo &&
                            <div className="action-item">
                                <label style={{ fontWeight: "bold" }}>{i18n.t('AFFILATE.CUSTOMER_PAID_COUNT') + ': ' +
                                    (affilateInfo.total_purchased ? baseHelper.numberWithCommas(affilateInfo.total_purchased) : 0)
                                }
                                </label>
                            </div>
                        }
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <table className="ladiui table-vertical">
                                <thead>
                                    <tr>

                                        <th className="text-left">Email</th>
                                        <th className="text-left">{i18n.t('AFFILATE.CUSTOMER_NAME')}</th>
                                        <th className="text-left">{i18n.t('AFFILATE.CUSTOMER_PHONE')}</th>
                                        <th name="created_at" onClick={this.sort} className="text-left">{i18n.t('AFFILATE.CUSTOMER_CREATED_DATE')}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(this.props.affilates, affilate => {
                                            affilate.email = affilate.email;
                                            return <tr key={affilate._id}>
                                                <td>{affilate.email}</td>
                                                <td>{affilate.fullname}</td>
                                                <td>{affilate.phone}</td>
                                                <td>{baseHelper.formatStrToDate(affilate.created_at)}</td>
                                                <td>
                                                    <div className="ladiui btn-group">
                                                        <div className="ladiui dropdown">
                                                            <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                                                <i className="ladiui icon icon-ldp-dot"></i>
                                                            </button>
                                                            <ul className="ladiui dropdown-menu custom-dropdown-menu">
                                                                <li>
                                                                    <a className="ladiui dropdown-item btn-open-modal" onClick={() => this.view(affilate)} data-toggle="modal" data-target="modal-useraff-detail">
                                                                        <i className="ladiui icon icon-ldp-decentralize"></i> {i18n.t("BASE.DETAIL")}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        )
                                    }
                                    {
                                        totalRecord <= 0 &&
                                        <tr className="text-center">
                                            <td colSpan="100%">{i18n.t('COMMON.NO_DATA')}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                    }
                    {
                        totalRecord > 0 &&
                        <div className="ladiui search-action">
                            {
                                totalRecord > 0 &&
                                <LadiPagination
                                    conditionsProp={{ limit: selectedLimit, page: this.state.activePage }}
                                    listName={i18n.t("Khách hàng")}
                                    items={this.props.affilates}
                                    total={totalRecord}
                                    limitAction={this.onSelectedLimit}
                                    pageAction={this.onPageChange}
                                />
                            }
                        </div>
                    }
                </div>
                <UserAffInfo
                    currentUser={this.state.currentUser}
                />


            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(affilateActions.myAffilateList(data)),
        getAffilateInfo: () => dispatch(affilateActions.myAffilateInfo())
    }
}

const mapStateToProps = (state) => ({ ...state.affilate });

export default connect(mapStateToProps, mapDispatchToProps)(Affilate);