import React from 'react';
import PropTypes from 'prop-types';

export default class ConfirmModal extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        onCancel: PropTypes.func,
        cancelText: PropTypes.string,
        onOk: PropTypes.func,
        okText: PropTypes.string,
        isLoading: PropTypes.bool,
    }

    static defaultProps = {
        id: "modal-confirm-action",
    }

    onCancel = () => {
        window.LadiUI.closeModal(this.props.id);
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onOk = () => {
        if (this.props.onOk) {
            this.props.onOk();
        }
    }

    render() {
        const { id, title, content, cancelText, okText, isLoading } = this.props;

        return (
            <div id={id} className="ladiui modal fade" tabIndex="-1" data-dismiss={id}>
                <div className='ladiui modal-dialog modal-dialog-centered custom-ldp-width'>
                    <div className="ladiui modal-content confirm">
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target={id}
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body">
                            {content}
                        </div>
                        <div className="ladiui modal-footer">
                            <button className="ladiui btn btn-secondary" onClick={this.onCancel} data-dismiss={id}>{cancelText}</button>
                            <button className={`ladiui btn btn-primary ${isLoading ? 'loader' : ''}`} onClick={this.onOk}>{okText}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}