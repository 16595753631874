import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import { authEndpoint } from '../../config/config';
import * as typesAuth from '../../redux/futures/auth/types';
import * as typesAffilate from '../../redux/futures/affilate/types';
import EditProfile from './components/EditProfile';
import ChangePassword from './components/ChangePassword';
import ChangeAffilateCode from './components/ChangeAffilateCode';
import ChangeFlowAffilateCode from './components/ChangeFlowAffilateCode';
import ConfirmModal from '../../components/ConfirmModal';
import PaymentInfo from '../affilates/components/PaymentInfo';
import SubMenuAccount from '../../components/SubMenuAccount';
import affilateActions from '../../redux/futures/affilate/actions';
import authActions from '../../redux/futures/auth/actions';
import i18n from '../../i18n';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const userInfo = baseHelper.getCookie(appConst.USER_INFO);
    if (userInfo.is_refferral === undefined) {
      userInfo.is_refferral = false;
    }
    const isSentMail = userInfo.is_sent_mail ? new Date(userInfo.is_sent_mail) : false;
    this.state = {
      userInfo: userInfo,
      affilateInfo: null,
      passwordObj: {
        newpass: '',
        confirmpass: ''
      },
      isSentMail
    }
    // this.ref =  React.createRef();
  }

  componentDidMount() {
    window.LadiUI.init();
    this.getAffilateInfo();
  }

  componentWillReceiveProps(nextProps) {

    const affilateInfo = this.state.affilateInfo;
    const userInfo = this.state.userInfo;
    if (nextProps.action == typesAuth.LOGOUT_ALL) {
      if (nextProps.status) {
        this.props.signOut();
      }
    }
    if (nextProps.action == typesAuth.GET_USER_INFO) {
      if (nextProps.status) {
        this.setState({
          userInfo: nextProps.userInfo,
        });
      }
    }
    if (nextProps.action == typesAuth.UPDATE_USER_INFO) {
      if (nextProps.status) {
        this.setState({
          userInfo: nextProps.userInfo,
          affilateInfo
        });
      }
      return;
    }

    if (nextProps.action == typesAuth.VERIFY_EMAIL) {
      if (nextProps.status) {
        userInfo.is_sent_mail = new Date();
        // baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(userInfo),appConst.COOKIE_EXPRIRE);
        window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
        this.setState({
          userInfo,
          affilateInfo
        });
      } else {
        window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
      }
      return;
    }

    if (nextProps.action == typesAuth.GET_REFFERRAL_INFO) {
      if (nextProps.status) {
        const result = _.cloneDeep(nextProps.refferralInfo);
        userInfo.is_refferral = result.is_referral;
        this.setState({
          userInfo,
        });
      } else {
        window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
      }
      return;
    }

    if (nextProps.affilate.action == typesAffilate.MY_AFFILATE_INFO) {

      if (nextProps.affilate.status) {
        this.setState({
          affilateInfo: nextProps.affilate.affilateInfo,
          userInfo
        }, () => {
          this.props.getRefferralInfo();
        });
      } else {
        this.setState({
          affilateInfo: false,
          userInfo
        }, () => {
          this.props.getRefferralInfo();
        });
      }
      return;
    }
  }
  getAffilateInfo = () => {
    this.props.getAffilateInfo();
  }

  resetForm() {
  }
  openCreateForm = () => {
    const userInfo = baseHelper.getCookie(appConst.USER_INFO);
    this.resetForm();

    this.setState({
      userInfo: userInfo,
      passwordObj: {
        newpass: '',
        confirmpass: ''
      }
    })
  }

  openPaymentInfo = () => {
    const affilateInfo = this.state.affilateInfo;
    const userInfo = this.state.userInfo;

    this.setState({
      userInfo: userInfo,
      affilateInfo: affilateInfo
    })
  }

  onError = () => {

    this.setState({
      userInfo: {
        ...this.state.userInfo,
        avatar: appConst.DEFAULT_AVATAR
      }
    });
  }

  logoutAll = () => {

    this.props.logoutAll();

  }

  sendVerifyEmail = () => {
    const { isSentMail } = this.state;
    if (isSentMail) {
      const diff = baseHelper.diffDateMinute(new Date(), isSentMail);
      if (diff > 5) {
        this.props.sendVerifyEmail({});
      }
    } else {
      this.props.sendVerifyEmail({});
    }

  }

  getRefferralInfo = () => {
    this.props.getRefferralInfo();
  }

  getRefAffilate = () => {

    this.props.getRefAffilate();
  }

  copyToClipboardRef = (e) => {
    this.textAreaRef.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    window.LadiUI.toast('success', '', i18n.t('PROFILE.COPIED'));
  };

  copyToClipboardAff = (e) => {
    this.textAreaAff.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    window.LadiUI.toast('success', '', i18n.t('PROFILE.COPIED'));
  };

  onclickSelectedRef = (e) => {
    this.textAreaRef.select();
    e.target.focus();
  };

  onclickSelectedAff = (e) => {
    this.textAreaAff.select();
    e.target.focus();
  };
  render() {
    const { userInfo, affilateInfo } = this.state;
    const name = (userInfo && userInfo.fullname) ? userInfo.fullname : '';
    const email = (userInfo && userInfo.email) ? userInfo.email : '';
    const packages = (userInfo && userInfo.packages) ? userInfo.packages : {};

    const timezone = (userInfo && userInfo.timezone) ? userInfo.timezone : '7';

    var date = "September 21, 2011 00:00:00";
    var targetTime = new Date(date);
    var timeZoneFromDB = -7.00; //time zone value from database
    //get the timezone offset from local time in minutes
    var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
    //convert the offset to milliseconds, add to targetTime, and make a new Date
    var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);

    const referralUrl = authEndpoint.REGISTER_LP_URL + "gt=" +
      userInfo.ladi_uid;
    let affilateUrl = '';
    if (affilateInfo) {
      affilateUrl = authEndpoint.REGISTER_LP_URL + "ref=" +
        affilateInfo.code
    }
    const isLoadingLogout = this.props.loading && (this.props.waitting == typesAuth.LOGOUT_ALL);
    return (
      <>
        <div className="page-ladiuid-account pd24">
          <div className="welcome text-center">
            <div className={!userInfo.avatar ? 'profile-avatar no-avatar' : 'profile-avatar'} >
              {
                !userInfo.avatar
                  ?
                  baseHelper.getFirstLetter(userInfo.fullname)
                  :
                  <img src={userInfo.avatar ? userInfo.avatar : appConst.DEFAULT_AVATAR} onError={this.onError} className="ladiui-profile-avatar" />
              }

            </div>
            <h3>{name == '' ? email : name}</h3>
            <p className='text-center'>{email} | {(userInfo.phone) ? userInfo.phone : 'No Phone'}</p>
            <div className='credit-info'>
              <ul>
                {userInfo.money && userInfo.money > 0 ?
                  <li>
                    <NavLink to="/credit">
                      <span className="points">Tiền còn lại : {
                        userInfo.money ? baseHelper.numberWithCommas(userInfo.money.toFixed(2)) : null
                      }</span>
                    </NavLink>
                  </li> : <></>
                }
                {userInfo.credit && userInfo.credit > 0 ?
                  <li>
                    <NavLink to="/credit">
                      <span className="points">Partner Credit : {
                        userInfo.credit ? baseHelper.numberWithCommas(userInfo.credit.toFixed(2)) : null
                      }</span>
                    </NavLink>
                  </li> : <></>
                }
                {userInfo.credit_app && userInfo.credit_app > 0 &&
                  <li>
                    <NavLink to="/credit" className={'ladiui link'}>
                      <span className="points">API Credit : {
                        userInfo.credit_app ? baseHelper.numberWithCommas(userInfo.credit_app.toFixed(2)) : 0
                      }</span>
                    </NavLink>
                  </li>
                }
                {
                  !userInfo.is_verify_email &&
                  <li>
                    <a className="ladiui button sm outline-primary" onClick={this.sendVerifyEmail} >{i18n.t('PROFILE.VERIFY_EMAIL')}</a>
                  </li>
                }
                {!userInfo.credit &&
                  <li className="points"><span className='ladiui link'>{i18n.t('PROFILE.POINT')} : {
                    userInfo.point ? baseHelper.numberWithCommas(userInfo.point) : 0
                  }</span></li>
                }
              </ul>
              <ul>
                <li><a data-toggle="modal" data-target="modal-profile" onClick={this.openCreateForm}>{i18n.t('PROFILE.UPDATE_PROFILE')}</a></li>
                <li>
                  <a data-toggle="modal" data-target="modal-password" className="ladiui-link ladiui">{i18n.t('PROFILE.CHANGE_PASSWORD')}</a>
                </li>
                <li>
                  <a data-toggle="modal" data-target="confirm-logout-all" className="ladiui-link ladiui">{i18n.t('PROFILE.LOG_OUT_ALL')}</a>
                </li>
                <li>
                  <a data-toggle="modal" data-target="modal-change-affilatecode" className="ladiui-link ladiui" onClick={this.getRefAffilate}>{i18n.t('PROFILE.CHANGE_AFFILATE_CODE')}</a>
                </li>
                <li>
                  <a data-toggle="modal" data-target="modal-change-flow-affilatecode" className="ladiui-link ladiui" >{i18n.t('PROFILE.CHANGE_FLOW_AFFILATE_CODE')}</a>
                </li>
                {
                  affilateInfo &&
                  <li><a data-toggle="modal" data-target="modal-paymentaffilate" className="ladiui" onClick={this.openCreateForm}>{i18n.t('PROFILE.AFF_PAYMENT_INFO')}</a></li>
                }
              </ul>
            </div>
          </div>
          <div className="ladiui-row flex" style={{ display: "none" }}>
            <div className="ladiui card col">
              <div className="card-header">
                <h3>{i18n.t('PROFILE.INVITE_FRIEND')}
                  <i className="ladi-icon icon-c-question" data-tooltip={i18n.t('PROFILE.INVITE_FRIEND_DESC')} data-tooltip-position="top">
                  </i>
                </h3>
              </div>
              <div className="card-body">
                {userInfo.is_refferral &&
                  <div>
                    <div className="referral-title">{i18n.t('PROFILE.REFERRAL_LABEL_URL')}</div>
                    <div className="ladiui-input-group referral-link">
                      <input
                        readOnly
                        type="text"
                        className="ladiui input"
                        value={referralUrl}
                        ref={(textarea) => this.textAreaRef = textarea}
                        onClick={this.onclickSelectedRef}
                      />
                      {
                        document.queryCommandSupported('copy') &&
                        <div className="ladiui-input-group-prepend">
                          <button className="ladiui-input-group-text" onClick={this.copyToClipboardRef}>Copy</button>
                        </div>
                      }
                    </div>
                    <div className="referral-title">
                      <a href="https://ladipage.vn/gioithieu" target="_blank" style={{ fontStyle: "italic" }}>*Chi tiết chương trình vui lòng xem tại đây</a>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="box-lastlogin ladiui card col" style={{ display: "none" }}>
              <div className="card-header">
                <h3>{i18n.t('PROFILE.AFFILATE_LABEL')} {
                  affilateInfo &&
                  <i modal="modal-paymentaffilate" className="ladiui ladi-icon icon-edit open-modal ladi-edit-icon" onClick={this.openCreateForm} />
                }
                </h3>
              </div>
              {
                affilateInfo
                  ?
                  <div className="card-body">
                    <div className="referral-title">{i18n.t('PROFILE.AFFILATE_LABEL_URL')}</div>
                    <div className="ladiui-input-group referral-link">
                      <input
                        readOnly
                        type="text"
                        className="ladiui input"
                        value={affilateUrl}
                        ref={(textarea) => this.textAreaAff = textarea}
                        onClick={this.onclickSelectedAff}
                      />


                      {
                        document.queryCommandSupported('copy') &&
                        <div className="ladiui-input-group-prepend" onClick={this.copyToClipboardAff}>
                          <span className="ladiui-input-group-text">Copy</span>
                        </div>
                      }

                    </div>
                  </div>
                  :
                  <div className="profile-info" style={{ padding: '15px' }}>
                    <h3>
                      {i18n.t('PROFILE.AFFILATE_SUB_TITLE_1')}<br /><br />
                    </h3><br />
                    <a target='_blank' href={authEndpoint.SUPPORT_URL.AFFILATTE_URL}><span className="ladiui button primary icon">{i18n.t('PROFILE.AFFILATE_JOIN_BUTTON')}</span></a>
                  </div>
              }


              {/* {console.log(affilateInfo)} */}
            </div>
          </div>
        </div>
        <EditProfile
          userInfo={userInfo}
        />
        <ChangePassword
          userInfo={userInfo}
        />
        <ChangeAffilateCode
          userInfo={userInfo}
        />
        <ChangeFlowAffilateCode
          userInfo={userInfo}
        />

        <ConfirmModal
          id="confirm-logout-all"
          title={i18n.t('PROFILE.LOGOUT_ALL_CONFIRM_TITLE')}
          content={i18n.t('PROFILE.LOGOUT_ALL_CONFIRM_CONTENT')}
          cancelText={i18n.t('COMMON.CANCEL')}
          okText={i18n.t('COMMON.AGREE')}
          onOk={() => this.logoutAll()}
          isLoading={isLoadingLogout}
        />
        <PaymentInfo affilateInfo={affilateInfo} />
      </>
    );

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAffilateInfo: () => dispatch(affilateActions.myAffilateInfo()),
    getRefAffilate: () => dispatch(authActions.getRefAffilate()),
    getRefferralInfo: () => dispatch(authActions.getRefferralInfo()),
    logoutAll: () => dispatch(authActions.logoutAll()),
    signOut: () => dispatch(authActions.signOut()),
    sendVerifyEmail: () => dispatch(authActions.sendVerifyEmail()),
  }
}

const mapStateToProps = (state) => ({ ...state.auth, affilate: { ...state.affilate } });

export default connect(mapStateToProps, mapDispatchToProps)(Profile);