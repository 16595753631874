import React from 'react';

import userActions from '../redux/futures/user/actions';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Checkout from '../pages/orders/Checkout';
import { Switch, Route } from 'react-router-dom';
class CheckoutLayout extends React.Component {

    render() {
 
        return (
            <Switch>
                <Route path="/checkout" exact={true} component={Checkout} />
            </Switch>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const mapStateToProps = (state) => ({ });


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutLayout));