import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const cancel = (data) => ({
    type: types.CANCEL_ORDER,
    meta: {
        endpoint: endpoint.CANCEL_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 

const create = (order) => ({
    type: types.CREATE_ORDER,
    meta: {
        endpoint: endpoint.ORDER_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            order: order
        },
        hasAuth: true,
    }
});
 

const getDetail = (data) => ({
    type: types.DETAIL_ORDER,
    meta: {
        endpoint: endpoint.ORDER_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const myOrders = (data) => ({
    type: types.MY_ORDER,
    meta: {
        endpoint: endpoint.MY_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const getOrderByTransaction = (data) => ({
    type: types.GET_TRANSACTION_DETAIL,
    meta: {
        endpoint: endpoint.GET_TRANSACTION_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const doCheckout = (data) => ({
    type: types.CHECKOUT_ORDER,
    meta: {
        endpoint: endpoint.CHECKOUT_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
export default {
    cancel,
    getDetail,
    myOrders,
    getOrderByTransaction,
    doCheckout
}