import React from 'react';
import appConst from '../config/const';
import baseHelper from '../helpers/BaseHelper';
import i18n from '../i18n'

export default class LadiLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi',
        };
    }

    onChangeLanguage = (item) => {
        baseHelper.setCookie(appConst.COOKIE.LANGUAGE, item.code);
        i18n.changeLanguage(item.code);
        window.location.reload();
    }

    getSelectedLanguage = () => {
        for (let i = 0; i < appConst.LANGUAGES.length; i++) {
            if (this.state.lang === appConst.LANGUAGES[i].code)
                return appConst.LANGUAGES[i].icon;
        }
    }

    render() {
        let currLang = appConst.LANGUAGES[0];
        appConst.LANGUAGES.map((item, idx) => {
            if (item.code == this.state.lang)
                currLang = item;
        })
        // console.log(' curr ', currLang);
        return (<div className="ladiui dropdown">
            <div className="dropdown-toggle select-dropdown" data-toggle="dropdown">
                {/* <div className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown"> */}

                <i></i>
                <div className="ladiui language-img">
                    <img src={this.getSelectedLanguage()} alt="" /><span>{currLang.name}</span>
                </div>
            </div>
            <ul className="ladiui dropdown-menu show-down">
                {
                    appConst.LANGUAGES.map((item, idx) => {
                        return <li key={item.code} onClick={() => this.onChangeLanguage(item)}>
                            <span className="ladiui dropdown-item">
                                {
                                    item.icon ? <img src={item.icon} alt="" /> : null
                                }  {' ' + item.name} </span>
                        </li>
                    })
                }
            </ul>
        </div>
        )
    }
}