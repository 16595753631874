import React from 'react';
import PropTypes from 'prop-types';
import authActions from '../../../redux/futures/auth/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import i18n from '../../../i18n';
import * as types from '../../../redux/futures/auth/types';

class ChangePassword extends React.Component {

    static propTypes = {
        passwordObj: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            passwordObj: {
                oldpass: '',
                newpass: '',
                confirmpass: ''
            },
            showOldPass: false,
            showNewPass: false,
            showConfirmPass: false,
            iconClass: 'icon-preview',
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.passwordObj && this.state.passwordObj !== nextProps.passwordObj) {
            this.setState({
                passwordObj: nextProps.passwordObj
            });
        }
        if (nextProps.action == types.CHANGE_PASSWORD) {

            if (nextProps.status) {
                window.LadiUI.closeModal('modal-password');
                window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), errorMessage, 'OK');
            return;
        }

        const { passwordObj } = this.state;
        if (passwordObj.newpass != passwordObj.confirmpass) {
            window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), i18n.t('RESET_PASSWORD.ERROR_PASSWORD_MISSMATCH'), 'OK');
            return;
        }
        const jsonData = {
            "old_password": passwordObj.oldpass,
            "new_password": passwordObj.newpass,
            "store_id": "",
            "app_code": "luid"
        }
        this.props.update(jsonData);

    }

    onChangeInput = (event) => {

        this.setState({
            passwordObj: {
                ...this.state.passwordObj,
                [event.target.name]: event.target.value
            }
        });
    }

    resetForm = (event) => {
        if (!event.target.hasAttribute("data-dismiss")) {
            return;
        }

        this.state = {
            passwordObj: {
                newpass: '',
                confirmpass: ''
            }
        }
    }

    showPassword = (type) => {
        const { showOldPass, showNewPass, showConfirmPass } = this.state;
        if (type == 'oldpass') {
            this.setState({ showOldPass: !showOldPass });
        }
        if (type == 'newpass') {
            this.setState({ showNewPass: !showNewPass });
        }
        if (type == 'confirmpass') {
            this.setState({ showConfirmPass: !showConfirmPass });
        }

        // const { typeInput } = this.state;
    }

    render() {
        const { passwordObj, typeInput, iconClass, showConfirmPass, showNewPass, showOldPass } = this.state;

        // if (typeInput == 'password') {
        //     this.setState({
        //         typeInput: 'text',
        //         iconClass: 'icon-b-preview'
        //     });
        // }
        // if (typeInput == 'text') {
        //     this.setState({
        //         typeInput: 'password',
        //         iconClass: 'icon-preview'
        //     });

        // }
        const title = i18n.t('RESET_PASSWORD.RESET_TITLE');

        return (
            <div ref={this.ref} id="modal-password" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-password"
                onClick={this.resetForm}>
                <div className='ladiui modal-dialog modal-dialog-centered custom-ldp-width'>
                    <div className="ladiui modal-content">
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-password"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>

                        <div className="ladiui modal-body">
                            <form>

                                <div className="ladiui-tab-content">
                                    <div className="ladiui-row">
                                        <div>
                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('RESET_PASSWORD.OLD_PASSWORD')}</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="oldpass"
                                                    validationName="NewPassword"
                                                    placeholder={i18n.t('RESET_PASSWORD.OLD_PASSWORD')}
                                                    value={passwordObj.oldpass}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true, minLength: 6, maxLength: 45 }}
                                                    type={!showOldPass ? 'password' : 'text'}
                                                />
                                                <i className={`ladi-icon ` + (!showOldPass ? 'icon-preview' : 'icon-b-preview') + ` ladi-date-picker-icon`} onClick={() => this.showPassword('oldpass')}></i>
                                            </div>
                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('RESET_PASSWORD.NEW_PASSWORD')}</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="newpass"
                                                    validationName="NewPassword"
                                                    placeholder={i18n.t('RESET_PASSWORD.NEW_PASSWORD')}
                                                    value={passwordObj.newpass}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true, minLength: 6, maxLength: 45 }}
                                                    type={!showNewPass ? 'password' : 'text'}
                                                />
                                                <i className={`ladi-icon ` + (!showNewPass ? 'icon-preview' : 'icon-b-preview') + ` ladi-date-picker-icon`} onClick={() => this.showPassword('newpass')}></i>
                                            </div>

                                            <div className="ladiui form-group">
                                                <label className="ladiui title-text-form-lever2">{i18n.t('RESET_PASSWORD.CONFIRM_PASSWORD')}</label>
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="confirmpass"
                                                    validationName="ConfirmPassword"
                                                    placeholder={i18n.t('RESET_PASSWORD.CONFIRM_PASSWORD')}
                                                    value={passwordObj.confirmpass}
                                                    onChange={this.onChangeInput}
                                                    validations={{ isRequired: true, minLength: 6, maxLength: 45 }}
                                                    type={!showConfirmPass ? 'password' : 'text'}
                                                />
                                                <i className={`ladi-icon ` + (!showConfirmPass ? 'icon-preview' : 'icon-b-preview') + ` ladi-date-picker-icon`} onClick={() => this.showPassword('confirmpass')}></i>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button data-dismiss="modal" data-target="modal-profile" className="ladiui btn small txt body3 btn-secondary">
                                    {i18n.t("BASE.CANCEL")}
                                </button>
                                <div>
                                    <button className="ladiui btn small txt body3 btn-primary" onClick={() => this.submit()}>
                                        {i18n.t("BASE.UPDATE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (passwordObj) => dispatch(authActions.changePassword(passwordObj))
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);