import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authActions from '../../redux/futures/auth/actions';
import * as types from '../../redux/futures/auth/types';
import _ from 'lodash';
import Input from '../../components/Input';
import i18n from '../../i18n';
import queryString from 'query-string';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
import LadiIframe from './LadiIframe';
import PoweredBy from '../../components/PoweredBy';
import LadiAppLogo from '../../components/LadiAppLogo';
import LadiLanguage from '../../components/LadiLanguage';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

class CompleteRegisterV3 extends React.Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            email: '',
            password: '',
            app_code: '',
            phone: '',
            agree_policy: false,
            errorPassword: "",
            errorObject: {
                policy: false,
                email: false,
                password: false,
                name: false,
                phone: false,
            },
            is_phone_valid: true,
            fullname: '',
            refObject: parsed,
            loading: false,
            typeInput: 'password',
            iconClass: 'icon-password-hide',
            classNormal: "ladiui btn btn-primary full-width",
            classLoading: "ladiui loader btn btn-primary full-width",
            classApply: "ladiui btn btn-primary full-width",
            search: this.props.location.search
        }
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.COMPLETE_REGISTER) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                localStorage.setItem('email', this.state.email);
                let parsed = {};
                if (this.state.search) {
                    parsed = queryString.parse(this.props.location.search);
                }
                const user = nextProps.user;
                // console.log(' nextProps.data = ', nextProps.user)

                // if (!parsed.callback_url) {
                this.props.history.push(`/verify-account?verify_type=${user.verify_type}&email=${user.email}&phone=${user.phone}&exp_time=${user.exp_time}&token=${user.token}`);
                // }
                // if (!parsed.callback_url) {
                // this.props.history.push('/account/profile');
                // console.log(' pussh :', `/verify-account?verify_type=${user.verify_type}&email=${user.email}&phone=${user.phone}&exp_time=${user.exp_time}&token=${user.token}`);
                // }
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }

    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    signOut = () => {
        this.props.signOut();
    }
    completeReg = (event) => {
        event.preventDefault();
        const { errorObject, is_phone_valid } = this.state;
        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {

            if (ref.validate) {
                errors = errors.concat(ref.validate());
                if (ref.validate().length > 0) {
                    errorObject[ref.props.name] = ref.validate()[0];
                } else {
                    errorObject[ref.props.name] = false;
                }
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            // const errorMessage = errors.join("<br/>");
            // window.LadiUI.toast('danger', '', errorMessage);
            errorObject.policy = false;
            this.setState({ errorObject, errorPassword: "" })
            return;
        }


        const { password, fullname, phone, agree_policy } = this.state;
        if (!baseHelper.passwordValidate(password.trim())) {
            errorObject.password = i18n.t('AUTH.PASSWORD_INVALID');
            this.setState({ errorObject, errorPassword: "" });
            return;
        }
        if (!agree_policy) {
            errorObject.policy = i18n.t('AUTH.ACCEPT_POLICY_ERROR');
            this.setState({ errorObject, errorPassword: "" })
            return;
        }
        if (!is_phone_valid) {
            errorObject.phone = i18n.t('VALIDATION.INVALID_PHONE');
            this.setState({ errorObject, errorPassword: "" })
            return;
        }
        const parsed = queryString.parse(this.props.location.search);

        const data = {
            "password": password,
            "phone": phone,
            fullname
        }
        if (parsed.app_code) {
            data.app_code = parsed.app_code;
        }
        data.search = this.state.search;
        this.props.complete(data);
    }


    checkAllItem = (event) => {
        const { checked } = event.target;
        this.setState({ agree_policy: checked });

    }
    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput == 'password') {
            this.setState({
                typeInput: 'text',
                iconClass: 'icon-password-view'
            });
        }
        if (typeInput == 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-password-hide'
            });

        }
    }
    handlePhoneChange = (isValid, value, countryData) => {
        // setPhone(value);
        // setValid(isValid);
        console.log("Selected Country Data:", isValid, value, countryData);
        this.setState({
            phone: value,
            is_phone_valid: isValid
        });

    }

    render() {
        const { email, password, fullname, phone, typeInput, iconClass, errorObject, errorPassword, is_phone_valid } = this.state;
        let userinfo = baseHelper.getCookie(appConst.USER_INFO);
        const search = this.props.location.search;
        return (
            appConst.ALLOW_RESISTER &&
            <div className="ladiui auth">

                <div className="ladiui-login-wrap no-frame" >
                    <LadiAppLogo />
                    <h1 className="form-title" style={{ textAlign: "center" }}>{i18n.t('AUTH._COMPLETE_REGISTRATION')}</h1>
                    <div className="ladiui-login">

                        {/* <div className="language">
                        <LadiLanguage />
                    </div> */}
                        <div className="ladiui-login-form">
                            <div className="ladiui-form create-user-form">
                                <div className={`error-password ${errorPassword != '' ? 'is_error' : ''}`}>{errorPassword}</div>
                                <form onSubmit={this.signUp}>
                                    <div className="ladiui-form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.FULLNAME')}</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="fullname"
                                            validationName={i18n.t('AUTH.FULLNAME')}
                                            placeholder={i18n.t('AUTH.FILL_YOUR_FULLNAME')}
                                            value={fullname}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true }}

                                        />
                                        <div className={`error-input ${errorObject.fullname ? 'is_error' : ''}`}>{errorObject.fullname}</div>
                                    </div>
                                    <div className="ladiui-form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                                        <input name="totalPrice" className="ladiui form-control" disabled="disable" value={userinfo.email} />
                                    </div>
                                    <div className="ladiui-form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PASSWORD')}</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="password"
                                            validationName={i18n.t('AUTH.PASSWORD')}
                                            placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                                            value={password}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true, minLength: 8, maxLength: 20 }}
                                            type={typeInput}
                                        />
                                        <i className={`ladiui icon ` + iconClass} onClick={this.showPassword}></i>
                                        <div className={`error-input ${errorObject.password ? 'is_error' : ''}`}>{errorObject.password}</div>
                                    </div>

                                    <div className="ladiui-form-group">
                                        <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PHONE')}</label>
                                        {/* <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="phone"
                                            validationName={i18n.t('AUTH.PHONE')}
                                            placeholder={i18n.t('AUTH.FILL_YOUR_PHONE')}
                                            value={phone}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true, phone: true }}

                                        /> */}
                                        <IntlTelInput
                                            containerClassName="intl-tel-input"
                                            inputClassName={`ladiui form-control` + (is_phone_valid ? '' : ' error')}
                                            onPhoneNumberChange={(isValid, value, countryData) =>
                                                this.handlePhoneChange(isValid, value, countryData)
                                            }
                                            defaultCountry="vn"
                                        />
                                        <div className={`error-input ${errorObject.phone ? 'is_error' : ''}`}>{errorObject.phone}</div>
                                    </div>
                                    <div className="ladiui-form-group allow-policy">
                                        <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                        <div id="policy-text" onClick={this.checkAllItem} style={{ textAlign: "left", fontSize: "12px" }}>{i18n.t('AUTH.SIGNUP_CONFIRM_RULE')}<a href="https://ladipage.vn/dieukhoandichvu" target="_blank">{i18n.t('AUTH.TERM_OF_USE')}</a> & <a href="https://ladipage.vn/chinhsachbaomat" target="_blank"> {i18n.t('AUTH.SERCURE_POLICY')}</a> {i18n.t('AUTH.POLICY_FROM')}.</div>

                                    </div>
                                    <div className={`error-input ${errorObject.policy ? 'is_error' : ''}`}>{errorObject.policy}</div>
                                    <div className="ladiui-form-group">
                                        <button
                                            ref={this.ref}
                                            className={this.state.classApply}
                                            onClick={this.completeReg}
                                            disabled={this.state.loading}
                                        >
                                            {i18n.t('AUTH._COMPLETE_REGISTRATION')}</button>

                                    </div>
                                    <div className="social-login" style={{ marginTop: '30px' }}>
                                        Hoặc <a href={`/signup${search}`} style={{ fontWeight: '500' }} className="ladiui link" onClick={this.signOut}> {i18n.t('AUTH.SIGN_UP_OTHER_ACC')}</a>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <PoweredBy />
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        complete: (data) => dispatch(authActions.completeRegister(data)),
        signOut: () => dispatch(authActions.signOut())

    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompleteRegisterV3));