import React from 'react';
import { connect } from 'react-redux';
import orderActions from '../../redux/futures/order/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
import Detail from './components/Detail';
import Print from './components/Print';
import Cancel from './components/Cancel';
import * as types from '../../redux/futures/order/types';

import LoadingTable from '../../components/LoadingTable';
import LadiPagination from '../../components/LadiPagination';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

import i18n from '../../i18n';
const moment = baseHelper.getMoment();
class Order extends React.Component {

    constructor(props) {
        super(props);
        const firstDayOfMonth = moment().startOf('month');
        const lastDayOfMonth = moment();

        this.state = {
            activePage: 1,
            page: 1,
            limit: 20,
            focusedInput: null,
            fromDate: firstDayOfMonth,
            toDate: lastDayOfMonth,
            searchStatus: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
            },
            searchOrderNo: '',
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            currentOrder: {},
            mode: appConst.FORM_MODE.CREATE,
            orderList: []
        }

        this.sortBy = {
            'store_id': 'ASC',
            'name': 'ASC',
            'created_at': 'ASC',
            'status': 'ASC',
        }
        this.selectedOrderIds = [];
        this.muteActions = [];
    }

    setFocusedInput() {

    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action)
            && nextProps.action == types.MY_ORDER) {
            if (nextProps.status) {
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }

        }
        if (nextProps.action == types.CANCEL_ORDER) {
            if (nextProps.status) {
                setTimeout(() => {
                    this.getList();
                }, 600);
            }

        }

    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }

    /**
     * list store
     */
    getList = (resetActivePageFlag = true, startDate, endDate) => {
        const { searchOrderNo, selectedLimit } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        // Reset selectedOrderIds
        this.selectedOrderIds = [];

        const statusSelected = this.state.searchStatus.selected;
        let { fromDate, toDate } = this.state;

        if (startDate)
            fromDate = startDate;

        if (endDate)
            toDate = endDate;

        if (fromDate)
            fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        if (toDate)
            toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "status": statusSelected,
            "reference_no": searchOrderNo,
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.myOrders(data);
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    onSelectedLimit = (limit) => {
        this.setState({ selectedLimit: limit }, () => {
            this.list();
        })
    }

    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.getList(false);
        });
    }

    checkAllItem = (event) => {
        const { checked } = event.target;

        this.selectedOrderIds = [];
    }

    checkItem = (event, orderId) => {
        const { checked } = event.target;
        const { selectedOrderIds } = this;

        if (checked) {
            if (!_.includes(selectedOrderIds, orderId)) {
                selectedOrderIds.push(orderId);
            }

            if (selectedOrderIds.length == this.props.orders.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedOrderIds, selectedOrderId => selectedOrderId == orderId);
        }


    }

    deleteAllItems = () => {
        if (this.selectedOrderIds.length <= 0) {
            window.LadiUI.toast('danger', '', i18n.t('COMMON.ERROR_SELECT_NONE'));
            return;
        }

        this.props.deleteOrders(this.selectedOrderIds);
    }

    deleteItem = (orderId) => {
        this.props.deleteOrder(orderId);
    }

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');
        // Change arrow up and down
        if (_.includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({
        }, () => this.getList());
    }

    print = (currentOrder) => {
        const data = {
            "id": currentOrder._id
        };
        this.setState({
            currentOrder
        });
        this.props.getDetail(data);
    }

    view = (currentOrder) => {
        const data = {
            "id": currentOrder._id
        };
        this.setState({
            currentOrder
        });
        this.props.getDetail(data);
    }

    cancel = (currentOrder) => {
        const data = {
            "id": currentOrder._id
        };
        this.setState({
            currentOrder
        });
        this.props.getDetail(data);
    }

    rePayment = (order) => {
        this.props.history.push('/checkout?app_code=' + order.appCode + '&transaction_id=' + order.paymentID);
    }
    /****************************************************************/

    setFromDate = (date) => {
        this.setState({
            fromDate: date
        })
    }

    setToDate = (date) => {
        this.setState({
            toDate: date
        })
    }

    render() {
        const { totalRecord } = this.props;
        const { activePage, selectedLimit, searchStatus, searchOrderNo } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.orders || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.orders.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.MY_ORDER);
        return (
            <div>
                <div className='page-order pd24'>
                    <div className="ladiui main-title">{i18n.t('ORDER.TITLE')}</div>
                    <div className="ladiui content-main-tool flex-row">
                        <div className="action-item">
                            <div className="ladiui search-group dropdown">
                                <input autoComplete='off' className="ladiui search-field form-control" style={{ minWidth: 270 }} name='keyword' placeholder={i18n.t("BASE.SEARCH")} value={searchOrderNo} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} />
                                <i onClick={this.getList} className="ladiui icon icon-search"></i>
                            </div >
                        </div>
                        <div className="action-item">
                            <div className="ladiui dropdown">
                                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                    <span className="ladiui dropdown-text">{i18n.t(searchStatus.name)}</span>
                                </button >
                                <ul className="ladiui dropdown-menu">
                                    {
                                        _.map(appConst.PAYMENT_STATUS_OPTIONS, option =>
                                            <li key={option.value} onClick={() => this.onChangeSearchStatus(option)}><a className="ladiui dropdown-item">{i18n.t(option.name)}</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="action-item">
                            <LadiDateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({ fromDate: startDate, toDate: endDate });
                                    this.getList(1, startDate, endDate);
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladiui icon icon-date-time-picker" />
                                }
                                startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => moment().isSame(date, 'day')}
                                minimumNights={0}
                                showClearDates={true}
                            />
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <table className="ladiui table-vertical header">
                                <thead>
                                    <tr>
                                        <th className="ladiui checkall text-left">
                                            <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                        </th>
                                        <th name="name" onClick={this.sort} className="text-left">{i18n.t('ORDER.REFERENCE_NO')}</th>
                                        <th className="text-left">{i18n.t('ORDER.AMOUNT')}</th>
                                        <th name="created_at" onClick={this.sort} className="text-left">{i18n.t('ORDER.CREATED_AT')}</th>
                                        <th name="status" onClick={this.sort} className="text-left">{i18n.t('COMMON.STATUS')}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(this.props.orders, order => {

                                            return <tr key={order._id}>
                                                <td className="text-center">
                                                    <input type="checkbox" onClick={(event) => this.checkItem(event, order._id)} className="ladiui checkbox" />
                                                </td>
                                                <td>{order.reference_no}</td>
                                                <td>{baseHelper.formatNumber(order.total)} VND</td>
                                                <td>{baseHelper.formatStrToDate(order.created_at)}</td>
                                                <td>
                                                    {i18n.t(appConst.PAYMENT_STATUS_OPTIONS[order.status].name)}
                                                </td>
                                                <td>

                                                    <ul className="ladiui-navbar -horizontal row-actions">
                                                        <li className="ladiui-dropdown">
                                                            <a><i className="ladi-icon icon-dots"></i></a>
                                                            <ul>
                                                                {/* <li><a onClick={() => this.print(order)} className="ladiui open-modal" modal="modal-print" >In</a></li> */}
                                                                <li><a onClick={() => this.view(order)} className="ladiui open-modal" modal="modal-detail" >{i18n.t('COMMON.DETAIL')}</a></li>
                                                                {
                                                                    (order.status === appConst.PAYMENT_STATUS_OPTIONS.PENDING.value) &&
                                                                    <li><a onClick={() => this.cancel(order)}
                                                                        className="ladiui open-modal" modal="modal-cancel">{i18n.t('COMMON.CANCEL')}</a></li>
                                                                }
                                                                {
                                                                    (order.status === appConst.PAYMENT_STATUS_OPTIONS.PENDING.value) &&
                                                                    <li><a onClick={() => this.rePayment(order)}>{i18n.t('ORDER.REPAYMENT')}</a></li>
                                                                }
                                                            </ul>
                                                        </li>
                                                    </ul>

                                                </td>
                                            </tr>
                                        }
                                        )
                                    }
                                    {
                                        totalRecord <= 0 &&
                                        <tr className="text-center">
                                            <td colSpan="100%">{i18n.t('COMMON.NO_DATA')}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                    }
                    {
                        totalRecord > 0 &&
                        <LadiPagination
                            conditionsProp={this.state}
                            listName={'Đơn hàng'}
                            items={this.props.orders}
                            total={totalRecord}
                            limitAction={this.onSelectedLimit}
                            pageAction={this.onPageChange}
                        />

                    }
                </div>
                <Detail
                    currentPayment={this.state.currentOrder}
                    mode={this.state.mode}
                />
                <Print
                    currentPayment={this.state.currentOrder}
                    mode={this.state.mode}
                />
                <Cancel
                    currentPayment={this.state.currentOrder}
                    mode={this.state.mode}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cancelOrder: (data) => dispatch(orderActions.cancelOrder(data)),
        myOrders: (data) => dispatch(orderActions.myOrders(data)),
        getDetail: (data) => dispatch(orderActions.getDetail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.order });

export default connect(mapStateToProps, mapDispatchToProps)(Order);