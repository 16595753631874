import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';
 
const myPayments = (data) => ({
    type: types.MY_PAYMENT,
    meta: {
        endpoint: endpoint.MY_PAYMENT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const getDetail = (data) => ({
    type: types.DETAIL_PAYMENT,
    meta: {
        endpoint: endpoint.ORDER_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    getDetail,
    myPayments
}