import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authActions from '../../redux/futures/auth/actions';
import * as types from '../../redux/futures/auth/types';
import _ from 'lodash';
import Input from '../../components/Input';
import i18n from '../../i18n';
import queryString from 'query-string';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
import LadiIframe from './LadiIframe';
import PoweredBy from '../../components/PoweredBy';
import LadiAppLogo from '../../components/LadiAppLogo';
import LadiLanguage from '../../components/LadiLanguage';
class Iframe extends React.Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            search: this.props.location.search
        }
        if (parsed.token) {
            baseHelper.setCookie(appConst.LADI_UID_TOKEN, parsed.token, appConst.COOKIE_EXPRIRE);
            // let token = parsed.token;
        }
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.LadiUI.init();
        let token = false;
        const parsed = queryString.parse(this.state.search);
        if (parsed.token) {
            baseHelper.setCookie(appConst.LADI_UID_TOKEN, parsed.token, appConst.COOKIE_EXPRIRE);
            token = parsed.token;
        }
        const data = {
            token,
            search: this.state.search,
            path: this.state.path
        }
        // console.log('data =', data);
        this.props.getUserInfo(data);
    }

    componentWillReceiveProps(nextProps) {

    }


    render() {
        return (
            <div className="ladiui auth">
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (data) => dispatch(authActions.getUserInfo(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Iframe));