import React from 'react';
import * as types from '../../../redux/futures/package/types';
import * as partnerTypes from '../../../redux/futures/partner/types';
import partnerActions from '../../../redux/futures/partner/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import appConst from '../../../config/const';



class ActiveForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",
                fullname: "",
                _id: "",
                phone: "",

            },
            appCode: 'LS',
            store_name: '',
            credit_app: '0',
            loading: false,
            loaderPackages: true,
            exp_date: '',
            classNormal: "ladiui btn btn-primary",
            classLoading: "ladiui loader btn btn-primary",
            classApply: "ladiui btn btn-primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.appCode !== nextProps.appCode) {
            this.setState({
                appCode: nextProps.appCode,
            });
        }
        if (this.props.currentUser !== nextProps.currentUser) {
            if (!nextProps.currentUser) {
                this.resetForm();
            } else {
                this.setState({
                    currentUser: nextProps.currentUser,
                    store_name: nextProps.currentUser.fullname || '',
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    packages: []
                })

            }
        }

        if (nextProps.store.action === types.LIST_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.store.status && nextProps.store.packages.length > 0) {
                this.setState({
                    packages: nextProps.store.packages,
                    selectedPackage: nextProps.store.packages[0],
                    loaderPackages: false
                });
                // console.log({packages: nextProps.packages,
                //     selectedPackage: nextProps.packages[0]});
            } else {
                window.LadiUI.toast('danger', '', nextProps.store.message, 5000, 'bottom-left');
            }

            // this.list();
        }
        if (nextProps.partner.action == partnerTypes.ACTIVE_STORE) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.partner.status) {
                const _seft = this;
                window.LadiUI.toast('success', '', nextProps.partner.message, 5000, 'bottom-left');

                setTimeout(function () {
                    window.LadiUI.closeModal("modal-active-store");
                    const { appCode, currentUser } = _seft.state;
                    const data = {
                        store_name: '',
                        app_code: appCode,
                        limit: 20,
                        offset: 0,
                        ladi_uid: currentUser._id,
                        email: currentUser.email

                    };
                    if (currentUser.email != '' && currentUser.email != undefined) {
                        _seft.props.listStore(data);
                    }
                }, 1000);

            } else {
                window.LadiUI.toast('danger', '', nextProps.partner.message, 5000, 'bottom-left');
            }
        }

    }

    pickPackages(name) {
        // console.log('pickPackages(',name);
        const { packages } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].name === name) {

                this.setState({
                    selectedPackage: packages[i]
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }

    onChangeInput = (event) => {
        this.setState({
            store_name: event.target.value
        });
    }
    onChangeCredit = (event) => {
        this.setState({
            credit_app: event.target.value
        });
    }


    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toast('danger', '', errorMessage, 5000, 'bottom-left');
            return;
        }

        const { currentUser, selectedPackage, store_name, appCode, credit_app } = this.state;


        const data = {
            pkg_name: 'STARTER',//selectedPackage.package_name,
            ladi_uid: currentUser._id,
            phone: currentUser.phone,
            email: currentUser.email,
            store_name: store_name == '' ? currentUser.fullname : store_name,
            app_code: appCode,
            credit_app
        };
        this.props.activeStore(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }


    onChangePackage = (event) => {

        this.pickPackages(event.target.value);
    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }

    render() {
        this.inputsRef = new Set();
        const { packages, selectedPackage, appCode, loaderPackages, store_name, credit_app, classApply } = this.state;
        const title = "Tạo store cho " + appConst.APP[appCode];
        return (
            <div ref={this.ref} id="modal-active-store" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-active-store" style={{ zIndex: 21 }}>
                <div className='ladiui modal-dialog modal-dialog-centered' style={{ width: '450px' }}>
                    <div className={`ladiui modal-content ${loaderPackages ? 'loader' : ''}`} >
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-active-store"
                                className="ladiui modal-close"
                                onClick={this.props.onCancel}
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body">
                            <form>

                                <div>

                                    <div className="ladiui form-group condition-form">

                                        <h3>Gói dịch vụ: STARTER</h3>
                                    </div>
                                    <div className="ladiui form-group">
                                        <h3>Tên Store</h3>
                                    </div>

                                    <div className="ladiui form-group">
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="store_name"
                                            validationName="Tên Store"
                                            placeholder="Tên Store"
                                            value={store_name}
                                            onChange={this.onChangeInput}
                                        />

                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="ladiui modal-footer">
                            <button className="ladiui btn btn-secondary" type="button"
                                data-dismiss="modal"
                                data-target="modal-active-store"
                                onClick={this.props.onCancel}>Close</button>
                            <button
                                ref={this.ref}
                                className={this.state.classApply}
                                onClick={this.submit}
                                disabled={this.state.loading}
                            >
                                <i className="ladi-icon" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>Kích hoạt store </button>

                            {/* <button className="ladiui button secondary close-modal icon" data-dismiss="modal-active-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-active-store" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button> */}

                            {/* {this.props.loading && <Spinner />} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listStore: (data) => dispatch(partnerActions.listStore(data)),
        activeStore: (store) => dispatch(partnerActions.activeStore(store)),

    }
}

const mapStateToProps = (state) => ({ store: { ...state.store }, partner: { ...state.partner } });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ActiveForm);