import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.LIST_PACKAGE,
    meta: {
        endpoint: endpoint.LIST_PACKAGE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: false,
    }
});

const getDetail = (data) => ({
    type: types.DETAIL_PACKAGE,
    meta: {
        endpoint: endpoint.ORDER_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    list,
    getDetail
}