import React from 'react';
import { connect } from 'react-redux';
import commissionActions from '../../redux/futures/commission/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/commission/types';
import LoadingTable from '../../components/LoadingTable';
import LadiPagination from '../../components/LadiPagination';
import "react-datepicker/dist/react-datepicker.css";
import SidebarMenu from '../affilates/components/SidebarMenu';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import i18n from '../../i18n';
const moment = baseHelper.getMoment();

class Commission extends React.Component {
    constructor(props) {
        super(props);
        const firstDayOfMonth = moment().startOf('month');
        const lastDayOfMonth = moment();
        this.state = {
            activePage: 1,
            searchStatus: {
                name: appConst.COMMISSION_TYPE.ALL.name,
                value: appConst.COMMISSION_TYPE.ALL.value,
                selected: appConst.COMMISSION_TYPE.ALL.value,
            },
            totalAmount: 0,
            fromDate: firstDayOfMonth,
            toDate: lastDayOfMonth,
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            commissionList: [],
            currentCommission: {}
        }


        this.selectedComissionIds = [];
        this.muteActions = [];
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && !_.includes(this.muteActions, nextProps.action) && nextProps.action === types.COMMISSION_LIST) {
            if (nextProps.status) {
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
        if (nextProps.action == types.CREATE_INVOICE) {

            if (nextProps.status) {
                setTimeout(() => {
                    this.list();
                }, 500);

            }
        }

        if (nextProps.action == types.MY_COMMISSION) {
            if (nextProps.status) {
                let totalComm = 0;
                for (let i = 0; i < nextProps.commissions.length; i++) {
                    totalComm += nextProps.commissions[i].commission_value;
                }
                this.setState({
                    totalAmount: totalComm
                });
            }
        }
    }

    /**
     * list store
     */
    list = (resetActivePageFlag = true, startDate, endDate) => {

        const { selectedLimit } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        let { fromDate, toDate } = this.state;

        if (startDate)
            fromDate = startDate;

        if (endDate)
            toDate = endDate;

        if (fromDate)
            fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        if (toDate)
            toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

        const statusSelected = this.state.searchStatus.selected;

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "is_invoiced": statusSelected,
            "from_date": fromDate,
            "to_date": toDate

        };


        this.props.list(data);
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    }

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.list();
        })
    }

    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        })
    }

    onSelectedLimit = (limit) => {
        this.setState({ selectedLimit: limit }, () => {
            this.list();
        })
    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date
        })
    }

    setToDate = (date) => {
        this.setState({
            toDate: date
        })
    }

    setFocusedInput = () => {

    }

    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }

    openCreateForm = () => {
        this.setState({
            currentCommission: false,
            mode: appConst.FORM_MODE.CREATE
        })
    }

    openEditForm = (currentCommission) => {
        this.setState({
            currentCommission,
            mode: appConst.FORM_MODE.EDIT
        })
    }

    checkAllItem = (event) => {
        const { checked } = event.target;
        this.selectedComissionIds = [];
        if (checked) {
            this.selectedComissionIds = _.map(this.props.commissions, store => store.store_id);
        } else {
        }
    }

    checkItem = (event, commissionId) => {
        const { checked } = event.target;
        const { selectedComissionIds } = this;
        if (checked) {
            if (!_.includes(selectedComissionIds, commissionId)) {
                selectedComissionIds.push(commissionId);
            }

            if (selectedComissionIds.length == this.props.commissions.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            _.remove(selectedComissionIds, selectedCommissionId => selectedCommissionId == commissionId);
        }
    }

    sort = (event) => {
        // const { classList } = event.target;
        // const name = event.target.getAttribute('name');
        // // Change arrow up and down
        // if (_.includes(classList, 'up')) {
        //     classList.remove('up');
        //     this.sortBy[name] = 'ASC';
        // } else {
        //     classList.add('up');
        //     this.sortBy[name] = 'DESC';
        // }

        // this.setState({
        // }, () => this.list());
    }

    view = (currentCommission) => {
        this.setState({
            currentCommission,

        })
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.list();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.list();
        });

    }

    render() {

        const { totalRecord } = this.props;
        const { activePage, selectedLimit, searchStatus, totalAmount } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.commissions || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.commissions.length - 1;
        }

        const isLoadingData = this.props.loading && (this.props.waitting == types.MY_COMMISSION);
        return (
            <div>
                <div className='page-order'>
                    <SidebarMenu />
                    <div className='ladiui content-main'>
                        <div className="ladiui content-main-title flex-row">
                            <div className="ladiui main-title">{i18n.t('COMMISSION.TITLE')}</div>
                        </div>
                        <div className="ladiui-table-actions">
                            <div className="action-item">
                                <div className="ladiui dropdown">
                                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                        <span className="ladiui dropdown-text">{i18n.t(searchStatus.name)}</span>
                                    </button >
                                    <ul className="ladiui dropdown-menu">
                                        {
                                            _.map(appConst.COMMISSION_TYPE, option =>
                                                <li key={option.value} onClick={() => this.onChangeSearchStatus(option)}><a className="ladiui dropdown-item">{i18n.t(option.name)}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="action-item">
                                <LadiDateRangePicker
                                    startDateId="fromDate"
                                    endDateId="toDate"
                                    startDate={this.state.fromDate}
                                    endDate={this.state.toDate}
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState({ fromDate: startDate, toDate: endDate });
                                        this.list(1, startDate, endDate);
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    showDefaultInputIcon={true}
                                    inputIconPosition="after"
                                    small={true}
                                    hideKeyboardShortcutsPanel={true}
                                    customInputIcon={
                                        <i className="ladiui icon icon-date-time-picker" />
                                    }
                                    startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                    endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                    isOutsideRange={() => false}
                                    isDayHighlighted={(date) => moment().isSame(date, 'day')}
                                    minimumNights={0}
                                    showClearDates={true}
                                />
                            </div>
                            <div className="action-item">
                                <label style={{ fontWeight: "bold" }}>{i18n.t('BASE.TOTAL')}: {baseHelper.formatNumber(totalAmount)} đ</label>
                            </div>
                        </div>
                        {
                            isLoadingData
                                ?
                                <LoadingTable />
                                :
                                <table className="ladiui table-vertical">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Email</th>
                                            <th className="text-left">{i18n.t('COMMISSION.INVOICE_DATE')}</th>
                                            <th name="created_at" onClick={this.sort} className="text-left">{i18n.t('COMMISSION.CREATED_AT')}</th>
                                            <th className="text-left">{i18n.t('COMMISSION.ORDER_AMOUNT')}</th>
                                            <th className="text-left">{i18n.t('COMMISSION.AMOUNT')}</th>
                                            <th className="text-left">{i18n.t('COMMISSION.PROGRAM')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(this.props.commissions, commission => {
                                                commission.email = commission.userInfo.email;
                                                return <tr key={commission._id}>

                                                    <td>{commission.userInfo.email}</td>
                                                    <td>{baseHelper.formatStrToDate(commission.date_invoice)}</td>
                                                    <td>{baseHelper.formatStrToDate(commission.created_at)}</td>

                                                    <td>{commission.order_amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                    <td>{commission.commission_value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                    <td>{
                                                        commission.type ?
                                                            commission.type :
                                                            'Affiliate'
                                                    }
                                                    </td>

                                                </tr>
                                            }
                                            )
                                        }
                                        {
                                            totalRecord <= 0 &&
                                            <tr className="text-center">
                                                <td colSpan="100%">{i18n.t('COMMON.NO_DATA')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                        }
                        {
                            totalRecord > 0 &&
                            <LadiPagination
                                conditionsProp={{ limit: selectedLimit, page: this.state.activePage }}
                                listName={i18n.t("BASE.COMMISSION")}
                                items={this.props.commissions}
                                total={totalRecord}
                                limitAction={this.onSelectedLimit}
                                pageAction={this.onPageChange}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(commissionActions.myCommission(data)),
        detail: (data) => dispatch(commissionActions.detail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.commission });

export default connect(mapStateToProps, mapDispatchToProps)(Commission);