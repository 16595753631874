import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const myInvoice = (data) => ({
    type: types.MY_INVOICE,
    meta: {
        endpoint: endpoint.MY_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const invoiceDetail = (data) => ({
    type: types.INVOICE_DETAIL,
    meta: {
        endpoint: endpoint.INVOICE_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});
 
export default {
    myInvoice,
    invoiceDetail
}