import React from 'react';
import appConst from "../../config/const";
import baseHelper from '../../helpers/BaseHelper';
import queryString from "query-string";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LadiIframe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi',
            isvideo: false
        };

        this.inputsRef = new Set();
        this.ref = React.createRef();
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.isvideo) {
            this.setState({
                isvideo: true
            });
        }

    }

    render() {

        if (this.state.lang == 'vi') {
            return (
                <div className="ladiui-iframe">
                    {!this.state.isvideo && <iframe src='https://ladipage.vn/iframelogin' width="100%" height="100%" title='LadiPage'></iframe>}
                    {this.state.isvideo && <iframe src='https://preview.pagedemo.me/test-video' width="100%" height="100%" title='LadiPage'></iframe>}
                </div>
            );
        }
        else {
            return <></>;
        }
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const mapStateToProps = (state) => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LadiIframe));