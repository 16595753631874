import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import routes from '../routes';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as types from '../redux/futures/auth/types';

import appConst from '../config/const';
import baseHelper from '../helpers/BaseHelper';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);
        this.state = {
            userInfo
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action === types.GET_USER_INFO) {
            if (nextProps.status) {
                this.setState({
                    userInfo: nextProps.userInfo
                })

            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    render() {
        const availableItem = routes.getItemByScopes({
            admin: true
        });
        const { userInfo } = this.state;
        const isPartner = true;//userInfo.is_partner || false;
        return (
            <aside className="ladiui aside">
                <nav className="ladiui nav">
                    <ul className="ladiui menu">
                        <li className="ladiui logo-item logo">
                            <Link to="" className="ladiui flex-row-menu-item">
                                <img src="https://w.ladicdn.com/ladiui/ladiapp/ladiapp-icon.svg" alt="" />
                            </Link>
                        </li>
                        <li className="ladiui logo-item logo-hover">
                            <Link to="" className="ladiui flex-row-menu-item">
                                <img src='https://w.ladicdn.com/ladiui/ladiapp/logo-ladiapp.svg' alt="" />
                            </Link>
                        </li>
                        {
                            _.map(availableItem, (item, key) => {
                                if (key !== '/credit') {
                                    return item;
                                } else {
                                    if (isPartner) {
                                        return item;
                                    }
                                    return <div key={key}></div>;
                                }

                            })
                        }
                    </ul>
                </nav>
            </aside>
        );
    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, null)(Sidebar));
