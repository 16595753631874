import React from 'react';

import { connect } from 'react-redux';
import partnerActions from '../../redux/futures/partner/actions';
import _ from 'lodash';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';

import LadiDateRangePicker from "../../components/LadiDateRangePicker";
import LoadingTable from '../../components/LoadingTable';

import LadiPagination from '../../components/LadiPagination';
import * as types from '../../redux/futures/partner/types';
import i18n from '../../i18n';
const moment = baseHelper.getMoment();
class Credit extends React.Component {

    constructor(props) {
        super(props);

        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const userInfo = baseHelper.getCookie(appConst.USER_INFO);
        const firstDayOfMonth = moment().startOf('month');
        const lastDayOfMonth = moment();

        this.state = {
            activePage: 1,

            searchStatus: {
                name: appConst.PAYMENT_STATUS_OPTIONS.ALL.name,
                value: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
                selected: appConst.PAYMENT_STATUS_OPTIONS.ALL.value,
            },
            searchType: {
                name: appConst.CREDIT_LOG_TYPE.ALL.name,
                value: appConst.CREDIT_LOG_TYPE.ALL.value,
                selected: appConst.CREDIT_LOG_TYPE.ALL.value,
            },

            fromDate: firstDayOfMonth,
            toDate: lastDayOfMonth,
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            userInfo,
            creditlogs: [],
            sortby: appConst.SORT_BY.DESCENDING
        }

        this.muteActions = [];
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.action && nextProps.action === types.MY_CREDIT_LOGS) {

            if (nextProps.status) {
                this.setState({ creditlogs: nextProps.creditlogs });
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }

            // this.getList();
        }
    }

    changeFromdate = (date) => {

        this.setState({
            fromDate: date
        }, () => {

            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }

    setFocusedInput() {

    }

    sort = () => {

    }

    /**
     * list store
     */
    getList = (resetActivePageFlag = true, startDate, endDate) => {
        const { selectedLimit } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }

        let { fromDate, toDate, searchType } = this.state;
        if (startDate)
            fromDate = startDate;
        if (endDate)
            toDate = endDate;
        if (fromDate)
            fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        if (toDate)
            toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,

            "from_date": fromDate,
            "to_date": toDate,
            type: searchType.selected
        };

        this.props.list(data);
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.getList();
        }
    }

    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.getList();
        })
    }

    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {

            this.getList(false);
        });
    }

    onSelectedLimit = (limit) => {
        this.setState({ selectedLimit: limit }, () => {
            this.list();
        })
    }

    onChangeSearchType = (option) => {
        this.setState({
            searchType: { ...this.state.searchType, selected: option.value },
        }, () => {

            this.getList(false);
        });
    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date
        })
    }

    setToDate = (date) => {
        this.setState({
            toDate: date
        })
    }

    render() {
        const { totalRecord, totalPage } = this.props;
        const { activePage, searchType, selectedLimit, fromDate, toDate, userInfo } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.state.creditlogs || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.state.creditlogs.length - 1;
        }
        // console.log(activePage, selectedLimit, this.state.creditlogs);
        const isPartner = userInfo.is_partner || false;

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;

        const isLoadingData = this.props.loading && (this.props.waitting == types.MY_CREDIT_LOGS);
        return (
            <div>
                <div className='page-payment-history pd24'>
                    <h1 className="page-title">{i18n.t('CREDIT.TITLE')}</h1>
                    <div className="ladiui-table-actions">
                        <div className="action-item">
                            <div className="ladiui dropdown" style={{ width: '180px' }}>
                                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                    <span className="ladiui dropdown-text">{i18n.t(searchType.name)}</span>
                                </button >
                                <ul className="ladiui dropdown-menu">
                                    {
                                        _.map(appConst.CREDIT_LOG_TYPE, option =>
                                            <li key={option.value} onClick={() => this.onChangeSearchType(option)}><a className="ladiui dropdown-item">{i18n.t(option.name)}</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="action-item">
                            <LadiDateRangePicker
                                startDateId="fromDate"
                                endDateId="toDate"
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState({ fromDate: startDate, toDate: endDate });
                                    this.getList(1, startDate, endDate);
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={
                                    <i className="ladiui icon icon-date-time-picker" />
                                }
                                startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => moment().isSame(date, 'day')}
                                minimumNights={0}
                                showClearDates={true}
                            />
                        </div>
                    </div>
                    {
                        isLoadingData
                            ?
                            <LoadingTable />
                            :
                            <table className="ladiui table-vertical header">
                                <thead>
                                    <tr>
                                        <th name="created_at" onClick={e => this.sort(e, 'created_at')} className="text-left">{i18n.t('ORDER.CREATED_AT')}</th>
                                        {userInfo.is_partner && searchType.value == appConst.CREDIT_LOG_TYPE.USER_BUY_ORDER.value &&
                                            <th className="text-left">{i18n.t('ORDER.CUSTOMER')}</th>
                                        }
                                        {searchType.value == appConst.CREDIT_LOG_TYPE.APP_PURCHASED.value &&
                                            <th className="text-left">{i18n.t('ORDER.APPLICATION')}</th>
                                        }
                                        <th name="amount" onClick={e => this.sort(e, 'amount')} className="text-left">{i18n.t('CREDIT.NAME')}</th>

                                        {userInfo.is_partner &&
                                            <th name="reference_no" onClick={e => this.sort(e, 'order_reference_no')} className="text-left">{i18n.t('ORDER.REFERENCE_NO')}</th>
                                        }
                                        <th className="text-left">{i18n.t('ORDER.TYPE')}</th>
                                        <th className="text-left">{i18n.t('ORDER.NOTE')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(this.state.creditlogs, credit => {
                                            let appCode = '';
                                            if (credit.app_code == 'LADISALES') {
                                                appCode = 'LadiSales';
                                            }
                                            if (credit.app_code == 'LADIFLOW') {
                                                appCode = 'LadiFlow';
                                            }
                                            if (credit.app_code == 'LADIPAGE') {
                                                appCode = 'LadiPage';
                                            }
                                            let isAdd = '';
                                            let isMinus = '';
                                            let isInApp = false;
                                            if (credit.type == appConst.CREDIT_LOG_TYPE.ADMIN_ADD.value) {
                                                isAdd = '+';
                                            }
                                            if (credit.type == appConst.CREDIT_LOG_TYPE.USER_BUY_ORDER.value) {
                                                isAdd = '-';
                                            }
                                            if (credit.type == appConst.CREDIT_LOG_TYPE.APP_PURCHASED.value) {
                                                isAdd = '-';
                                                isInApp = true;
                                            }
                                            if (credit.type == appConst.CREDIT_LOG_TYPE.APP_DEPOSIT.value) {
                                                isAdd = '+';
                                                isInApp = true;
                                            }
                                            if (credit.type == appConst.CREDIT_LOG_TYPE.MONEY_ADD.value) {
                                                isAdd = '+';
                                                isInApp = true;
                                            }
                                            if (credit.type == appConst.CREDIT_LOG_TYPE.MONEY_PURCHASED.value) {
                                                isAdd = '-';
                                                isInApp = true;
                                            }
                                            const creditType = appConst.CREDIT_LOG_TYPE[credit.type].name;


                                            return <tr key={credit._id}>
                                                <td>{baseHelper.formatStrToDate(credit.created_at)}</td>
                                                {userInfo.is_partner && searchType.value == appConst.CREDIT_LOG_TYPE.USER_BUY_ORDER.value &&
                                                    <td>
                                                        {
                                                            credit.affilate_user ?
                                                                credit.affilate_user.email
                                                                :
                                                                ''
                                                        }
                                                    </td>
                                                }
                                                {(searchType.value == appConst.CREDIT_LOG_TYPE.APP_PURCHASED.value
                                                    || searchType.value == appConst.CREDIT_LOG_TYPE.MONEY_PURCHASED.value) &&
                                                    <td>
                                                        {appCode}
                                                    </td>
                                                }
                                                <td>
                                                    {isMinus + baseHelper.numberWithCommas(credit.credit.toFixed(2))}
                                                </td>
                                                {userInfo.is_partner &&
                                                    <td>{credit.reference_no}</td>
                                                }
                                                <td>{i18n.t(creditType)}</td>

                                                <td>{credit.description}</td>
                                                <td>


                                                </td>
                                            </tr>
                                        }
                                        )
                                    }
                                    {
                                        totalRecord <= 0 &&
                                        <tr className="text-center">
                                            <td colSpan="100%">{i18n.t('COMMON.NO_DATA')}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                    }
                    {totalRecord > 0 &&
                        <LadiPagination
                            conditionsProp={{ limit: selectedLimit, page: this.state.activePage }}
                            listName={i18n.t('BASE.TRANSACTION_HISTORY')}
                            items={this.state.creditlogs}
                            total={totalRecord}
                            limitAction={this.onSelectedLimit}
                            pageAction={this.onPageChange}
                        />
                    }
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(partnerActions.myCreditLogs(data))
    }
}

const mapStateToProps = (state) => ({ ...state.partner });

export default connect(mapStateToProps, mapDispatchToProps)(Credit);