import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { store, history } from './redux/store';
import * as serviceWorker from './serviceWorker';
import appConst from './config/const';
// import { initializeFirebase } from './push-notification';
// import { fireBaseConfig } from './config/config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
ReactDOM.render((
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={appConst.KEY_CAPCHA}  >
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </GoogleReCaptchaProvider>
    </Provider>
)
    , document.getElementById('root')
);

serviceWorker.unregister();
// initializeFirebase(fireBaseConfig);
