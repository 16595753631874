import React from 'react';
import PropTypes from 'prop-types';
import invoiceActions from '../../../redux/futures/invoice/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import * as types from '../../../redux/futures/invoice/types';
import i18n from '../../../i18n';

class Detail extends React.Component {

    static propTypes = {
        currentInvoice: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentInvoice: {

                amount: "",
                created_at: "",
                pay_date: "",
                status: "",
                updated_at: "",
                _id: ""
            },
            loading: true,
            commissions: []
        };

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.currentInvoice !== nextProps.currentInvoice) {
            this.setState({
                loading: true
            });
            this.setState({
                currentInvoice: _.pick(nextProps.currentInvoice, _.keys(this.state.currentInvoice))
            })
        }

        if (_.includes([types.INVOICE_DETAIL], nextProps.action)) {
            if (nextProps.status) {
                this.setState({
                    loading: false
                });
                this.setState({
                    commissions: nextProps.commissions,
                });
            }
        }

    }

    invoiceDetail = () => {
        const ID = this.state.currentInvoice._id;
        const data = {
            id: ID
        }
        this.props.invoiceDetail(data);
    }

    render() {
        const { currentInvoice, commissions } = this.state;


        return (
            <div id="modal-invoicedetail" className="ladiui modal fade" tabIndex={-1} data-dismiss="modal-invoicedetail">
                <div className='ladiui modal-dialog modal-dialog-centered' style={{ width: '1000px' }}>
                    <div className="ladiui modal-content">
                        <div className="ladiui modal-header">
                            <label className="ladiui title-text-form-lever1">{i18n.t('INVOICE.INVOICE_ID')}: {currentInvoice._id}</label>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-invoicedetail"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body" >
                            <div className="order-box" style={{margin: '14px auto' }}>
                                <div className="invoice-detail-summary">
                                    <span><strong>{i18n.t('INVOICE.COMMISSION')}: </strong>
                                        {
                                            currentInvoice.amount && currentInvoice.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
                                        }
                                    </span>
                                    <span><strong>{i18n.t('INVOICE.CREATED_AT')}: </strong>
                                        {
                                            baseHelper.formatStrToDate(currentInvoice.created_at)
                                        }
                                    </span>
                                    <span><strong>{i18n.t('INVOICE.PAID_DATE')}: </strong>
                                        {
                                            baseHelper.formatStrToDate(currentInvoice.pay_date)
                                        }</span>
                                </div>
                            </div>
                            <table className="ladiui table-vertical">
                                <thead>
                                    <tr>
                                        <th className="text-left">{i18n.t('INVOICE.DATE_ORDER')}</th>
                                        <th className="text-left">Email</th>
                                        <th className="text-left">{i18n.t('INVOICE.CUSTOMER_NAME')}</th>
                                        <th className="text-left">{i18n.t('INVOICE.ORDER_AMOUNT')}</th>
                                        <th className="text-left">{i18n.t('INVOICE.COMMISSION')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(commissions, commission => {
                                            return <tr key={commission._id}>
                                                <td className="text-left">{baseHelper.formatStrToDate(commission.created_at)}</td>
                                                <td>{commission.userInfo.email}</td>
                                                <td>{commission.userInfo.fullname}</td>
                                                <td>{commission.order_amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                <td>{commission.commission_value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                            </tr>
                                        }
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className="ladiui modal-footer">
                                {this.state.loading && <Spinner />}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        invoiceDetail: (data) => dispatch(invoiceActions.invoiceDetail(data))
    }
}

const mapStateToProps = (state) => ({ ...state.invoice });

export default connect(mapStateToProps, mapDispatchToProps)(Detail);