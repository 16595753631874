import React from 'react';

// import SigninV2 from '../pages/auth/SigninV2';
import SigninV3 from '../pages/auth/SigninV3';
// import SignupV2 from '../pages/auth/SignupV2';
import SignupV3 from '../pages/auth/SignupV3';
import VerifyAccount from '../pages/auth/VerifyAccount';

import queryString from "query-string";
import ForgottenPasswordV3 from '../pages/auth/ForgottenPasswordV3';
import ResetPassV3 from '../pages/auth/ResetPasswordV3';
import FacebookCallback from '../pages/auth/FacebookCallback';
import GoogleCallback from '../pages/auth/GoogleCallback';
import CookiePage from '../pages/auth/CookiePage';
import { Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import CompleteRegister from '../pages/auth/CompleteRegister';
import CompleteRegisterV3 from '../pages/auth/CompleteRegisterV3';
import VerifyEmail from '../pages/profile/VerifyEmail';
import appConst from "../config/const";
import i18n from '../i18n';
import baseHelper from "../helpers/BaseHelper";
import { I18nextProvider } from 'react-i18next';





class AuthLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi',
            isIframe: false
        };
    }

    componentDidMount() {

        const lang = baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi';
        i18n.changeLanguage(lang).then(() => {
            this.setState({ lang });
        });
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.iframe) {
            this.setState({
                isIframe: true
            });
        }

    }

    render() {
        let _class = 'ladiui-page-auth ';
        if (this.state.lang !== 'vi')
            _class += ' no-iframe';
        // console.log('this.state.isIframe ', this.state.isIframe)
        return (
            <I18nextProvider i18n={i18n}>
                <div className={_class}>
                    {!this.state.isIframe && <div className="ladi-popupx-inline" data-ladipage-id="65265997226e580012ecf4fc" data-align="center" data-scrolling="no"></div>}
                    <Switch>
                        <Route path="/signin" component={SigninV3} />
                        <Route path="/signup" component={SignupV3} />
                        <Route path="/verify-account" component={VerifyAccount} />
                        <Route path="/forgottenpass" component={ForgottenPasswordV3} />
                        <Route path="/resetpass" component={ResetPassV3} />
                        <Route path="/oauth2-facebook" component={FacebookCallback} />
                        <Route path="/oauth2-google" component={GoogleCallback} />
                        <Route path="/cookie" component={CookiePage} />
                        <Route path="/verify-email" component={VerifyEmail} />
                        <Route path="/complete-reg" component={CompleteRegisterV3} />
                    </Switch>
                </div>
            </I18nextProvider>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const mapStateToProps = (state) => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthLayout));