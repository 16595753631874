import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import i18n from "../../i18n";

class RegisterWarning extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }

        this.ref = React.createRef();

        this.inputsRef = [];
    }

    componentWillReceiveProps(nextProps) {

    }


    render() {

        return (
            <div ref={this.ref} id="modal-register-warning" className="ladiui modal" tabIndex={-1} data-dismiss="modal-register-warning" onClick={this.resetForm}>
                <div className='ladiui modal-dialog modal-dialog-centered'>
                    <div className="ladiui modal-content">
                        <div className="ladiui modal-header">
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="modal-register-warning"
                                className="ladiui modal-close"
                            >
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body custom-ldp">
                            <img src='https://w.ladicdn.com/ladiui/icons/new-ldicon-illust-dont-leave-page.svg' />
                            <h1 className='form-title'>{i18n.t('AUTH.LEAVE_PAGE_WARNING')}</h1>
                            <p className="form-sub">{i18n.t('AUTH.LEAVE_PAGE_WARNING_SB1')}</p>
                            <p className="form-sub">{i18n.t('AUTH.LEAVE_PAGE_WARNING_SB2')}</p>
                        </div>
                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button data-dismiss="modal" data-target="modal-register-warning" className="ladiui btn small txt body3 btn-secondary">
                                    {i18n.t('AUTH.LEAVE_PAGE_CONFIRM')}
                                </button>
                                {/* <div>
                                    <button className="ladiui btn small txt body3 btn-primary" >
                                        Rời trang
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            // <div id="modal-register-warning" className="ladiui modal" tabIndex={-1} data-dismiss="modal-register-warning">
            //     <div className="ladiui-modal-content">
            //         <div className="ladiui-modal-header">
            //             <button type="button" className="ladiui-close-modal" data-dismiss="modal-register-warning"><i className="ladi-icon icon-e-remove" data-dismiss="modal-register-warning" /></button>
            //             <h3>{i18n.t('AUTH.SIGN_IN')}</h3>
            //         </div>
            //         <div className="ladiui-modal-body">
            //             <h1 className="form-title">{i18n.t('AUTH.SIGN_IN')}</h1>
            //         </div>

            //         <div className="ladiui-modal-footer">
            //             <div className="ladiui footer-modal-btn">
            //                 <button data-dismiss="modal" data-target="modal-register-warning" className="ladiui btn small txt body3 btn-secondary">
            //                     {i18n.t("BASE.CANCEL")}
            //                 </button>
            //                 <div>
            //                     <button className="ladiui btn small txt body3 btn-primary" onClick={() => this.submit()}>
            //                         {i18n.t("BASE.UPDATE")}
            //                     </button>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>

            // </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = (state) => ({ ...state.payment });

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWarning);