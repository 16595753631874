import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Iframe from '../pages/auth/Iframe';

class IframeLayout extends React.Component {

    render() {

        return (
            <Switch>
                <Route path="/iframe" component={Iframe} />
            </Switch>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const mapStateToProps = (state) => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IframeLayout));