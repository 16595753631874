import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import queryString from 'query-string';

import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';


class CookiePage extends React.Component {


    constructor(props) {
        super(props);

        const callbackObj = queryString.parse(this.props.location.search);

        let referral_id = false;
        let affilate_code = false;
        let ladiflow_ref = false;
        if (callbackObj.referral_id && callbackObj.referral_id != '') {
            referral_id = callbackObj.referral_id;
        }
        // if (callbackObj.ref && callbackObj.ref != '') {
        //     referral_id = callbackObj.ref;
        // }
        if (callbackObj.affilate_code && callbackObj.affilate_code != '') {
            affilate_code = callbackObj.affilate_code;
        }
        // if (callbackObj.aff && callbackObj.aff != '') {
        //     affilate_code = callbackObj.aff;
        // }
        if (callbackObj.ladiflow_ref && callbackObj.ladiflow_ref != '') {
            ladiflow_ref = callbackObj.ladiflow_ref;
        }
        if (referral_id) {
            baseHelper.setCookie(appConst.REFERRAL_ID, referral_id, appConst.COOKIE_AFFILATE_EXPRIRE);
        }
        if (affilate_code) {
            baseHelper.setCookie(appConst.AFFILATE_CODE, affilate_code, appConst.COOKIE_AFFILATE_EXPRIRE);
        }
        if (ladiflow_ref) {
            baseHelper.setCookie(appConst.LADIFLOW_REF, ladiflow_ref, appConst.COOKIE_AFFILATE_EXPRIRE);
        }

    }

    render() {
        return (<img src={"1x1.gif?version=" + (new Date().getTime())} >
        </img>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}


export default connect(null, mapDispatchToProps)(CookiePage);