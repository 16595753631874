import React from 'react';

import userActions from '../redux/futures/user/actions';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VerifyEmail from '../pages/profile/VerifyEmail';
import { Switch, Route } from 'react-router-dom';
class VerifyEmailLayout extends React.Component {

    render() {
 
        return (
            <Switch>
                <Route path="/verify-email" exact={true} component={VerifyEmail} />
            </Switch>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const mapStateToProps = (state) => ({ });


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmailLayout));