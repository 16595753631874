import React from 'react';

import invoiceActions from '../../redux/futures/invoice/actions';
import _ from 'lodash';
import { connect } from 'react-redux';

import baseHelper from '../../helpers/BaseHelper';
import appConst from '../../config/const';
import Detail from './components/Detail';
import LoadingTable from '../../components/LoadingTable';
import LadiPagination from '../../components/LadiPagination';

import * as types from '../../redux/futures/invoice/types';
import SidebarMenu from '../affilates/components/SidebarMenu';
import i18n from '../../i18n';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
const moment = baseHelper.getMoment();
class Invoice extends React.Component {

    constructor(props) {
        super(props);
        const firstDayOfMonth = moment().startOf('month');
        const lastDayOfMonth = moment();
        this.state = {
            activePage: 1,
            fromDate: firstDayOfMonth,
            toDate: lastDayOfMonth,
            focusedInput: null,
            searchStatus: {
                name: appConst.INVOICE_STATUS.ALL.name,
                value: appConst.INVOICE_STATUS.ALL.value,
                selected: appConst.INVOICE_STATUS.ALL.value,
            },
            selectedLimit: appConst.PAGINATION.LIMIT_OPTIONS[1].value,
            invoiceList: []
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userInfo && this.state.userInfo !== nextProps.userInfo) {
            if (nextProps.status) {
                // window.LadiUI.closeModal(this.ref.current);
                // window.LadiUI.toast('success', '', nextProps.message, 5000, 'bottom-left');
            } else {
                window.LadiUI.toast('danger', '', nextProps.message,5000, 'bottom-left');
            }
        }
    }

    changeFromdate = (date) => {
        this.setState({
            fromDate: date
        }, () => {
            this.getList();
        });

    }

    changeTodate = (date) => {
        this.setState({
            toDate: date
        }, () => {
            this.getList();
        });

    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date
        })
    }

    setToDate = (date) => {
        this.setState({
            toDate: date
        })
    }

    setFocusedInput = () => {

    }

    getList = (resetActivePageFlag = true, startDate, endDate) => {
        const statusSelected = this.state.searchStatus.selected;
        const { selectedLimit } = this.state;
        let { activePage } = this.state;
        if (resetActivePageFlag) {
            activePage = 1;

            this.setState({
                activePage: 1,
            });
        }
        let { fromDate, toDate } = this.state;

        if (startDate)
            fromDate = startDate;

        if (endDate)
            toDate = endDate;

        if (fromDate)
            fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        if (toDate)
            toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

        const data = {
            "limit": selectedLimit,
            "offset": (activePage - 1) * selectedLimit,
            "status": statusSelected,
            "from_date": fromDate,
            "to_date": toDate
        };

        this.props.myInvoice(data);
    }

    view = (currentInvoice) => {
        const data = {
            "id": currentInvoice._id
        };
        this.setState({
            currentInvoice
        }, () => {
            this.props.invoiceDetail(data);
        });

    }

    onSelectedLimit = (limit) => {
        this.setState({ selectedLimit: limit }, () => {
            this.list();
        })
    }

    onPageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, () => {
            this.list(false);
        });
    }

    onChangeInput = (event) => {

        // this.setState({
        //     userInfo: {
        //         ...this.state.userInfo,
        //         [event.target.name]: event.target.value
        //     }
        // });
    }

    onChangeSearchStatus = (option) => {
        this.setState({
            searchStatus: { ...this.state.searchStatus, selected: option.value },
        }, () => {
            this.getList();
        })
    }

    render() {
        const { totalRecord } = this.props;
        const { activePage, selectedLimit, searchStatus } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.invoices || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.invoices.length - 1;
        }

        const msgShowItem = `Show ${fromItem} to ${toItem} of ${baseHelper.formatNumber(totalRecord)} items`;
        const isLoadingData = this.props.loading && (this.props.waitting == types.MY_INVOICE);
        return (
            <div>
                <div className='page-payment-history'>
                    <SidebarMenu />
                    <div className='ladiui content-main'>
                        <div className="ladiui content-main-title flex-row">
                            <div className="ladiui main-title">Danh sách hoá đơn</div>
                        </div>
                        <div className="ladiui-table-actions">
                            <div className="action-item">
                                <div className="ladiui dropdown">
                                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                        <span className="ladiui dropdown-text">{i18n.t(searchStatus.name)}</span>
                                    </button >
                                    <ul className="ladiui dropdown-menu">
                                        {
                                            _.map(appConst.INVOICE_STATUS, option =>
                                                <li key={option.value} onClick={() => this.onChangeSearchStatus(option)}><a className="ladiui dropdown-item">{i18n.t(option.name)}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="action-item">
                                <LadiDateRangePicker
                                    startDateId="fromDate"
                                    endDateId="toDate"
                                    startDate={this.state.fromDate}
                                    endDate={this.state.toDate}
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState({ fromDate: startDate, toDate: endDate });
                                        this.getList(1, startDate, endDate);
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    showDefaultInputIcon={true}
                                    inputIconPosition="after"
                                    small={true}
                                    hideKeyboardShortcutsPanel={true}
                                    customInputIcon={
                                        <i className="ladiui icon icon-date-time-picker" />
                                    }
                                    startDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                    endDatePlaceholderText={appConst.DEFAULT_FORMAT_DATE.toUpperCase()}
                                    isOutsideRange={() => false}
                                    isDayHighlighted={(date) => moment().isSame(date, 'day')}
                                    minimumNights={0}
                                    showClearDates={true}
                                />
                            </div>
                        </div>
                        {
                            isLoadingData
                                ?
                                <LoadingTable />
                                :
                                <table className="ladiui table-vertical">
                                    <thead>
                                        <tr>
                                            {/* <th className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th> */}

                                            <th className="text-left">{i18n.t('INVOICE.TOTAL_AMOUNT')}</th>
                                            <th name="created_at" onClick={this.sort} className="text-left">{i18n.t('INVOICE.CREATED_AT')}</th>
                                            <th name="created_at" onClick={this.sort} className="text-left">{i18n.t('INVOICE.PAID_DATE')}</th>
                                            <th name="status" onClick={this.sort} className="text-left">{i18n.t('COMMON.STATUS')}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(this.props.invoices, invoice => {

                                                return <tr key={invoice._id}>
                                                    {/* <td className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, invoice._id)} className="ladiui checkbox" />
                                                        </td> */}
                                                    <td>{invoice.amount.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                    <td>{baseHelper.formatStrToDate(invoice.created_at)}</td>
                                                    <td>{baseHelper.formatStrToDate(invoice.pay_date)}</td>

                                                    <td>{invoice.status}</td>
                                                    <td>
                                                        <a className="ladiui link"
                                                            onClick={() => this.view(invoice)} data-target="modal-invoicedetail" data-toggle="modal">
                                                            {i18n.t('COMMON.DETAIL')}</a>
                                                    </td>
                                                </tr>
                                            }
                                            )
                                        }
                                        {
                                            totalRecord == 0 &&
                                            <tr className="text-center">
                                                <td colSpan="100%">{i18n.t('COMMON.NO_DATA')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                        }

                        {
                            totalRecord > 0 &&
                            <LadiPagination
                                conditionsProp={{ limit: selectedLimit, page: this.state.activePage }}
                                listName={i18n.t("Hoá đơn")}
                                items={this.props.invoices}
                                total={totalRecord}
                                limitAction={this.onSelectedLimit}
                                pageAction={this.onPageChange}
                            />
                        }
                    </div>
                    <Detail
                        currentInvoice={this.state.currentInvoice}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        myInvoice: (data) => dispatch(invoiceActions.myInvoice(data)),
        invoiceDetail: (data) => dispatch(invoiceActions.invoiceDetail(data)),
    }
}

const mapStateToProps = (state) => ({ ...state.invoice });

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);